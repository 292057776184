import { Brightness1, Edit, Send } from "@mui/icons-material";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerSearch } from "../../api/url";
import {
  thunkClearCustomer,
  thunkGetCustomer,
  thunkGetCustomerBonusPoints,
  thunkUpdateCustomer,
} from "../../store/actions/CustomerCareActions";
import { thunkGetCustomerHistory } from "../../store/actions/HistoryActions";
import { thunkGetCustomerIdentifications } from "../../store/actions/IdentificationActions";
import { canMaintainCustomers } from "../../store/actions/LoginActions";
import { thunkGetCustomerPreferences } from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { CircleDto } from "../../store/models/circle/CircleDto";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import {
  CustomerStatus,
  getCustomerStatusColor,
  getCustomerStatusText,
} from "../../store/models/customer/CustomerStatusDto";
import AdjustmentDialog from "../adjustment/AdjustmentDialog";
import MenuLayout from "../app/MenuLayout";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import Coupons from "./Coupons";
import CustomerStatusDialog from "./CustomerStatusDialog";
import Family from "./Family";
import GdprDialog from "./GdprDialog";
import HistoryFeed from "./HistoryFeed";
import Identifications from "./Identifications";
import KeyFigures from "./KeyFigures";
import PersonalInformation from "./PersonalInformation";
import Preferences from "./Preferences";

export function getHeadlineText(customer: CustomerDto) {
  return customer.firstName && customer.lastName
    ? customer.firstName + " " + customer.lastName
    : customer.mailAddress;
}

const CustomerCareDetailsPage = (props: ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [customerStatusDialogVisible, setCustomerStatusDialogVisible] =
    useState(false);
  const [adjustmentDialogVisible, setAdjustmentDialogVisible] = useState(false);
  const [gdprDialogVisible, setGdprDialogVisible] = useState(false);
  const { id } = useParams<"id">();

  const {
    customer,

    circles,
    thunkClearCustomer,
    thunkGetCustomer,
    thunkGetCustomerPreferences,
    thunkGetCustomerHistory,
    thunkGetCustomerBonusPoints,
    thunkGetCustomerIdentifications,
  } = props;

  useEffect(() => {
    if (id && customer?.customerNumber !== id) {
      thunkClearCustomer();
      thunkGetCustomer(id);
      thunkGetCustomerPreferences(id);
      thunkGetCustomerHistory(id);
      thunkGetCustomerBonusPoints(id);
      thunkGetCustomerIdentifications(id);
    }
  }, [
    id,
    customer,
    thunkClearCustomer,
    thunkGetCustomer,
    thunkGetCustomerPreferences,
    thunkGetCustomerHistory,
    thunkGetCustomerBonusPoints,
    thunkGetCustomerIdentifications,
  ]);

  const getCircleNameFromNumber = (circleNumber?: string) =>
    circles.circles.find(
      (circleObj: CircleDto) => circleObj.circleNumber === circleNumber
    )?.circleName;

  if (redirect) {
    return <Navigate to={UrlPathCustomerSearch} />;
  }

  if (!id) return null;

  if (!customer || customer.customerNumber !== id) return null;

  return (
    <MenuLayout
      headline={getHeadlineText(customer)}
      subHeadline={getCircleNameFromNumber(customer.circleNumber)}
    >
      <Form
        onSubmit={(customer: CustomerDto) => {
          props.thunkUpdateCustomer(customer);
          setRedirect(true);
        }}
        initialValues={customer}
        render={({ handleSubmit }) => {
          return (
            <form key="CustomerCareDetailsForm_Form" onSubmit={handleSubmit}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                direction="row"
              >
                {customerStatusDialogVisible && (
                  <CustomerStatusDialog
                    setCustomerStatusDialogVisible={
                      setCustomerStatusDialogVisible
                    }
                  />
                )}
                {adjustmentDialogVisible && (
                  <AdjustmentDialog
                    setAdjustmentDialogVisible={setAdjustmentDialogVisible}
                  />
                )}
                {gdprDialogVisible && (
                  <GdprDialog setGdprDialogVisible={setGdprDialogVisible} />
                )}
                <Grid
                  container
                  style={{
                    marginBottom: theme.spacing(6),
                  }}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item md={6} container alignItems="center">
                    <Brightness1
                      style={{
                        fill: getCustomerStatusColor(customer.status),
                        marginRight: theme.spacing(1),
                        paddingBottom: 2,
                      }}
                    />
                    {getCustomerStatusText(customer.status)}
                    {customer.status !== CustomerStatus.CANCELLED &&
                      canMaintainCustomers() && (
                        <Edit
                          style={{
                            marginLeft: theme.spacing(2),
                            fontSize: 24,
                            backgroundColor: theme.palette.primary.main,
                            color: COLOR_GRAY_1,
                            cursor: "hand",
                            borderRadius: "50%",
                            padding: 1,
                          }}
                          onClick={() => {
                            setCustomerStatusDialogVisible(true);
                            return false;
                          }}
                        />
                      )}
                  </Grid>
                  {canMaintainCustomers() && (
                    <Grid item>
                      <Button
                        startIcon="+"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setAdjustmentDialogVisible(true);
                        }}
                      >
                        Neue Bonusbuchung
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <PersonalInformation />
                  <HistoryFeed />
                </Grid>
                <KeyFigures />
                <Family />
                <Identifications />
                <Coupons />
                <Preferences />
              </Grid>

              {customer.status !== CustomerStatus.CANCELLED && (
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{
                    margin: theme.spacing(3, 0, 2, 0),
                  }}
                >
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => setGdprDialogVisible(true)}
                  >
                    <Typography
                      variant="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Send /> DSGVO-AUSKUNFT BEREITSTELLEN
                    </Typography>
                  </Button>
                </Grid>
              )}
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  circles: state.circles,
  operationalUnits: state.operationalUnits.operationalUnits,
  customerHistory: state.customerCare.customerHistory,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkClearCustomer,
      thunkGetCustomer,
      thunkUpdateCustomer,
      thunkGetCustomerHistory,
      thunkGetCustomerPreferences,
      thunkGetCustomerBonusPoints,
      thunkGetCustomerIdentifications,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerCareDetailsPage);
