import { AnyAction } from "redux";
import { GET_ALL_CONFIG_GROUPS } from "../actions/ConfigGroupActions";
import { ConfigGroupDto } from "../models/config/ConfigDto";

export interface IConfigGroupReducerState {
  configGroups: ConfigGroupDto[];
}

export default class ConfigGroupReducer {
  public static reducer(
    state: IConfigGroupReducerState,
    action: AnyAction
  ): IConfigGroupReducerState {
    switch (action.type) {
      case GET_ALL_CONFIG_GROUPS:
        return {
          ...state,
          configGroups: action.payload,
        };
      default:
        return state || { configGroups: [] };
    }
  }
}
