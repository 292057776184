import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { CircleDto } from "../models/circle/CircleDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_ALL_CIRCLES = "GET_ALL_CIRCLES";
export const DELETE_CIRCLE = "DELETE_CIRCLE";

export const thunkGetAllCircles = (): BooleanThunk => async (dispatch) => {
  try {
    const circles = await Api.getAllCircles();
    dispatch({
      type: GET_ALL_CIRCLES,
      payload: circles,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Circles", e));
    return false;
  }
};

export const thunkCreateCircle =
  (circleToCreate: CircleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createCircle(circleToCreate);
      dispatch(thunkGetAllCircles() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Circle wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen des Circles", e)
      );
      return false;
    }
  };

export const thunkUpdateCircle =
  (circleToUpdate: CircleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateCircle(circleToUpdate);
      dispatch(thunkGetAllCircles() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Circle wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren des Circle", e)
      );
      return false;
    }
  };

export const thunkDeleteCircle =
  (circleToDelete: CircleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteCircle(circleToDelete);
      dispatch({
        type: DELETE_CIRCLE,
        payload: circleToDelete,
      });
      dispatch(thunkCreateSuccessNotification("Der Circle wurde gelöscht"));
      return true;
    } catch (e) {
      if (e === "The Default Circle is not deletable!") {
        dispatch(
          thunkCreateErrorNotification(
            "Circle kann nicht gelöscht werden - Der Standard-Circle darf nicht gelöscht werden.",
            e
          )
        );
      } else if (e === "Circle in use by Customer.") {
        dispatch(
          thunkCreateErrorNotification(
            "Circle kann nicht gelöscht werden - Es gibt noch Mitglieder, die diesem Circle angehören.",
            e
          )
        );
      } else if (e === "Circle in use by Bonus Rule.") {
        dispatch(
          thunkCreateErrorNotification(
            "Circle kann nicht gelöscht werden - Es gibt noch Bonuskampagnen, die diesem Circle angehören.",
            e
          )
        );
      } else {
        dispatch(
          thunkCreateErrorNotification("Fehler beim Löschen des Circles", e)
        );
      }
    }
    return false;
  };
