import { Button, Grid, Paper, useTheme } from "@mui/material";
import arrayMutators from "final-form-arrays";
import { useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { UrlPathPreferences } from "../../api/url";
import {
  thunkCreateQuestion,
  thunkUpdateQuestion,
} from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { QuestionDto } from "../../store/models/preferences/QuestionDto";
import { questionTypeOptions } from "../../store/models/preferences/QuestionType";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { isBlank, ValidationErrors } from "../atoms/Utils";

interface PreferenceFormProps {
  question: QuestionDto;
}

const PreferenceForm = (props: PreferenceFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const saveQuestion = async (question: QuestionDto) => {
    // set position according to GUI order

    for (let i = 0; i < question.options.length; i++) {
      question.options[i].position = i + 1;
    }

    let success;
    if (question.isNew) {
      delete question.isNew;
      success = await props.thunkCreateQuestion(question);
    } else {
      success = await props.thunkUpdateQuestion(question);
    }
    if (success) {
      setRedirect(true);
    }
  };

  if (redirect) return <Navigate to={UrlPathPreferences} />;

  const validateForm = (values: QuestionDto) => {
    const errors: ValidationErrors<QuestionDto> = {};
    if (isBlank(values.label)) {
      errors.label = "Bitte geben Sie eine Frage ein.";
    }
    if (!values.type) {
      errors.type = "Pflichtfeld";
    }
    if (!values.options) {
      errors.options = "Pflichtfeld";
    }
    return errors;
  };
  return (
    <Form
      onSubmit={saveQuestion}
      initialValues={props.question}
      mutators={{ ...arrayMutators }}
      validate={validateForm}
      render={({
        handleSubmit,
        form: {
          mutators: { push, remove, swap },
        },
        submitting,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}
          >
            <ImsPaperHead text="Wie lautet die Frage?" />
            <Grid container spacing={4}>
              <Grid container spacing={2} item>
                <Grid item md={12}>
                  <b>Fragestellung</b>
                </Grid>
                <Grid item md={6}>
                  <FormInput
                    type="text"
                    name="label"
                    label="Frage"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item>
                <Grid item md={12}>
                  <b>Frageart</b>
                </Grid>
                <Grid item md={6}>
                  <FormRadioButton
                    name="type"
                    value={values.type}
                    options={questionTypeOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{ padding: theme.spacing(3), marginTop: theme.spacing(8) }}
          >
            <ImsPaperHead text="Wie lauten die Antwortmöglichkeiten?" />
            <Grid container spacing={4}>
              <Grid container spacing={2} item>
                <Grid item md={12}>
                  <b>Mögliche Antwort</b>
                </Grid>
              </Grid>
              <Grid container spacing={2} item>
                <Grid item>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    data-testid="button-addOption"
                    onClick={() =>
                      push("options", {
                        label: "",
                        optionNumber: `OPTION-${uuid()}`,
                        position:
                          Math.max(
                            ...props.question.options.map(
                              (option) => option.position || 0
                            ),
                            0
                          ) + 1,
                      })
                    }
                  >
                    Antwort hinzufügen
                  </Button>
                </Grid>
                <FieldArray name="options">
                  {({ fields }) =>
                    fields.map((option, index) => (
                      <Grid container item key={option}>
                        <Grid item className="pt-4" md={1}>
                          <Button
                            onClick={() => swap("options", index, index - 1)}
                            className="mr-1"
                            disabled={index === 0}
                          >
                            <span
                              className="ims-iconfont-button-back"
                              aria-hidden="true"
                            >
                              &uarr;
                            </span>
                          </Button>
                          <Button
                            disabled={
                              index >= props.question.options.length - 1
                            }
                            onClick={() => swap("options", index, index + 1)}
                          >
                            &darr;
                          </Button>
                        </Grid>
                        <Grid item md={3} data-testid={`Antwort-${index + 1}`}>
                          <FormInput
                            type="text"
                            name={`${option}.label`}
                            label={`Antwort-${index + 1}`}
                          />
                        </Grid>
                        <Grid item className="pt-4" md={3}>
                          <Button onClick={() => remove("options", index)}>
                            Löschen
                          </Button>
                        </Grid>
                      </Grid>
                    ))
                  }
                </FieldArray>
              </Grid>
            </Grid>
          </Paper>

          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              type="button"
              onClick={() => setRedirect(true)}
              variant="contained"
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              data-testid="button-save"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {values.isNew ? "Frage anlegen" : "Frage speichern"}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateQuestion,
      thunkUpdateQuestion,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PreferenceForm);
