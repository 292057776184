import { CustomerDto } from "../customer/CustomerDto";

export interface FamilyMembershipDto {
  customerNumber: string;
  roleName: FamilyMembershipRole;
  customerDto?: CustomerDto;
}

export enum FamilyMembershipRole {
  HEAD = "HEAD",
  MEMBER = "MEMBER",
}

export interface FamilyRoleDto {
  [customerNumber: string]: FamilyMembershipRole;
}

export const memberRoleOptions = [
  {
    label: "Sammeln und Einlösen",
    value: FamilyMembershipRole.HEAD,
  },
  {
    label: "Sammeln",
    value: FamilyMembershipRole.MEMBER,
  },
];

export interface FamilyMembershipRoleDto {
  roleName: FamilyMembershipRole;
}
