import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { ConfigValueDto } from "../../../store/models/config/ConfigDto";
import { ConfigGroupKey } from "../../../store/models/config/ConfigGroupKey";
import MenuLayout from "../../app/MenuLayout";
import ConfigValueForm from "./ConfigValueForm";

export interface ConfigValueFormDto extends ConfigValueDto {
  isNew?: boolean;
}

export interface ConfigValueProps {
  configGroupKey: ConfigGroupKey;
  entityName: string;
  valueName: string;
}

export const receiptDetailProps: ConfigValueProps = {
  configGroupKey: ConfigGroupKey.RECEIPT_DETAIL,
  entityName: "Filter",
  valueName: "JSON-Pfad",
};

export const customFieldProps: ConfigValueProps = {
  configGroupKey: ConfigGroupKey.CUSTOM_FIELD,
  entityName: "Zusatzfelder",
  valueName: "Beschriftung",
};

const defaultConfigValue = { isNew: true } as ConfigValueFormDto;

const ConfigValueFormPage = (props: ThunkProps & ConfigValueProps) => {
  const { configGroups } = props;
  const { id } = useParams<"id">();

  let matchingConfigGroups = configGroups
        .find((configGroup) => configGroup.key === props.configGroupKey);

  let configValue;
  if (matchingConfigGroups && matchingConfigGroups.values) {
    configValue = matchingConfigGroups
        ?.values.find((value) => value.key === id);
  }

  return (
    <MenuLayout
      headline={props.entityName + " " + (id ? "bearbeiten" : "erstellen")}
    >
      <ConfigValueForm
        configValue={configValue || defaultConfigValue}
        {...props}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  configGroups: state.configGroups.configGroups,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ConfigValueFormPage);
