import { Grid, Paper, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { KNISTR_GREEN, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { formatNumber } from "../atoms/Utils";
import Bonus from "./Bonus";
import BonusDialog from "./BonusDialog";
import Payment from "./Payment";
import PaymentDialog from "./PaymentDialog";
import { getPaymentModel } from "./PaymentModel";

export function formatBonusPoints(
  bonusPointsString?: string | number
): ReactNode {
  const bonusPoints = Number(bonusPointsString);
  if (!bonusPoints) return null;

  return (
    <span
      style={{
        color: bonusPoints < 0 ? KNISTR_RED : KNISTR_GREEN,
      }}
    >
      {bonusPoints < 0 ? "-" : "+"} {formatNumber(Math.abs(bonusPoints))}
    </span>
  );
}
const KeyFigures = (props: ThunkProps) => {
  const theme = useTheme();
  const [bonusDialogVisible, setBonusDialogVisible] = useState(false);
  const [paymentDialogVisible, setPaymentDialogVisible] = useState(false);

  if (!props.customerNumber || !props.customerHistory) return null;

  const payments = getPaymentModel(
    props.customerHistory,
    props.operationalUnits,
    (history) => history.displayedPaymentAmount
  )?.sort(function(p1, p2){
      return new Date(p2.interactionTimestamp).getTime() - new Date(p1.interactionTimestamp).getTime();
  });

  const bonusPayments = getPaymentModel(
    props.customerHistory,
    props.operationalUnits,
    (history) => history.bonusPoints && history.bonusPoints !== "0"
  )?.sort(function(p1, p2){
      return new Date(p2.interactionTimestamp).getTime() - new Date(p1.interactionTimestamp).getTime();
  });

  return (
    <Grid
      item
      md
      style={{
        marginBottom: theme.spacing(3),
      }}
    >
      {bonusDialogVisible && (
        <BonusDialog
          bonusPayments={bonusPayments}
          closeDialog={() => setBonusDialogVisible(false)}
        />
      )}
      {paymentDialogVisible && (
        <PaymentDialog
          payments={payments}
          closeDialog={() => setPaymentDialogVisible(false)}
        />
      )}
      <Grid
        item
        style={{
          paddingTop: theme.spacing(3),
          height: "100%",
        }}
      >
        <Paper
          style={{
            padding: theme.spacing(3),
            marginTop: theme.spacing(3),
            height: "100%",
          }}
        >
          <ImsPaperHead text="Kennzahlen" />
          <Grid
            container
            style={{ height: "100%", paddingBottom: theme.spacing(3) }}
          >
            <Bonus
              bonusPayments={bonusPayments}
              openDialog={() => setBonusDialogVisible(true)}
            />
            <Payment
              payments={payments}
              openDialog={() => setPaymentDialogVisible(true)}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerNumber: state.customerCare.customer?.customerNumber,
  operationalUnits: state.operationalUnits.operationalUnits,
  customerHistory: state.customerCare.customerHistory,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(KeyFigures);
