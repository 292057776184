import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import {
  BonusPremiumDto,
  BonusPremiumStatus,
} from "../../store/models/bonusPremium/BonusPremiumDto";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import BonusPremiumTableMenu from "./BonusPremiumTableMenu";

function formatAvailability(startDate?: string, endDate?: string) {
  return endDate ? (
    <>
      <DateTag date={startDate} />
      {" bis "}
      <DateTag date={endDate} />
    </>
  ) : (
    <>
      {"ab "}
      <DateTag date={startDate} />
    </>
  );
}
const BonusPremiumTable = (props: ThunkProps) => {
  const headCells: ImsHeadCell<BonusPremiumDto>[] = [
    { val: (val) => val.premiumName, node: "Name" },
    { val: (val) => val.startDate, node: "Verfügbarkeit" },
    { val: (val) => val.unitNumber, node: "Filialen" },
    { val: (val) => val.couponDetails.advantageType, node: "Prämie" },
    { val: (val) => val.couponDetails.requiredBonusPoints, node: "Punkte" },
    { val: (val) => val.endDate, node: "Gültigkeit" },
  ];

  const rows: ImsTableRow<BonusPremiumDto>[] = props.bonusPremiums.map(
    (row) => ({
      value: row,
      nodes: [
        <b>{row.premiumName}</b>,
        formatAvailability(row.startDate, row.endDate),
        props.operationalUnits.find((ou) => ou.unitNumber === row.unitNumber)
          ?.unitName,
        row.couponDetails.advantageInfo,
        row.couponDetails.requiredBonusPoints,
        <>
          {!row.couponDetails.expiryMonths
            ? "bis "
            : row.couponDetails.expiryMonths + " Monate"}
          {!row.couponDetails.expiryMonths && (
            <DateTag date={row.couponDetails.expiryDate} />
          )}
        </>,
        <BonusPremiumTableMenu bonusPremium={row} />,
      ],
      disabled: row.premiumStatus === BonusPremiumStatus.DISABLED,
    })
  );
  return <ImsTable hasMenu={true} headCells={headCells} rows={rows} />;
};

const mapStateToProps = (state: IStore) => ({
  bonusPremiums: state.bonusPremiums.bonusPremiums,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumTable);
