import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { OperationalUnitDto } from "../models/operationalUnit/OperationalUnitDto";
import { OperationalUnitStatus } from "../models/operationalUnit/OperationalUnitStatus";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_OPERATIONAL_UNITS = "GET_OPERATIONAL_UNITS";
export const UPDATE_OPERATIONAL_UNIT = "UPDATE_OPERATIONAL_UNIT";
export const CREATE_OPERATIONAL_UNIT = "CREATE_OPERATIONAL_UNIT";
export const GET_DEFAULT_OPERATIONAL_UNIT_TYPES =
  "GET_DEFAULT_OPERATIONAL_UNIT_TYPES";

export const DELETE_OPERATIONAL_UNIT = "DELETE_OPERATIONAL_UNIT";

export const thunkGetOperationalUnits =
  (): BooleanThunk => async (dispatch) => {
    try {
      const operationalUnits = await Api.getOperationRootUnitList();
      dispatch({
        type: GET_OPERATIONAL_UNITS,
        payload: operationalUnits,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Filialen", e)
      );
      return false;
    }
  };

export const thunkUpdateOperationalUnit =
  (operationalUnitToUpdate: OperationalUnitDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const operationalUnit = await Api.updateOperationalUnit(
        operationalUnitToUpdate
      );
      dispatch({
        type: UPDATE_OPERATIONAL_UNIT,
        payload: operationalUnit,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Filiale wurde aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren der Filiale", e)
      );
      return false;
    }
  };

export const thunkCreateOperationalUnit =
  (operationalUnitToCreate: OperationalUnitDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const operationalUnit = await Api.createOperationalUnit(
        operationalUnitToCreate
      );
      dispatch({
        type: CREATE_OPERATIONAL_UNIT,
        payload: operationalUnit,
      });
      dispatch(thunkCreateSuccessNotification("Die Filiale wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen der Filiale", e)
      );
      return false;
    }
  };

export const thunkUpdateOperationalUnitStatus =
  (
    operationalUnit: OperationalUnitDto,
    status: OperationalUnitStatus
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      operationalUnit.unitStatus = status;
      await Api.updateOperationalUnitStatus(operationalUnit);
      dispatch({
        type: UPDATE_OPERATIONAL_UNIT,
        payload: operationalUnit,
      });
      dispatch(thunkCreateSuccessNotification("Die Filiale wurde deaktiviert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Deaktivieren der Filiale", e)
      );
      return false;
    }
  };

export const thunkGetDefaultOperationalUnitTypes =
  (): BooleanThunk => async (dispatch) => {
    try {
      const operationalUnitTypes = await Api.getDefaultOperationalUnitTypes();
      dispatch({
        type: GET_DEFAULT_OPERATIONAL_UNIT_TYPES,
        payload: operationalUnitTypes,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Laden der Filial-/Gruppentypen",
          e
        )
      );
      return false;
    }
  };

export const thunkDeleteOperationalUnit =
  (operationalUnit: OperationalUnitDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteOperationalUnit(operationalUnit);
      dispatch({
        type: DELETE_OPERATIONAL_UNIT,
        payload: operationalUnit,
      });
      dispatch(thunkCreateSuccessNotification("Die Filiale wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen der Filiale", e)
      );
      return false;
    }
  };
