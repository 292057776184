export enum CustomerInteractionType {
  ADD_PREFERENCES = "ADD_PREFERENCES",
  REGISTER = "REGISTER",
  ADD_ADDRESS = "ADD_ADDRESS",
  FRIEND_INVITATION = "FRIEND_INVITATION",
  FAMILY_INVITATION = "FAMILY_INVITATION",
  CONSENT = "CONSENT",
  DELETE_BONUS_POINTS = "DELETE_BONUS_POINTS",
  EMAIL_CORRESPONDENCE_ALLOWED = "EMAIL_CORRESPONDENCE_ALLOWED",
  CUSTOM = "CUSTOM",
}

export function isCustomInteractionType(
  customerInteractionType?: string
): boolean {
  switch (customerInteractionType) {
    // DO NOT ADD CONSENT HERE!
    case CustomerInteractionType.ADD_ADDRESS:
    case CustomerInteractionType.ADD_PREFERENCES:
    case CustomerInteractionType.DELETE_BONUS_POINTS:
    case CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED:
    case CustomerInteractionType.FAMILY_INVITATION:
    case CustomerInteractionType.FRIEND_INVITATION:
    case CustomerInteractionType.REGISTER:
    case CustomerInteractionType.CUSTOM:
      return false;
    default:
      return Boolean(customerInteractionType);
  }
}

export function formatCustomerInteractionType(
  customerInteractionType: CustomerInteractionType
): string {
  switch (customerInteractionType) {
    case CustomerInteractionType.ADD_ADDRESS:
      return "Adressdaten angegeben";
    case CustomerInteractionType.ADD_PREFERENCES:
      return "Präferenzfragen beantwortet";
    case CustomerInteractionType.REGISTER:
      return "Registrierung";
    case CustomerInteractionType.FAMILY_INVITATION:
      return "Familieneinladung";
    case CustomerInteractionType.FRIEND_INVITATION:
      return "Freundschaftswerbung";
    case CustomerInteractionType.CONSENT:
      return "Zustimmung zum Programm";
    case CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED:
      return "E-Mail-Einwilligung";
    default:
      return customerInteractionType;
  }
}
