import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCircleEdit } from "../../../api/url";
import { thunkDeleteCircle } from "../../../store/actions/CircleActions";
import { canMaintainCircles } from "../../../store/actions/LoginActions";
import { IStore } from "../../../store/IStore";
import { CircleDto } from "../../../store/models/circle/CircleDto";
import ImsConfirmDialog from "../../atoms/ImsConfirmDialog";

interface CircleTableMenuProps {
  circle: CircleDto;
}

function CircleTableMenu(props: CircleTableMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  if (!canMaintainCircles()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="den"
          word="Circle"
          verb="löschen"
          onConfirm={() => props.thunkDeleteCircle(props.circle)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link
            component={RouterLink}
            to={UrlPathCircleEdit.replace(":id", props.circle.circleNumber)}
          >
            Bearbeiten
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          Löschen
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDeleteCircle }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CircleTableMenu);
