import { AnyAction } from "redux";
import {
  GET_BONUS_XPRESS,
  UPDATE_BONUS_XPRESS,
} from "../actions/BonusXpressActions";
import { BonusXpressDto } from "../models/bonusXpress/BonusXpressDto";

export interface IBonusXpressReducerState {
  bonusXpress?: BonusXpressDto;
}

export default class BonusXpressReducer {
  public static reducer(
    state: IBonusXpressReducerState,
    action: AnyAction
  ): IBonusXpressReducerState {
    switch (action.type) {
      case GET_BONUS_XPRESS:
      case UPDATE_BONUS_XPRESS:
        return {
          ...state,
          bonusXpress: action.payload,
        };
      default:
        return state || {};
    }
  }
}
