import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { CustomerHistoryDto } from "../../store/models/history/HistoryDto";
import { dialogStyles } from "../atoms/DialogStyle";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import { formatGermanDateTime } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";

interface NoteDialogProps extends ThunkProps {
  note: CustomerHistoryDto;
  setNoteDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const hasNoteDialog = (action: CustomerHistoryDto) =>
  action.note || action.username;

const NoteDialog = (props: NoteDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      onClose={() => props.setNoteDialogVisible(false)}
      open={true}
      fullWidth={false}
      maxWidth="sm"
      PaperProps={{
        sx: dialogStyles.scrollIn,
      }}
    >
      <NotificationList isDialog />
      <DialogTitle style={{ padding: theme.spacing(0) }}>
        <Grid container style={{ padding: theme.spacing(3) }}>
          <b>Notiz</b>
        </Grid>

        <Divider
          style={{
            color: COLOR_GRAY_1,
            height: "2px",
          }}
          light={true}
        />

        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Datum / Uhrzeit</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">
              {formatGermanDateTime(new Date(props.note.eventTimestamp))}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="subtitle1">Ausgelöst durch</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1">
              {props.note.username || "-"}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider
        style={{
          color: COLOR_GRAY_1,
          height: "2px",
        }}
        light={true}
      />
      <DialogContent
        style={{
          padding: theme.spacing(0),
          overflow: "hidden",
          display: "flex",
        }}
      >
        {props.note.note ? (
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid item sm={6}>
              <Typography variant="subtitle1">Notiz</Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="body1">{props.note.note}</Typography>
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(NoteDialog);
