import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Routes, useLocation, useNavigate } from "react-router";
import { Route } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  isUnauthenticatedPath,
  UrlPathBonus,
  UrlPathBonusCreate,
  UrlPathBonusPremium,
  UrlPathBonusPremiumCreate,
  UrlPathBonusPremiumViewMode,
  UrlPathBonusViewMode,
  UrlPathBonusXpress,
  UrlPathCampaignCreate,
  UrlPathCampaignEdit,
  UrlPathCampaigns,
  UrlPathCircle,
  UrlPathCircleCreate,
  UrlPathCircleEdit,
  UrlPathConfigValueCreate,
  UrlPathConfigValueEdit,
  UrlPathCustomerDetails,
  UrlPathCustomerEdit,
  UrlPathCustomerPreferences,
  UrlPathCustomerSearch,
  UrlPathCustomField,
  UrlPathFamily,
  UrlPathFlow,
  UrlPathFlowCreate,
  UrlPathFlowEdit,
  UrlPathGdpr,
  UrlPathIdentificationType,
  UrlPathIdentificationTypeCreate,
  UrlPathIdentificationTypeEdit,
  UrlPathInteractionSearch,
  UrlPathLogin,
  UrlPathMain,
  UrlPathOperationalUnit,
  UrlPathOperationalUnitCreate,
  UrlPathOperationalUnitEdit,
  UrlPathPreferences,
  UrlPathPreferencesCreate,
  UrlPathPreferencesEdit,
  UrlPathReceiptDetail,
  UrlPathRegistration,
  UrlPathReports,
  UrlPathUser,
  UrlPathUserCreate,
  UrlPathUserEdit,
} from "../../api/url";
import { thunkGetBonusPremiums } from "../../store/actions/BonusPremiumActions";
import { thunkGetBonusRules } from "../../store/actions/BonusRuleActions";
import { thunkGetBonusXpress } from "../../store/actions/BonusXpressActions";
import { thunkFindAllCampaigns } from "../../store/actions/CampaignActions";
import { thunkGetAllCircles } from "../../store/actions/CircleActions";
import { thunkGetAllConfigGroups } from "../../store/actions/ConfigGroupActions";
import { thunkGetAllFlows } from "../../store/actions/FlowActions";
import { thunkGetAllIdentificationTypes } from "../../store/actions/IdentificationActions";
import {
  canMaintainBonus,
  canMaintainBonusPremiums,
  canMaintainCampaigns,
  canMaintainCircles,
  canMaintainConfig,
  canMaintainCustomers,
  canMaintainFamilies,
  canMaintainFlows,
  canMaintainIdentificationTypes,
  canMaintainOperationalUnits,
  canMaintainPreferences,
  canMaintainUsers,
  canReadBonus,
  canReadBonusPremiums,
  canReadCampaigns,
  canReadCircles,
  canReadConfig,
  canReadCustomerInteractions,
  canReadCustomerPreferences,
  canReadCustomers,
  canReadDashboard,
  canReadFlows,
  canReadGdpr,
  canReadIdentificationTypes,
  canReadOperationalUnits,
  canReadPreferences,
  canReadReports,
  canReadUsers,
  ensureToken,
  isLoggedIn,
} from "../../store/actions/LoginActions";
import {
  thunkGetDefaultOperationalUnitTypes,
  thunkGetOperationalUnits,
} from "../../store/actions/OperationalUnitActions";
import { thunkGetQuestions } from "../../store/actions/PreferenceActions";
import { thunkFindAllUsers } from "../../store/actions/UserActions";
import { IStore } from "../../store/IStore";
import BonusPremiumFormPage from "../bonusPremium/BonusPremiumFormPage";
import { BonusPremiumPage } from "../bonusPremium/BonusPremiumPage";
import BonusRuleFormPage from "../bonusRule/BonusRuleFormPage";
import { BonusRulePage } from "../bonusRule/BonusRulePage";
import BonusXpressPage from "../bonusXpress/BonusXpressPage";
import CampaignFormPage from "../campaign/CampaignFormPage";
import { CampaignPage } from "../campaign/CampaignPage";
import CustomerCareDetailsPage from "../customerCare/CustomerCareDetailsPage";
import CustomerCareEditPage from "../customerCare/CustomerCareEditPage";
import CustomerCarePreferencesPage from "../customerCare/CustomerCarePreferencesPage";
import CustomerCareSearchPage from "../customerCare/CustomerCareSearchPage";
import FamilyPage from "../customerCare/FamilyPage";
import { MainDashboardPage } from "../dashboard/MainDashboardPage";
import Flows from "../flow/Flows";
import InteractionSearchPage from "../interactionSearch/InteractionSearchPage";
import OperationalUnitFormPage from "../operationalUnit/OperationalUnitFormPage";
import OperationalUnitOverview from "../operationalUnit/OperationalUnitOverview";
import PreferencesFormPage from "../preferences/PreferencesFormPage";
import { PreferencesPage } from "../preferences/PreferencesPage";
import { RegistrationPage } from "../registration/RegistrationPage";
import ReportPage from "../report/ReportPage";
import CircleFormPage from "../setup/circle/CircleFormPage";
import ConfigValueFormPage, {
  customFieldProps,
  receiptDetailProps,
} from "../setup/configValue/ConfigValueFormPage";
import IdentificationTypeFormPage from "../setup/identificationType/IdentificationTypeFormPage";
import Setup from "../setup/Setup";
import UserFormPage from "../user/UserFormPage";
import { UserTablePage } from "../user/UserTablePage";
import LoginPage from "./LoginPage";

const App = (props: ThunkProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = ensureToken();
    if (token) {
      canReadPreferences() && props.thunkGetQuestions();
      canReadBonus() && props.thunkGetBonusRules();
      canReadBonus() && props.thunkGetBonusXpress();
      canReadBonusPremiums() && props.thunkGetBonusPremiums();
      canReadCampaigns() && props.thunkFindAllCampaigns();
      canReadOperationalUnits() && props.thunkGetOperationalUnits();
      canReadOperationalUnits() && props.thunkGetDefaultOperationalUnitTypes();
      canReadUsers() && props.thunkFindAllUsers();
      canReadCircles() && props.thunkGetAllCircles();
      canReadIdentificationTypes() && props.thunkGetAllIdentificationTypes();
      canReadFlows() && props.thunkGetAllFlows();
      canReadConfig() && props.thunkGetAllConfigGroups();
    }
  }, [props, props.loginState]);

  useEffect(() => {
    if (!isUnauthenticatedPath() && !isLoggedIn()) {
      navigate(UrlPathLogin);
    }

    if (isUnauthenticatedPath() && isLoggedIn()) {
      if (canReadDashboard()) {
        navigate(UrlPathMain);
      } else {
        navigate(UrlPathCustomerSearch);
      }
    }
  }, [location, navigate]);

  // duplicate check because useEffect is only called after the first rendering
  if (!isUnauthenticatedPath() && !isLoggedIn()) {
    return null;
  }

  return (
    <Routes>
      <Route path={UrlPathLogin} element={<LoginPage />} />
      <Route
        path={UrlPathMain}
        element={
          <AuthOrElse
            check={canReadDashboard}
            element={<MainDashboardPage />}
            pathToNavigate={UrlPathCustomerSearch}
          />
        }
      />
      <Route
        path={UrlPathReports}
        element={<Auth check={canReadReports} element={<ReportPage />} />}
      />

      <Route
        path={UrlPathInteractionSearch}
        element={
          <Auth
            check={canReadCustomerInteractions}
            element={<InteractionSearchPage />}
          />
        }
      />
      <Route
        path={UrlPathCustomerSearch}
        element={
          <Auth check={canReadCustomers} element={<CustomerCareSearchPage />} />
        }
      />
      <Route
        path={UrlPathCustomerEdit}
        element={
          <Auth
            check={canMaintainCustomers}
            element={<CustomerCareEditPage />}
          />
        }
      />
      <Route
        path={UrlPathCustomerDetails}
        element={
          <Auth
            check={canReadCustomers}
            element={<CustomerCareDetailsPage />}
          />
        }
      />

      <Route
        path={UrlPathPreferences}
        element={
          <Auth check={canReadPreferences} element={<PreferencesPage />} />
        }
      />
      <Route
        path={UrlPathPreferencesCreate}
        element={
          <Auth
            check={canMaintainPreferences}
            element={<PreferencesFormPage />}
          />
        }
      />
      <Route
        path={UrlPathPreferencesEdit}
        element={
          <Auth
            check={canMaintainPreferences}
            element={<PreferencesFormPage />}
          />
        }
      />
      <Route
        path={UrlPathCustomerPreferences}
        element={
          <Auth
            check={canReadCustomerPreferences}
            element={<CustomerCarePreferencesPage />}
          />
        }
      />
      <Route
        path={UrlPathBonusXpress}
        element={<Auth check={canReadBonus} element={<BonusXpressPage />} />}
      />
      <Route
        path={UrlPathBonus}
        element={<Auth check={canReadBonus} element={<BonusRulePage />} />}
      />
      <Route
        path={UrlPathBonusCreate}
        element={
          <Auth check={canMaintainBonus} element={<BonusRuleFormPage />} />
        }
      />
      <Route
        path={UrlPathBonusViewMode}
        element={<Auth check={canReadBonus} element={<BonusRuleFormPage />} />}
      />

      <Route
        path={UrlPathBonusPremium}
        element={
          <Auth check={canReadBonusPremiums} element={<BonusPremiumPage />} />
        }
      />
      <Route
        path={UrlPathBonusPremiumCreate}
        element={
          <Auth
            check={canMaintainBonusPremiums}
            element={<BonusPremiumFormPage />}
          />
        }
      />
      <Route
        path={UrlPathBonusPremiumViewMode}
        element={
          <Auth
            check={canReadBonusPremiums}
            element={<BonusPremiumFormPage />}
          />
        }
      />

      <Route
        path={UrlPathCampaigns}
        element={<Auth check={canReadCampaigns} element={<CampaignPage />} />}
      />
      <Route
        path={UrlPathCampaignCreate}
        element={
          <Auth check={canMaintainCampaigns} element={<CampaignFormPage />} />
        }
      />
      <Route
        path={UrlPathCampaignEdit}
        element={
          <Auth check={canMaintainCampaigns} element={<CampaignFormPage />} />
        }
      />
      <Route
        path={UrlPathOperationalUnit}
        element={
          <Auth
            check={canReadOperationalUnits}
            element={<OperationalUnitOverview />}
          />
        }
      />
      <Route
        path={UrlPathOperationalUnitCreate}
        element={
          <Auth
            check={canMaintainOperationalUnits}
            element={<OperationalUnitFormPage />}
          />
        }
      />
      <Route
        path={UrlPathOperationalUnitEdit}
        element={
          <Auth
            check={canMaintainOperationalUnits}
            element={<OperationalUnitFormPage />}
          />
        }
      />
      <Route
        path={UrlPathCircle}
        element={<Auth check={canReadCircles} element={<Setup />} />}
      />
      <Route
        path={UrlPathCircleCreate}
        element={
          <Auth check={canMaintainCircles} element={<CircleFormPage />} />
        }
      />
      <Route
        path={UrlPathCircleEdit}
        element={
          <Auth check={canMaintainCircles} element={<CircleFormPage />} />
        }
      />
      <Route
        path={UrlPathIdentificationType}
        element={
          <Auth check={canReadIdentificationTypes} element={<Setup />} />
        }
      />
      <Route
        path={UrlPathIdentificationTypeCreate}
        element={
          <Auth
            check={canMaintainIdentificationTypes}
            element={<IdentificationTypeFormPage />}
          />
        }
      />
      <Route
        path={UrlPathIdentificationTypeEdit}
        element={
          <Auth
            check={canMaintainIdentificationTypes}
            element={<IdentificationTypeFormPage />}
          />
        }
      />
      <Route
        path={UrlPathFlow}
        element={<Auth check={canReadFlows} element={<Flows />} />}
      />
      <Route
        path={UrlPathFlowCreate}
        element={<Auth check={canMaintainFlows} element={<Flows />} />}
      />
      <Route
        path={UrlPathFlowEdit}
        element={<Auth check={canMaintainFlows} element={<Flows />} />}
      />
      <Route
        path={UrlPathReceiptDetail}
        element={<Auth check={canReadConfig} element={<Setup />} />}
      />
      <Route
        path={UrlPathConfigValueCreate(UrlPathReceiptDetail)}
        element={
          <Auth
            check={canMaintainConfig}
            element={<ConfigValueFormPage {...receiptDetailProps} />}
          />
        }
      />
      <Route
        path={UrlPathConfigValueEdit(UrlPathReceiptDetail)}
        element={
          <Auth
            check={canMaintainConfig}
            element={<ConfigValueFormPage {...receiptDetailProps} />}
          />
        }
      />
      <Route
        path={UrlPathCustomField}
        element={<Auth check={canReadConfig} element={<Setup />} />}
      />
      <Route
        path={UrlPathConfigValueCreate(UrlPathCustomField)}
        element={
          <Auth
            check={canMaintainConfig}
            element={<ConfigValueFormPage {...customFieldProps} />}
          />
        }
      />
      <Route
        path={UrlPathConfigValueEdit(UrlPathCustomField)}
        element={
          <Auth
            check={canMaintainConfig}
            element={<ConfigValueFormPage {...customFieldProps} />}
          />
        }
      />
      <Route
        path={UrlPathGdpr}
        element={<Auth check={canReadGdpr} element={<Setup />} />}
      />
      <Route
        path={UrlPathUser}
        element={<Auth check={canReadUsers} element={<UserTablePage />} />}
      />
      <Route
        path={UrlPathUserCreate}
        element={<Auth check={canMaintainUsers} element={<UserFormPage />} />}
      />
      <Route
        path={UrlPathUserEdit}
        element={<Auth check={canMaintainUsers} element={<UserFormPage />} />}
      />
      <Route path={UrlPathRegistration} element={<RegistrationPage />} />
      <Route
        path={UrlPathFamily}
        element={<Auth check={canMaintainFamilies} element={<FamilyPage />} />}
      />
    </Routes>
  );
};

const Auth = ({
  element,
  check,
}: {
  element: JSX.Element;
  check: () => boolean;
}) => (check() ? element : null);

const AuthOrElse = ({
  element,
  pathToNavigate,
  check,
}: {
  element: JSX.Element;
  pathToNavigate: string;
  check: () => boolean;
}) => (check() ? element : <Navigate to={pathToNavigate} />);

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetQuestions,
      thunkGetBonusRules,
      thunkGetBonusPremiums,
      thunkFindAllCampaigns,
      thunkGetOperationalUnits,
      thunkGetDefaultOperationalUnitTypes,
      thunkFindAllUsers,
      thunkGetAllCircles,
      thunkGetAllIdentificationTypes,
      thunkGetAllFlows,
      thunkGetBonusXpress,
      thunkGetAllConfigGroups,
    },
    dispatch
  );

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IStore) => ({
  loginState: state.login.loginState,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
