import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { IdentificationTypeDto } from "../models/identification/IdentificationTypeDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_ALL_IDENTIFICATION_TYPES = "GET_ALL_IDENTIFICATION_TYPES";
export const DELETE_IDENTIFICATION_TYPE = "DELETE_IDENTIFICATION_TYPE";
export const GET_CUSTOMER_IDENTIFICATIONS = "GET_CUSTOMER_IDENTIFICATIONS";
export const GET_CUSTOMER_CUSTOM_FIELDS = "GET_CUSTOMER_CUSTOM_FIELDS";

export const thunkGetAllIdentificationTypes =
  (): BooleanThunk => async (dispatch) => {
    try {
      const identificationTypes = await Api.getAllIdentificationTypes();
      dispatch({
        type: GET_ALL_IDENTIFICATION_TYPES,
        payload: identificationTypes,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Wallets", e)
      );
      return false;
    }
  };

export const thunkCreateIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createIdentificationType(identificationType);
      dispatch(thunkGetAllIdentificationTypes() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Die Wallet wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen der Wallet", e)
      );
      return false;
    }
  };

export const thunkUpdateIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateIdentificationType(identificationType);
      dispatch(thunkGetAllIdentificationTypes() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Die Wallet wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren der Wallet", e)
      );
      return false;
    }
  };

export const thunkDeleteIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteIdentificationType(identificationType);
      dispatch({
        type: DELETE_IDENTIFICATION_TYPE,
        payload: identificationType,
      });
      dispatch(thunkCreateSuccessNotification("Die Wallet wurde gelöscht"));
      return true;
    } catch (e) {
      if (e === "The Default Identification is not deletable!") {
        dispatch(
          thunkCreateErrorNotification(
            "Die Wallet kann nicht gelöscht werden - Die Standard-Wallet darf nicht gelöscht werden.",
            e
          )
        );
      } else if (e === "Identification in use by Customer.") {
        dispatch(
          thunkCreateErrorNotification(
            "Die Wallet kann nicht gelöscht werden - Es gibt noch Mitglieder, die dieser Wallet zugeordnet sind.",
            e
          )
        );
      } else if (e === "Identification in use by Bonus Rule.") {
        dispatch(
          thunkCreateErrorNotification(
            "Die Wallet kann nicht gelöscht werden - Es gibt noch Bonuskampagnen, die dieser Wallet zugeordnet sind.",
            e
          )
        );
      } else {
        dispatch(
          thunkCreateErrorNotification("Fehler beim Löschen der Wallet", e)
        );
      }
    }
    return false;
  };

export const thunkGetCustomerIdentifications =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const identifications = await Api.getCustomerIdentifications(
        customerNumber
      );
      dispatch({
        type: GET_CUSTOMER_IDENTIFICATIONS,
        payload: identifications,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Kunden-Wallets", e)
      );
      return false;
    }
  };
