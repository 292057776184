import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { ConfigValueDto } from "../../../store/models/config/ConfigDto";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import { ConfigValueProps } from "./ConfigValueFormPage";
import ConfigValueTableMenu from "./ConfigValueTableMenu";

const ConfigValueTable = (props: ThunkProps & ConfigValueProps) => {
  const { configGroups } = props;
  const headCells: ImsHeadCell<ConfigValueDto>[] = [
    {
      val: (val) => val.key,
      node: "Name",
    },
    { val: (val) => val.description, node: "Beschreibung" },
    { val: (val) => val.value, node: props.valueName },
  ];

  const rows: ImsTableRow<ConfigValueDto>[] | undefined = configGroups
    .find((configGroup) => configGroup.key === props.configGroupKey)
    ?.values?.map((row) => ({
      value: row,
      nodes: [
        row.key,
        row.description,
        row.value,
        <ConfigValueTableMenu configValue={row} {...props} />,
      ],
    }));
  return rows?.length ? (
    <ImsTable
      hasMenu={true}
      headCells={headCells}
      rows={rows}
      sortColumnIndex={1}
      sortDirection="desc"
    />
  ) : (
    <>Es sind keine Daten vorhanden</>
  );
};

const mapStateToProps = (state: IStore) => ({
  configGroups: state.configGroups.configGroups,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ConfigValueTable);
