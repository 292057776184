import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { FlowGroupDto, FlowType } from "../../store/models/flow/FlowDto";
import FlowForm from "./FlowForm";

interface FlowFormPageProps {
  flowGroup: string;
  flowType: FlowType;
}

const FlowFormPage = (props: FlowFormPageProps & ThunkProps) => {
  const { flows } = props;
  const flow = flows.find((flow) => flow.flowType === props.flowType);
  return (
    <FlowForm flow={flow || ({} as FlowGroupDto)} flowType={props.flowType} />
  );
};

const mapStateToProps = (state: IStore) => ({
  flows: state.flows.flows,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FlowFormPage);
