import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { CircleDto } from "../../../store/models/circle/CircleDto";
import MenuLayout from "../../app/MenuLayout";
import CircleForm from "./CircleForm";

const defaultCircle = {} as CircleDto;

const CircleFormPage = (props: ThunkProps) => {
  const { circles } = props;
  const { id } = useParams<"id">();
  const circle = circles.find((circle) => circle.circleNumber === id);
  return (
    <MenuLayout headline={id ? "Circle bearbeiten" : "Neuer Circle"}>
      <CircleForm circle={circle || defaultCircle} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CircleFormPage);
