import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { IStore } from "../../store/IStore";
import {
  BonusRuleDto,
  ruleFilterApplyTypeOptions,
  RuleFilterCustomerPropertyType,
} from "../../store/models/bonusRule/BonusRuleDto";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import FilterForm from "./FilterForm";
import { thunkDeleteBonusRuleFilter } from "../../store/actions/BonusRuleActions";
import { BackdropProcessing } from "../app/BackdropProcessing";

interface FilterProps {
  bonusRule: BonusRuleDto;
  viewMode: boolean;
}

const Filter = (props: FilterProps & ThunkProps) => {
  const theme = useTheme();
  const addFilter = () => {
    const filterNumber = "NEW-FILTER-" + uuid();
    setEditFilterNumber(filterNumber);
  };
  const deleteFilter = async () => {
    if (!deleteFilterNumber) {
      return;
    }
    setProcessing(true);
    if (deleteFilterNumber.startsWith("NEW-FILTER-")) {
      props.bonusRule.ruleFilters = props.bonusRule.ruleFilters?.filter(
        filter => filter.ruleFilterNumber !== deleteFilterNumber
      );
    } else {
      await props.thunkDeleteBonusRuleFilter(deleteFilterNumber);
    }
    setProcessing(false);
  };
  const uploadFilterValues = async (
    e: ChangeEvent<HTMLInputElement>,
    ruleFilterNumber: string | undefined
  ) => {
    if (!e.target.files?.length) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = evt => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const records = result
        .toString()
        .replace("\t", "")
        .replace("\r", "")
        .split("\n")
        .filter(item => !!item && item.trim().length > 0)
        .map(item => item.trim());
      setInitialRuleFilterValues(records);
      setEditFilterNumber(ruleFilterNumber);
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const [editFilterNumber, setEditFilterNumber] = useState<string>();
  const [deleteFilterNumber, setDeleteFilterNumber] = useState<string>();
  const [initialRuleFilterValues, setInitialRuleFilterValues] = useState<
    string[]
  >([]);
  const [processing, setProcessing] = useState<boolean>(false);

  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <Grid item md={12}>
        Mit dem Artikelfilter können Sie festlegen, nach welchen Attributen
        gekaufte Artikel bonifiziert werden.
      </Grid>
      {deleteFilterNumber && (
        <ImsConfirmDialog
          article="den"
          word="Filter"
          verb="löschen"
          setConfirmVisible={() => setDeleteFilterNumber(undefined)}
          onConfirm={deleteFilter}
        />
      )}
      {editFilterNumber && (
        <FilterForm
          bonusRule={props.bonusRule}
          editFilterNumber={editFilterNumber}
          setEditFilterNumber={setEditFilterNumber}
          initialRuleFilterValues={initialRuleFilterValues}
        />
      )}
      <Grid item md={2}>
        Artikel
      </Grid>
      {props.bonusRule.ruleFilters && (
        <Grid item md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Werte</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.bonusRule.ruleFilters
                .filter(
                  ruleFilter =>
                    ruleFilter.ruleFilterPropertyName !==
                    RuleFilterCustomerPropertyType.CIRCLE_NUMBER
                )
                .map(filter => (
                  <TableRow key={filter.ruleFilterNumber}>
                    <TableCell>
                      {
                        props.configGroups
                          .map(configGroup => configGroup.values)
                          .flat()
                          .find(
                            value => value.key === filter.ruleFilterPropertyName
                          )?.description
                      }
                    </TableCell>
                    <TableCell>
                      {
                        ruleFilterApplyTypeOptions.find(
                          type => filter.ruleFilterApplyType === type.value
                        )?.label
                      }
                    </TableCell>
                    <TableCell>
                      {filter.ruleFilterValues.slice(0, 5).join(", ")}
                      {filter.ruleFilterValues.length > 5 && "..."}
                    </TableCell>
                    {!props.viewMode && (
                      <TableCell>
                        <Button component="label">
                          <Edit
                            style={{
                              marginLeft: theme.spacing(2),
                              fontSize: 24,
                              backgroundColor: theme.palette.primary.main,
                              color: COLOR_GRAY_1,
                              cursor: "hand",
                              borderRadius: "50%",
                              padding: 1,
                            }}
                            onClick={() => {
                              if (
                                !filter.ruleFilterNumber ||
                                filter.ruleFilterNumber.startsWith(
                                  "NEW-FILTER-"
                                )
                              ) {
                                setEditFilterNumber(filter.ruleFilterNumber);
                              }
                              return false;
                            }}
                          />
                          {filter.ruleFilterNumber &&
                            !filter.ruleFilterNumber.startsWith(
                              "NEW-FILTER-"
                            ) && (
                              <input
                                type="file"
                                accept=".csv"
                                hidden
                                onChange={e =>
                                  uploadFilterValues(e, filter.ruleFilterNumber)
                                }
                              />
                            )}
                        </Button>
                        <Button component="label">
                          <Delete
                            style={{
                              marginLeft: theme.spacing(2),
                              fontSize: 24,
                              backgroundColor: theme.palette.primary.main,
                              color: COLOR_GRAY_1,
                              cursor: "hand",
                              borderRadius: "50%",
                              padding: 1,
                            }}
                            onClick={() => {
                              setDeleteFilterNumber(filter.ruleFilterNumber);
                              return false;
                            }}
                          />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      )}
      {!props.viewMode && (
        <Grid container justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={addFilter}>
            <Add />
            Neuer Filter
          </Button>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  configGroups: state.configGroups.configGroups,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDeleteBonusRuleFilter }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Filter);
