import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const FIND_PROPERTY_GROUP_VALUES = "FIND_PROPERTY_GROUP_VALUES";

export const thunkFindPropertyGroupValues =
  (propertyNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const propertyGroupValues = await Api.findPropertyGroupValues(
        propertyNumber
      );
      dispatch({
        type: FIND_PROPERTY_GROUP_VALUES,
        payload: propertyGroupValues,
        propertyNumber,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Laden der Filterwerte mit der ID " + propertyNumber,
          e
        )
      );
      return false;
    }
  };
