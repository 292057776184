import { Grid, Switch } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { BonusRuleDto } from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { interactionTypeOptions, typeOptions } from "./BonusRuleForm";
import BonusRuleMenu from "./BonusRuleMenu";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";

export function getCustomerInteractionDescription(row: BonusRuleDto): string {
  if (row.type === BonusRuleType.TRANSACTION)
    return typeOptions.filter(
      typeOption => typeOption.value === BonusRuleType.TRANSACTION
    )[0].label;
  const interactionTypeOption = interactionTypeOptions.filter(
    interactionTypeOption =>
      interactionTypeOption.value === row.customerInteractionType
  );
  return interactionTypeOption[0]?.label || "Eigene Aktivität";
}

export function getUnitNames(
  unitNumbers: string[],
  operationalUnits: OperationalUnitDto[]
): string {
  const unitNames = operationalUnits
    .filter(unit => unitNumbers.includes(unit.unitNumber))
    .slice(0, 5)
    .map(unit => unit.unitName);

  if (unitNames.length > 0) {
    return unitNames.join(", ") + (unitNumbers.length > 5 ? "..." : "");
  }

  return "";
}

const BonusTable = (props: ThunkProps) => {
  const [showInactiveRules, setShowInactiveRules] = useState(false);

  const headCells: ImsHeadCell<BonusRuleDto>[] = [
    { val: val => val.ruleName, node: "Name" },
    { val: val => val.validFrom, node: "Ausführung" },
    { val: val => val.unitNumbers, node: ["Filialen"] },
    { val: val => val.value, node: "Punkte" },
    { val: val => val.type, node: "Aktivität" },
    { val: val => val.status, node: "Status" },
  ];

  const rows: ImsTableRow<BonusRuleDto>[] = props.bonusRules.map(row => ({
    value: row,
    nodes: [
      <b>{row.ruleName}</b>,
      <>
        {!row.validTill ? " ab " : ""} <DateTag date={row.validFrom} />
        {row.validTill ? " bis " : ""} <DateTag date={row.validTill} />
      </>,
      getUnitNames(row.unitNumbers, props.operationalUnits),
      Number(row.value) +
        (row.valueType === BonusRuleValueType.FACTOR ? " pro Euro" : ""),
      getCustomerInteractionDescription(row),
      row.status === BonusRuleStatus.ENABLED ? "aktiv" : "inaktiv",
      <BonusRuleMenu bonusRule={row} />,
    ],
    disabled: row.status === BonusRuleStatus.DISABLED,
  }));

  return (
    <Grid>
      <Grid container item justifyContent="flex-end" alignItems="baseline">
        inaktive Regeln anzeigen
        <Switch
          color="primary"
          checked={showInactiveRules}
          onChange={() => setShowInactiveRules(!showInactiveRules)}
        />
      </Grid>

      <ImsTable
        hasMenu={true}
        headCells={headCells}
        rows={getFilteredInactiveRows(rows, showInactiveRules)}
      />
    </Grid>
  );
};

const getFilteredInactiveRows = (
  rows: ImsTableRow<BonusRuleDto>[],
  showInactiveRules: boolean
): ImsTableRow<BonusRuleDto>[] =>
  rows.filter(row => showInactiveRules || !row.disabled);

const mapStateToProps = (state: IStore) => ({
  bonusRules: state.bonusRules.bonusRules,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusTable);
