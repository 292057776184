import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkGetCustomerActivities,
  thunkGetCustomerActivitiesYear,
} from "../../store/actions/DashboardActions";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import CustomerActivityChart from "./CustomerActivityChart";
import CustomerActivityCount from "./CustomerActivityCount";

const CustomerActivityPanel = (props: ThunkProps) => {
  const theme = useTheme();
  const { customerActivities } = props;
  if (!customerActivities) {
    props.thunkGetCustomerActivities();
    props.thunkGetCustomerActivitiesYear();
    return null;
  }
  return (
    <>
      <Paper
        style={{
          padding: theme.spacing(3),
          paddingBottom: theme.spacing(6),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
        }}
      >
        <Grid item xs={12} style={{ marginBottom: theme.spacing(3) }}>
          <Typography variant="h3">Kundenaktivität</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <ArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.thunkGetCustomerActivities(
                  (customerActivities.offset || 0) + 1
                );
              }}
            />
          </Grid>
          <Grid item md>
            <CustomerActivityChart />
          </Grid>
          <Grid item>
            <ArrowForwardIos
              onClick={() => {
                if (customerActivities.offset)
                  props.thunkGetCustomerActivities(
                    customerActivities.offset - 1
                  );
              }}
              style={{
                color: customerActivities.offset ? COLOR_BLACK : COLOR_GRAY_1,
                cursor: customerActivities.offset ? "pointer" : undefined,
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            <Grid
              item
              md
              style={{
                paddingBottom: "25%",
              }}
            >
              <CustomerActivityCount />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerActivities: state.dashboard.customerActivities,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkGetCustomerActivities, thunkGetCustomerActivitiesYear },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerActivityPanel);
