import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction, IStore } from "../IStore";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_CUSTOMER_HISTORY = "GET_CUSTOMER_HISTORY";

export const thunkGetCustomerHistory =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const customerHistory = await Api.getCustomerHistory(customerNumber);
      dispatch({
        type: GET_CUSTOMER_HISTORY,
        payload: customerHistory,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          `Fehler beim Laden der Aktivitäten für Kunde ${customerNumber}: `,
          e
        )
      );
      return false;
    }
  };

// reload Activity feed after three seconds
export const reloadCustomerHistory = (
  dispatch: ThunkDispatch<IStore, null, Action<string>>,
  customerNumber: string
) =>
  setTimeout(
    () =>
      dispatch(thunkGetCustomerHistory(customerNumber) as BooleanThunkAction),
    3000
  );
