import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IStore } from "../../store/IStore";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { OperationalUnitStatus } from "../../store/models/operationalUnit/OperationalUnitStatus";
import { OperationalUnitType } from "../../store/models/operationalUnit/OperationalUnitType";
import MenuLayout from "../app/MenuLayout";
import OperationalUnitForm from "./OperationalUnitForm";

interface OperationalUnitFormPageProps {
  operationalUnits: OperationalUnitDto[];
}

let defaultOperationalUnit: OperationalUnitDto = {
  unitName: "",
  unitNumber: "",
  externalUnitNumber: "",
  unitType: OperationalUnitType.BRANCH_STORE,
  unitStatus: OperationalUnitStatus.ENABLED,
  parentUnitNumber: "",
};

const OperationalUnitFormPage = (props: OperationalUnitFormPageProps) => {
  const { operationalUnits } = props;
  const { id } = useParams<"id">();
  const oUToEdit = operationalUnits.find((ou) => ou.unitNumber === id);
  if (!oUToEdit) {
    const rootUnit = operationalUnits.find((ou) => !ou.parentUnitNumber)!;
    defaultOperationalUnit = {
      ...defaultOperationalUnit,
      parentUnitNumber: rootUnit && rootUnit.unitNumber,
    };
  }

  return (
    <MenuLayout
      headline={
        oUToEdit ? oUToEdit.unitName + " bearbeiten" : "Neue Filiale/-gruppe"
      }
    >
      <OperationalUnitForm
        operationalUnit={oUToEdit || defaultOperationalUnit}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

export default connect(mapStateToProps)(OperationalUnitFormPage);
