import { ConfigDto } from './../../store/models/config/ConfigDto';
import { LocalDate } from "@js-joda/core";
import { ValidationErrors } from "final-form";
import {
  CustomerDto,
  CUSTOMER_FIELDS,
} from "../../store/models/customer/CustomerDto";
import {
  FieldConfigDto,
  getAllowUnchangedFields,
  getMandatoryFields,
  getOptionalFields,
} from "../../store/models/fieldConfig/FieldConfigDto";
import {
  RegistrationDto,
  REGISTRATION_FIELDS,
} from "../../store/models/registration/RegistrationDto";

export function validateCustomerFields(
  errors: ValidationErrors,
  customer: CustomerDto,
  updatedCustomer: CustomerDto,
  customerFieldConfig: FieldConfigDto[]
) {
  if (!errors) return;
  if (!customerFieldConfig.length) return errors;

  const mandatoryMap = getMandatoryMap(CUSTOMER_FIELDS, customerFieldConfig);

  const allowUnchanged = getAllowUnchangedFields(customerFieldConfig);
  mandatoryMap.forEach((propertyName) => {
    const updatedPropertyValue = getValueByPropertyName(
      updatedCustomer,
      propertyName
    );
    if (!updatedPropertyValue) {
      const propertyValue = getValueByPropertyName(customer, propertyName);

      const propertyAllowUnchanged = allowUnchanged.includes(
        propertyName.toUpperCase()
      );

      if (
        (propertyValue && propertyAllowUnchanged) ||
        !propertyAllowUnchanged
      ) {
        setErrors(errors, "Pflichtfeld", propertyName);
      }
    }
  });
  return errors;
}

export function validateRegistrationFields(
  errors: ValidationErrors,
  registration: RegistrationDto,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return errors;

  const mandatoryMap = getMandatoryMap(
    REGISTRATION_FIELDS,
    registrationFieldConfig
  );

  mandatoryMap.forEach((propertyName) => {
    if (!getValueByPropertyName(registration, propertyName)) {
      if (propertyName !== REGISTRATION_FIELDS.EMAILCORRESPONDENCEALLOWED) {
        setErrors(errors, "Pflichtfeld", propertyName);
      }
    }
  });

  return errors;
}

function getValueByPropertyName(obj: any, propertyName: string) {
  return (
    obj &&
    propertyName &&
    propertyName
      .split(".")
      .reduce((prev, cur) => (prev == null ? undefined : prev[cur]), obj)
  );
}

function setErrors(
  errors: ValidationErrors,
  errorMessage: string,
  propertyName: string
) {
  if (!errors) return;
  if (!propertyName) return errors;
  const props = propertyName.split(".");

  if (props.length === 1) {
    errors[props[0]] = errorMessage;
  } else if (props.length === 2) {
    if (!errors[props[0]]) {
      errors[props[0]] = {};
    }
    errors[props[0]][props[1]] = errorMessage;
  }
}

export function isFieldMandatory(
  fieldName: string,
  fieldConfig: FieldConfigDto[]
) {
  if (!fieldConfig.length) return undefined;
  const mandatoryFields = getMandatoryFields(fieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");
  return mandatoryFields.some(
    (mandatoryField) => mandatoryField === fieldKeyFromName
  );
}

function isFieldOptional(
  fieldName: string,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return undefined;

  const optionalFields = getOptionalFields(registrationFieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return optionalFields.some(
    (mandatoryField) => mandatoryField === fieldKeyFromName
  );
}

export function isFieldNeeded(
  fieldName: string,
  fieldConfig: FieldConfigDto[]
) {
  if (!fieldConfig.length) return true;
  return (
    isFieldMandatory(fieldName, fieldConfig) ||
    isFieldOptional(fieldName, fieldConfig)
  );
}

function getMandatoryMap(
  fieldNames: any,
  fieldConfig: FieldConfigDto[]
): string[] {
  const mandatoryFields = getMandatoryFields(fieldConfig);
  if (!mandatoryFields) return [];
  return mandatoryFields.map((prop) => fieldNames[prop]);
}

export function formatName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/(^| |-)(?!de|der|van|von|zur|zum|zu)(\w)/g, (s) =>
      s.toUpperCase()
    );
}

export function validateNameLength(name: string): boolean {
  return name.split(/[ ,-]+/).every((a) => a.length > 1);
}

export function validateLatin(name: string): boolean {
  return /^[a-zA-ZÀ-ÿ\-'’` ]*$/.test(name);
}

export function validateDateOfBirth(
  errors: ValidationErrors,
  dateOfBirth?: string
) {
  if (!errors) return;
  if (dateOfBirth) {
    const date = LocalDate.parse(dateOfBirth);
    if (date.isAfter(LocalDate.now().minusYears(18))) {
      errors.dateOfBirth = "Sie müssen mindestens 18 Jahre alt sein";
    }
    if (date.year() < 1900) {
      errors.dateOfBirth = "Das Geburtsdatum ist ungültig";
    }
  }
  return errors;
}

export function validateName(
  errors: ValidationErrors,
  firstName?: string,
  lastName?: string
) {
  if (!errors) return;
  if (firstName) {
    firstName = formatName(firstName);
    if (!validateNameLength(firstName)) {
      errors.firstName =
        "Der Vorname muss mindestens zwei Buchstaben enthalten";
    }
    if (!validateLatin(firstName)) {
      errors.firstName =
        "Der Vorname darf nur lateinische Buchstaben enthalten";
    }
  }
  if (lastName) {
    lastName = formatName(lastName);
    if (!validateNameLength(lastName)) {
      errors.lastName =
        "Der Nachname muss mindestens zwei Buchstaben enthalten";
    }
    if (!validateLatin(lastName)) {
      errors.lastName =
        "Der Nachname darf nur lateinische Buchstaben enthalten";
    }
  }
  return errors;
}

export function validateRegistration(
  errors: ValidationErrors,
  password: string,
  passwordConfirm: string | undefined
) {
  if (!errors) return;

  if (!errors.login) {
    errors.login = {};
  }
  if (!passwordConfirm) {
    errors.login.passwordConfirm = "Pflichtfeld";
  }
  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.login.passwordConfirm =
      "Die Passwortbestätigung ist nicht identisch.";
  }

  return errors;
}

export function isVisibleField(
  fieldName: string,
  displayConfig: ConfigDto[]
) {
  if (!displayConfig.length) return true;
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return displayConfig.some(
    (config) => config.key === fieldKeyFromName && config.value.toLocaleLowerCase() === "true"
  );
}
