import { ListItem, ListItemText, Theme, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { IStore } from "../../store/IStore";
import { COLOR_WHITE, KNISTR_BLUE, KNISTR_CYAN } from "../atoms/ImsMaterialTheme";
import useMenuMatch from "./customHook/useMenuMatch";
import { LightTooltip } from "./LightToolTip";

interface ListItemLinkProps {
  icon?: ReactNode;
  primaryText: string;
  to: string;
  visible?: boolean;
}

const ListItemLink = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme, props.isMenuOpen);
  const menuMatch = useMenuMatch(props.to);
  if (props.visible === false) return null;

  let navListButtonClass = classes.navListButton;
  if (menuMatch)
    navListButtonClass = {
      ...navListButtonClass,
      ...classes.navListButtonActive,
    };

  return (
    <LightTooltip
      title={props.primaryText}
      placement="right"
      disableHoverListener={props.isMenuOpen}
      disableFocusListener={props.isMenuOpen}
      disableTouchListener={props.isMenuOpen}
    >
      <ListItem
        component={Link}
        to={props.to}
        sx={navListButtonClass}
        className="navListItem"
      >
        {props.icon}
        <ListItemText
          sx={classes.navListItemText}
          primary={props.primaryText}
        />
      </ListItem>
    </LightTooltip>
  );
};

const styles = (theme: Theme, isMenuOpen: boolean) => ({
  navListItemText: {
    paddingLeft: theme.spacing(2),
    display: isMenuOpen ? "block" : "none",
  },
  navListButtonActive: {
    backgroundColor: KNISTR_CYAN,
    color: KNISTR_BLUE,
  },
  navListButton: {
    width: isMenuOpen ? theme.spacing(30) : "49px",
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(5),
    color: COLOR_WHITE,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});

const mapStateToProps = (state: IStore, ownProps: ListItemLinkProps) => ({
  isMenuOpen: state.menu?.open,
  ...ownProps,
});

const connector = connect(mapStateToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ListItemLink);
