import { AnyAction } from "redux";
import { MENU_STATE } from "../actions/MenuActions";

export interface IMenuReducerState {
  open: boolean;
}
export default class MenuReducer {
  public static reducer(
    state: IMenuReducerState,
    action: AnyAction
  ): IMenuReducerState {
    switch (action.type) {
      case MENU_STATE:
        return {
          ...state,
          open: action.payload!,
        };
      default:
        return state || {};
    }
  }
}
