export interface FlowGroupDto {
  flowGroupNumber: string;
  flowType: FlowType;
  bonusCurrency: BonusCurrency;
  periodStartType: PeriodStartType;
  periodStartValue?: string;
  periodDuration?: string;
  periodDurationUnit?: PeriodDurationUnit;
  flowDetails: FlowDetailsDto[];
  negativeAllowed: boolean;
  carryOver: boolean;
}

export interface FlowDetailsDto {
  flowDetailNumber?: string;
  threshold: string;
  flowDirection: FlowDirection;
  origin?: string;
  target?: string;
  immediate?: boolean;
}

export enum PeriodStartType {
  START_OF_YEAR = "START_OF_YEAR",
  FIXED_DATE = "FIXED_DATE",
}

export const periodStartOptions = [
  { value: PeriodStartType.START_OF_YEAR, label: "Jahresanfang" },
  { value: PeriodStartType.FIXED_DATE, label: "Fixes Datum" },
];

export enum PeriodDurationUnit {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export const periodDurationUnitOptions = [
  { value: PeriodDurationUnit.DAY, label: "Tag" },
  { value: PeriodDurationUnit.MONTH, label: "Monat" },
  { value: PeriodDurationUnit.YEAR, label: "Jahr" },
];

export enum FlowType {
  CIRCLE = "CIRCLE",
  IDENTIFICATION = "IDENTIFICATION",
  BONUS_PREMIUM = "BONUS_PREMIUM",
}

export enum FlowTypeTrans {
  CIRCLE = "Circle",
  IDENTIFICATION = "Wallet",
  BONUS_PREMIUM = "BONUS_PREMIUM",
}

export const flowTypeOptions = [
  { value: FlowType.CIRCLE, label: "Circle" },
  { value: FlowType.IDENTIFICATION, label: "Wallet" },
  { value: FlowType.BONUS_PREMIUM, label: "Bonusprämie" },
];

export enum BonusCurrency {
  BONUS_POINT = "BONUS_POINT",
  REWARD_POINT = "REWARD_POINT",
  PAYMENT_AMOUNT = "PAYMENT_AMOUNT",
}

export const bonusCurrencyOptions = [
  { value: BonusCurrency.BONUS_POINT, label: "Bonuspunkte" },
  { value: BonusCurrency.REWARD_POINT, label: "Rewardpunkte" },
  { value: BonusCurrency.PAYMENT_AMOUNT, label: "Euro Umsatz" },
];

export enum FlowDirection {
  UPGRADE = "UPGRADE",
  DOWNGRADE = "DOWNGRADE",
}

export enum FlowDirectionTrans {
  UPGRADE = "Aufstufen",
  DOWNGRADE = "Herabstufen",
}

export const flowDirectionOptions = [
  { value: FlowDirection.UPGRADE, label: "überschritten" },
  { value: FlowDirection.DOWNGRADE, label: "unterschritten" },
];
