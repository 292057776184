import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathIdentificationType } from "../../../api/url";
import {
  thunkCreateIdentificationType,
  thunkUpdateIdentificationType,
} from "../../../store/actions/IdentificationActions";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import { FormCheckbox } from "../../atoms/FormCheckbox";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../../atoms/Utils";

interface IdentificationTypeFormProps {
  identificationType: IdentificationTypeDto;
}

const IdentificationTypeForm = (
  props: IdentificationTypeFormProps & ThunkProps
) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { identificationType } = props;
  const saveIdentificationType = async (
    identificationTypeToSave: IdentificationTypeDto
  ) => {
    if (identificationTypeToSave.identificationTypeNumber) {
      await props.thunkUpdateIdentificationType(identificationTypeToSave);
    } else {
      await props.thunkCreateIdentificationType(identificationTypeToSave);
    }
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to={UrlPathIdentificationType} />;
  }

  const validateForm = (values: IdentificationTypeDto) => {
    const errors: ValidationErrors<IdentificationTypeDto> = {};
    if (isBlank(values.identificationTypeName)) {
      errors.identificationTypeName =
        "Bitte geben Sie eine valide Bezeichnung der Wallet ein";
    }
    if (isBlank(values.externalIdentificationTypeNumber)) {
      errors.externalIdentificationTypeNumber =
        "Bitte geben Sie eine Kennung der Wallet ein";
    }
    return errors;
  };
  return (
    <Form
      onSubmit={saveIdentificationType}
      initialValues={identificationType}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text="Wie lauten die Details der Wallet?" />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>ID</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<IdentificationTypeDto>(
                      "externalIdentificationTypeNumber"
                    )}
                    label="Kennung der Wallet"
                    readOnly={Boolean(values.identificationTypeNumber)}
                  />
                </Grid>
                <Grid item md={12}>
                  <b>Bezeichnung</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<IdentificationTypeDto>(
                      "identificationTypeName"
                    )}
                    label="Bezeichnung der Wallet"
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={12}>
                    <FormCheckbox
                      name={nameof<IdentificationTypeDto>(
                        "defaultIdentification"
                      )}
                      label="Standard für Neukunden"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateIdentificationType,
      thunkCreateIdentificationType,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeForm);
