import { Typography } from "@mui/material";
import { formatCustomerInteractionType } from "../../store/models/bonusRule/CustomerInteractionType";
import {
  CustomerInteractionDto,
  InteractionType,
} from "../../store/models/customer/CustomerInteractionDto";
import { FamilyDto } from "../../store/models/family/FamilyDto";
import { CustomerHistoryDto } from "../../store/models/history/HistoryDto";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { getYearFromTimestamp } from "../atoms/Utils";

export interface PaymentModel extends CustomerInteractionDto {
  displayedPaymentAmount: number;
  displayedCashValue: number;
  bonusReasons?: React.ReactElement[];
  year?: number;
  unitName?: string;
}

export const getPaymentModel = (
  customerHistory: CustomerHistoryDto[],
  operationalUnits: OperationalUnitDto[],
  filter: (history: PaymentModel) => unknown
): PaymentModel[] =>
  customerHistory
    .filter((history) => history.interaction)
    .map((history) => history.interaction!)
    .map((interaction) => ({
      displayedPaymentAmount: Number(
        interaction.paymentAmount ||
          (interaction.reversalAmount && -interaction.reversalAmount)
      ),
      displayedCashValue: Number(
        (interaction.cashValue && -interaction.cashValue) ||
          interaction.reversalCashValue
      ),
      bonusReasons: interaction.rewardedInteractions?.map((interaction) => (
        <>
          {interaction.bonusReason}
          <br />
        </>
      )),
      year: getYearFromTimestamp(interaction.interactionTimestamp),
      unitName: operationalUnits.find(
        (unit) => unit.externalUnitNumber === interaction.externalUnitNumber
      )?.unitName,
      ...interaction,
    }))
    .filter(filter);

export function formatCustomerName(customerNumber: string, family?: FamilyDto) {
  const customer = family?.familyMembers.find(
    (customers) => customers.customerNumber === customerNumber
  )?.customerDto;
  return customer?.firstName || customer?.mailAddress || "";
}

export function formatInteractionCol(row: PaymentModel) {
  switch (row.interactionType) {
    case InteractionType.PAYMENT_INTERACTION:
      return (
        <>
          <b>Einkauf | {row.unitName}</b>
          <Typography align="left" variant="body1">
            {row.bonusReasons}
          </Typography>
          <Typography align="left" variant="body1">
            Bezahlt
          </Typography>
        </>
      );
    case InteractionType.REVERSAL_INTERACTION:
      return <>Storno Einkauf | {row.unitName}</>;
    case InteractionType.COUPON_INTERACTION:
      return "Coupon gekauft";
    case InteractionType.BONUS_XPRESS_INTERACTION:
      return <>Bonus XPress | {row.unitName}</>;
    case InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION:
      return <>Bonus XPress | {row.unitName}</>;
    case InteractionType.CUSTOMER_INTERACTION:
      return formatCustomerInteractionType(row.customerInteractionType!);
    case InteractionType.ADJUSTMENT_EARN_INTERACTION:
      return `Kulanzgutschrift | ${row.unitName}`;
    case InteractionType.ADJUSTMENT_BURN_INTERACTION:
      return `Belastung | ${row.unitName}`;
    case InteractionType.EXPIRED_REWARD_INTERACTION:
      return "Abzug verfallener Bonuspunkte";
    default:
      return row.interactionType;
  }
}
