import { combineReducers, Reducer, ReducersMapObject } from "redux";
import { IStore } from "../IStore";
import BonusPremiumReducer from "./BonusPremiumReducer";
import BonusRuleReducer from "./BonusRuleReducer";
import BonusXpressReducer from "./BonusXpressReducer";
import CampaignReducer from "./CampaignReducer";
import CircleReducer from "./CircleReducer";
import ConfigGroupReducer from "./ConfigGroupReducer";
import CouponReducer from "./CouponReducer";
import CustomerCareReducer from "./CustomerCareReducer";
import DashboardReducer from "./DashboardReducer";
import FlowReducer from "./FlowReducer";
import GdprReducer from "./GdprReducer";
import IdentificationReducer from "./IdentificationReducer";
import InteractionSearchReducer from "./InteractionSearchReducer";
import LoginReducer from "./LoginReducer";
import MenuReducer from "./MenuReducer";
import NotificationsReducer from "./NotificationsReducer";
import OperationalUnitReducer from "./OperationalUnitReducer";
import PreferencesReducer from "./PreferencesReducer";
import RegistrationReducer from "./RegistrationReducer";
import UserReducer from "./UserReducer";

const reducerMap: ReducersMapObject = {
  preferences: PreferencesReducer.reducer,
  bonusRules: BonusRuleReducer.reducer,
  bonusPremiums: BonusPremiumReducer.reducer,
  bonusXpress: BonusXpressReducer.reducer,
  campaigns: CampaignReducer.reducer,
  customerCare: CustomerCareReducer.reducer,
  operationalUnits: OperationalUnitReducer.reducer,
  notifications: NotificationsReducer.reducer,
  users: UserReducer.reducer,
  circles: CircleReducer.reducer,
  identifications: IdentificationReducer.reducer,
  flows: FlowReducer.reducer,
  registration: RegistrationReducer.reducer,
  coupons: CouponReducer.reducer,
  dashboard: DashboardReducer.reducer,
  login: LoginReducer.reducer,
  interactionSearch: InteractionSearchReducer.reducer,
  gdpr: GdprReducer.reducer,
  configGroups: ConfigGroupReducer.reducer,
  menu: MenuReducer.reducer,
};

export default combineReducers(reducerMap) as Reducer<IStore>;
