import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import React from "react";
import NotificationList from "../notification/NotificationList";
import HistoryFeedTable from "./HistoryFeedTable";

interface HistoryFeedDialogProps {
  setHistoryFeedDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HistoryFeedDialog = (props: HistoryFeedDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      onClose={() => props.setHistoryFeedDialogVisible(false)}
      open={true}
      maxWidth="xl"
    >
      <NotificationList isDialog />
      <DialogTitle>Letzte Aktivitäten</DialogTitle>
      <DialogContent style={{ paddingBottom: theme.spacing(8) }}>
        <HistoryFeedTable />
      </DialogContent>
      <DialogActions style={{ padding: theme.spacing(3) }}>
        <Button
          id="btndone"
          color="primary"
          type="button"
          variant="contained"
          onClick={() => {
            props.setHistoryFeedDialogVisible(false);
          }}
        >
          FERTIG
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryFeedDialog;
