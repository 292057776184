export const UrlPathMain =
  (window.env.PUBLIC_URL || process.env.PUBLIC_URL) + "/";

export const TabCircle = "circle";
export const TabFlow = "flow";
export const TabWallet = "wallet";
export const TabDSGVO = "dsgvo";
export const TabFilter = "receiptdetail";
export const TabCustomField = "customfield";

export const UrlPathReports = UrlPathMain + "report";
export const UrlPathPreferences = UrlPathMain + "preferences";
export const UrlPathPreferencesCreate = UrlPathPreferences + "/create";
export const UrlPathPreferencesEdit = UrlPathPreferences + "/:id/edit";
export const UrlPathBonus = UrlPathMain + "bonus";
export const UrlPathBonusCreate = UrlPathBonus + "/create";
export const UrlPathBonusViewMode = UrlPathBonus + "/:id/:viewMode/";
export const UrlPathBonusPremium = UrlPathMain + "bonuspremium";
export const UrlPathBonusPremiumCreate = UrlPathBonusPremium + "/create";
export const UrlPathBonusPremiumViewMode =
  UrlPathBonusPremium + "/:id/:viewMode";
export const UrlPathBonusXpress = UrlPathMain + "bonusxpress";
export const UrlPathCustomerSearch = UrlPathMain + "customer";
export const UrlPathCustomer = UrlPathMain + "customer";
export const UrlPathCustomerEdit = UrlPathCustomer + "/:id/edit";
export const UrlPathCustomerDetails = UrlPathCustomer + "/:id/details";
export const UrlPathCustomerPreferences = UrlPathCustomer + "/:id/preferences";
export const UrlPathCampaigns = UrlPathMain + "campaign";
export const UrlPathCampaignCreate = UrlPathCampaigns + "/create";
export const UrlPathCampaignEdit = UrlPathCampaigns + "/:id/edit";
export const UrlPathOperationalUnit = UrlPathMain + "operationalunit";
export const UrlPathOperationalUnitCreate = UrlPathOperationalUnit + "/create";
export const UrlPathOperationalUnitEdit = UrlPathOperationalUnit + "/:id/edit";
export const UrlPathUser = UrlPathMain + "user";
export const UrlPathUserCreate = UrlPathUser + "/create";
export const UrlPathUserEdit = UrlPathUser + "/:id/edit";
export const UrlPathRegistration = UrlPathCustomer + "/register";
export const UrlPathFamily = UrlPathMain + "family/:id";
export const UrlPathCircle = UrlPathMain + TabCircle;
export const UrlPathCircleCreate = UrlPathCircle + "/create";
export const UrlPathCircleEdit = UrlPathCircle + "/:id/edit";
export const UrlPathIdentificationType = UrlPathMain + TabWallet;
export const UrlPathIdentificationTypeCreate =
  UrlPathIdentificationType + "/create";
export const UrlPathIdentificationTypeEdit =
  UrlPathIdentificationType + "/:id/edit";
export const UrlPathFlow = UrlPathMain + TabFlow;
export const UrlPathFlowCreate = UrlPathFlow + "/create";
export const UrlPathFlowEdit = UrlPathFlow + "/:id/edit";
export const UrlPathGdpr = UrlPathMain + TabDSGVO;
export const UrlPathInteractionSearch = UrlPathMain + "interactionsearch";
export const UrlPathReceiptDetail = UrlPathMain + TabFilter;
export const UrlPathCustomField = UrlPathMain + TabCustomField;
export const UrlPathConfigValueCreate = (url: string) => url + "/create";
export const UrlPathConfigValueEdit = (url: string) => url + "/:id/edit";
export const UrlPathLogin = UrlPathMain + "login";

const unauthenticatedPaths = [UrlPathLogin];

export const isUnauthenticatedPath = () =>
  unauthenticatedPaths.includes(window.location.pathname);
