import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  useTheme,
} from "@mui/material";
import React from "react";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import NotificationList from "../notification/NotificationList";
import CouponTable from "./CouponTable";

interface CouponDialogProps {
  setCouponDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CouponDialog = (props: CouponDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      onClose={() => props.setCouponDialogVisible(false)}
      open={true}
      fullWidth={true}
      maxWidth="md"
    >
      <NotificationList isDialog />
      <DialogTitle style={{ padding: theme.spacing(3) }}>
        <Grid container>
          <Grid item md={12}>
            <b>Coupons</b>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <DialogContent
        style={{
          padding: theme.spacing(0),
          overflow: "hidden",
          display: "flex",
        }}
      >
        <CouponTable />
      </DialogContent>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <Grid
        container
        justifyContent="flex-end"
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Button
          id="btndone"
          color="primary"
          type="button"
          variant="contained"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.setCouponDialogVisible(false);
          }}
        >
          FERTIG
        </Button>
      </Grid>
    </Dialog>
  );
};

export default CouponDialog;
