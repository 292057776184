import { AnyAction } from "redux";
import {
  ACTIVATE_BONUS_RULE,
  ADD_BONUS_RULE,
  DEACTIVATE_BONUS_RULE,
  DELETE_BONUS_RULE,
  GET_BONUS_RULES,
  UPDATE_BONUS_RULE,
  ADD_BONUS_RULE_FILTER,
  UPDATE_BONUS_RULE_FILTER,
  DELETE_BONUS_RULE_FILTER,
} from "../actions/BonusRuleActions";
import { FIND_PROPERTY_GROUP_VALUES } from "../actions/PropertyGroupActions";
import {
  BonusRuleDto,
  FilterDto,
  RuleFilterCustomerPropertyType,
} from "../models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../models/bonusRule/BonusRuleStatus";

export interface IBonusRuleReducerState {
  bonusRules: BonusRuleDto[];
}

export default class BonusRuleReducer {
  public static reducer(
    state: IBonusRuleReducerState,
    action: AnyAction
  ): IBonusRuleReducerState {
    switch (action.type) {
      case GET_BONUS_RULES:
        return {
          ...state,
          bonusRules: BonusRuleReducer.filterExpiredFilters(
            action.payload as BonusRuleDto[]
          ),
        };
      case ADD_BONUS_RULE:
        return {
          ...state,
          bonusRules: [...state.bonusRules, action.payload],
        };
      case UPDATE_BONUS_RULE:
        return {
          ...state,
          bonusRules: BonusRuleReducer.updateRule(
            state.bonusRules,
            action.payload as BonusRuleDto
          ),
        };
      case ADD_BONUS_RULE_FILTER:
        return {
          ...state,
          bonusRules: BonusRuleReducer.addFilter(
            state.bonusRules,
            action.ruleNumber,
            action.payload
          ),
        };
      case UPDATE_BONUS_RULE_FILTER:
        return {
          ...state,
          bonusRules: BonusRuleReducer.updateFilter(
            state.bonusRules,
            action.filterNumber,
            action.payload
          ),
        };
      case DELETE_BONUS_RULE_FILTER:
        return {
          ...state,
          bonusRules: BonusRuleReducer.deleteFilter(
            state.bonusRules,
            action.payload
          ),
        };
      case DELETE_BONUS_RULE:
        return {
          ...state,
          bonusRules: BonusRuleReducer.deleteBonusRule(
            state.bonusRules,
            action.payload.ruleNumber
          ),
        };
      case ACTIVATE_BONUS_RULE:
        return {
          ...state,
          bonusRules: BonusRuleReducer.activateBonusRule(
            state.bonusRules,
            action.payload.ruleNumber
          ),
        };
      case DEACTIVATE_BONUS_RULE:
        return {
          ...state,
          bonusRules: BonusRuleReducer.deactivateBonusRule(
            state.bonusRules,
            action.payload.ruleNumber
          ),
        };
      case FIND_PROPERTY_GROUP_VALUES:
        return {
          ...state,
          bonusRules: BonusRuleReducer.findPropertyGroupValues(
            state.bonusRules,
            action.propertyNumber,
            action.payload
          ),
        };
      default:
        return state || { bonusRules: [] };
    }
  }
  private static filterExpiredFilters(
    bonusRules: BonusRuleDto[]
  ): BonusRuleDto[] {
    return bonusRules.map(rule => ({
      ...rule,
      ruleFilters: rule.ruleFilters?.filter(
        ruleFilter => !ruleFilter.hasOwnProperty("validTill")
      ),
    }));
  }

  private static updateRule(
    bonusRules: BonusRuleDto[],
    newRule: BonusRuleDto
  ): BonusRuleDto[] {
    return bonusRules.map(br =>
      br.ruleNumber === newRule.ruleNumber
        ? {
            ...newRule,
            ruleFilters: newRule.ruleFilters
              ?.filter(ruleFilter => !ruleFilter.hasOwnProperty("validTill"))
              .map(ruleFilter =>
                ruleFilter.ruleFilterPropertyName ===
                RuleFilterCustomerPropertyType.CIRCLE_NUMBER
                  ? { ...ruleFilter, ruleFilterValues: [] }
                  : ruleFilter
              ),
          }
        : br
    );
  }

  private static addFilter(
    bonusRules: BonusRuleDto[],
    ruleNumber: string,
    newFilter: FilterDto
  ): BonusRuleDto[] {
    return bonusRules.map(rule =>
      rule.ruleNumber === ruleNumber
        ? { ...rule, ruleFilters: [...rule.ruleFilters, newFilter] }
        : rule
    );
  }

  private static updateFilter(
    bonusRules: BonusRuleDto[],
    filterNumber: string,
    newFilter: FilterDto
  ): BonusRuleDto[] {
    return bonusRules.map(rule => ({
      ...rule,
      ruleFilters: rule.ruleFilters?.map(ruleFilter =>
        ruleFilter.ruleFilterNumber === filterNumber ? newFilter : ruleFilter
      ),
    }));
  }

  private static deleteFilter(
    bonusRules: BonusRuleDto[],
    filterNumber: string
  ): BonusRuleDto[] {
    return bonusRules.map(rule => ({
      ...rule,
      ruleFilters: rule.ruleFilters?.filter(
        ruleFilter => ruleFilter.ruleFilterNumber !== filterNumber
      ),
    }));
  }

  private static deleteBonusRule(
    bonusRules: BonusRuleDto[],
    ruleNumber: string
  ): BonusRuleDto[] {
    return bonusRules.filter(rule => rule.ruleNumber !== ruleNumber);
  }

  private static activateBonusRule(
    bonusRules: BonusRuleDto[],
    ruleNumber: string
  ): BonusRuleDto[] {
    return bonusRules.map(rule =>
      rule.ruleNumber !== ruleNumber
        ? rule
        : { ...rule, status: BonusRuleStatus.ENABLED }
    );
  }

  private static deactivateBonusRule(
    bonusRules: BonusRuleDto[],
    ruleNumber: string
  ): BonusRuleDto[] {
    return bonusRules.map(rule =>
      rule.ruleNumber !== ruleNumber
        ? rule
        : { ...rule, status: BonusRuleStatus.DISABLED }
    );
  }

  private static findPropertyGroupValues(
    bonusRules: BonusRuleDto[],
    propertyNumber: string,
    ruleFilterValues: string[]
  ): BonusRuleDto[] {
    return bonusRules.map(rule => ({
      ...rule,
      ruleFilters: rule.ruleFilters?.map(ruleFilter =>
        ruleFilter.ruleFilterPropertyGroupNumber === propertyNumber
          ? { ...ruleFilter, ruleFilterValues: ruleFilterValues }
          : ruleFilter
      ),
    }));
  }
}
