
import { styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { COLOR_BLACK } from "../atoms/ImsMaterialTheme";


//Customized ToolTip
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: COLOR_BLACK,
      boxShadow: theme.shadows[1],
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }));