import { Help } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Field } from "react-final-form";
import { ConfigDto } from "../../store/models/config/ConfigDto";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded, isVisibleField } from "./FieldValidation";
import { KNISTR_RED } from "./ImsMaterialTheme";

interface FormCheckboxProps {
  name: string;
  label: string;
  readOnly?: boolean;
  helperText?: string;
  tooltip?: string;
  fieldConfig?: FieldConfigDto[];
  displayConfig?: ConfigDto[];
}

export const FormCheckbox = (props: FormCheckboxProps) => {
  if (props.displayConfig && !isVisibleField(props.name, props.displayConfig)) {
    return null;
  } else if (
    props.fieldConfig &&
    !isFieldNeeded(props.name, props.fieldConfig)
  ) {
    return null;
  }

  return (
    <Field name={props.name} type="checkbox">
      {({ input, meta }) => {
        const hasErrors = meta.error && meta.touched;
        return (
          <>
            <FormControlLabel
              value="bottom"
              style={{
                verticalAlign: "sub",
                display: "block",
                color: hasErrors ? KNISTR_RED : undefined,
              }}
              control={
                <Checkbox
                  {...input}
                  id={props.name}
                  disabled={props.readOnly}
                  color="primary"
                  style={{
                    color: hasErrors ? KNISTR_RED : undefined,
                  }}
                />
              }
              label={props.label}
              labelPlacement="end"
              color="primary"
            />

            {props.tooltip && (
              <Tooltip title={props.tooltip}>
                <Help color="primary" />
              </Tooltip>
            )}
            {props.helperText && (
              <FormHelperText>{props.helperText}</FormHelperText>
            )}
          </>
        );
      }}
    </Field>
  );
};
