import { AnyAction } from "redux";
import {
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION_SUCCESS,
  GET_CUSTOMER_PREFERENCES,
  GET_QUESTIONS,
  UPDATE_QUESTION_SUCCESS,
} from "../actions/PreferenceActions";
import { PreferenceDto } from "../models/preferences/PreferenceDto";
import { QuestionDto } from "../models/preferences/QuestionDto";

export interface IPreferencesReducerState {
  message?: string;
  error?: string;
  questions: QuestionDto[];
  customerPreferences?: PreferenceDto;
}

export default class PreferencesReducer {
  public static reducer(
    state: IPreferencesReducerState,
    action: AnyAction
  ): IPreferencesReducerState {
    switch (action.type) {
      case GET_QUESTIONS:
        return { ...state, questions: action.payload };
      case GET_CUSTOMER_PREFERENCES:
        return { ...state, customerPreferences: action.payload };
      case UPDATE_QUESTION_SUCCESS:
      case CREATE_QUESTION_SUCCESS:
        return {
          ...state,
          questions: [
            ...state.questions.filter(
              (q) => q.questionNumber !== action.payload.questionNumber
            ),
            action.payload,
          ],
          message: "Ihre Daten wurden gespeichert",
        };
      case DELETE_QUESTION_SUCCESS:
        return {
          ...state,
          questions: [
            ...state.questions.filter(
              (q) => q.questionNumber !== action.payload.questionNumber
            ),
          ],
          message: "Ihre Daten wurden gelöscht",
        };

      default:
        return state || { questions: [] };
    }
  }
}
