import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  useTheme,
} from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkCreateAdjustmentBurn,
  thunkCreateAdjustmentEarn,
} from "../../store/actions/AdjustmentActions";
import { IStore } from "../../store/IStore";
import {
  AdjustmentDto,
  AdjustmentType,
  adjustmentTypeOptions,
} from "../../store/models/adjustment/AdjustmentDto";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";

interface UpdateAdjustmentDialogVisibleProps {
  setAdjustmentDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdjustmentDialog = (
  props: UpdateAdjustmentDialogVisibleProps & ThunkProps
) => {
  const { customer, operationalUnits, setAdjustmentDialogVisible } = props;
  const theme = useTheme();

  const submit = async (adjustment: AdjustmentDto) => {
    if (adjustment.type === AdjustmentType.EARN) {
      if (await props.thunkCreateAdjustmentEarn(adjustment)) {
        setAdjustmentDialogVisible(false);
      }
    } else {
      if (await props.thunkCreateAdjustmentBurn(adjustment)) {
        setAdjustmentDialogVisible(false);
      }
    }
  };

  if (!customer) return null;

  const operationalUnitOptions = getOperationalUnitOptions(
    undefined,
    operationalUnits,
    theme,
    true,
    true
  );

  const validateForm = (values: AdjustmentDto) => {
    const errors: ValidationErrors<AdjustmentDto> = {};

    if (!values.type) {
      errors.type = "Bitte geben Sie die Art der Kulanzgutschrift an.";
    }

    if (isBlank(values.externalUnitNumber)) {
      errors.externalUnitNumber = "Bitte geben Sie die Filiale an.";
    }

    if (isBlank(values.bonusPoints)) {
      errors.bonusPoints = "Bitte geben Sie die Bonuspunkte an.";
    }

    if (isBlank(values.note)) {
      errors.note = "Bitte geben Sie die Notiz an.";
    }
    return errors;
  };
  return (
    <Dialog
      onClose={() => setAdjustmentDialogVisible(false)}
      open={true}
      fullWidth
      maxWidth="sm"
    >
      <NotificationList isDialog />
      <DialogTitle>Neue Bonusbuchung</DialogTitle>
      <Divider
        style={{
          color: COLOR_GRAY_1,
          height: "2px",
        }}
        light={true}
      />
      <DialogContent
        style={{
          overflow: "hidden",
        }}
      >
        <Form
          onSubmit={submit}
          initialValues={{
            type: AdjustmentType.EARN,
            customerNumber: customer.customerNumber,
          }}
          validate={validateForm}
          render={({ handleSubmit, submitting, values }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={3}>
                  <Grid item md={3}>
                    <FormRadioButton
                      name={nameof<AdjustmentDto>("type")}
                      options={adjustmentTypeOptions}
                      value={values.type}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item md={10}>
                    <FormSelect
                      label="Filiale"
                      name={nameof<AdjustmentDto>("externalUnitNumber")}
                      options={operationalUnitOptions}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <FormInput
                      type="number"
                      fullWidth={true}
                      name={nameof<AdjustmentDto>("bonusPoints")}
                      label="Bonuspunkte"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={10}>
                    <FormInput
                      type="textarea"
                      name={nameof<AdjustmentDto>("note")}
                      label="Notiz"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={() => {
                        setAdjustmentDialogVisible(false);
                      }}
                    >
                      Abbrechen
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                    >
                      Buchung erstellen
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkCreateAdjustmentEarn, thunkCreateAdjustmentBurn },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AdjustmentDialog);
