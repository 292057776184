export enum Gender {
  DIVERSE = "DIVERSE",
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export const genderOptions = [
  { value: "", label: "Bitte auswählen" },
  { label: "männlich", value: Gender.MALE },
  { label: "weiblich", value: Gender.FEMALE },
  { label: "divers", value: Gender.DIVERSE },
];

export const getGenderText = (gender?: Gender) =>
  genderOptions.find((option) => option.value === gender)?.label;
