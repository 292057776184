import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { canReadPropertyGroup } from "../../store/actions/LoginActions";
import { thunkFindPropertyGroupValues } from "../../store/actions/PropertyGroupActions";
import { IStore } from "../../store/IStore";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import { RuleRoundingMode } from "../../store/models/bonusRule/RuleRoundingMode";
import { PageViewMode } from "../../store/models/PageViewMode";
import MenuLayout from "../app/MenuLayout";
import BonusRuleForm from "./BonusRuleForm";

const BonusRuleFormPage = (props: ThunkProps) => {
  const { viewMode, id } = useParams<"viewMode" | "id">();
  const bonusRule = props.bonusRules.find(
    bonusRule => bonusRule.ruleNumber === id
  ) || {
    unitNumbers: [],
    ruleNumber: "",
    ruleName: "",
    status: BonusRuleStatus.DISABLED,
    value: "",
    type: BonusRuleType.TRANSACTION,
    valueType: BonusRuleValueType.FACTOR,
    ruleRoundingMode: RuleRoundingMode.MERCANTILE,
    ruleFilters: [],
  };

  if (canReadPropertyGroup()) {
    bonusRule.ruleFilters
      .filter(
        ruleFilter =>
          !ruleFilter.ruleFilterValues ||
          ruleFilter.ruleFilterValues.length === 0
      )
      .forEach(async ruleFilter => {
        await props.thunkFindPropertyGroupValues(ruleFilter.ruleFilterNumber!);
      });
  }

  if (!props.operationalUnits?.length) return null;

  const rootOperationalUnit = props.operationalUnits.find(
    operationalUnit => !operationalUnit.parentUnitNumber
  );

  if (!rootOperationalUnit) {
    return <>Es ist keine Stammfiliale definiert</>;
  }

  return (
    <MenuLayout headline={getHeadline(id, viewMode)}>
      <BonusRuleForm
        bonusRule={bonusRule}
        viewMode={viewMode as PageViewMode}
      />
    </MenuLayout>
  );
};

function getHeadline(id?: string, viewMode?: string): string {
  if (!id) {
    return "Neue Bonuskampagne";
  }
  if (viewMode === PageViewMode.VIEW) return "Bonuskampagne anzeigen";
  return "Bonuskampagne bearbeiten";
}

const mapStateToProps = (state: IStore) => ({
  bonusRules: state.bonusRules.bonusRules,
  operationalUnits: state.operationalUnits.operationalUnits,
  circles: state.circles.circles,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkFindPropertyGroupValues }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusRuleFormPage);
