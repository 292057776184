import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { ChangePasswordDto } from "../models/user/ChangePasswordDto";
import { UserDto } from "../models/user/UserDto";
import { ensureToken, logout } from "./LoginActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const FIND_ALL_USERS = "FIND_ALL_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const thunkFindAllUsers = (): BooleanThunk => async (dispatch) => {
  try {
    const users = await Api.findAllUsers();
    dispatch({
      type: FIND_ALL_USERS,
      payload: users,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Anwender", e));
    return false;
  }
};

export const thunkUpdateUser =
  (userToUpdate: UserDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const user = await Api.updateUser(userToUpdate);
      dispatch({
        type: UPDATE_USER,
        payload: user,
      });
      dispatch(
        thunkCreateSuccessNotification("Der Anwender wurde aktualisiert")
      );
      const loyaltyToken = ensureToken();
      if (loyaltyToken?.customer_number === userToUpdate.userNumber) {
        logout();
      }
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren des Anwenders",
          e
        )
      );
      return false;
    }
  };

export const thunkCreateUser =
  (userToCreate: UserDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createUser(userToCreate);
      dispatch(thunkFindAllUsers() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Anwender wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen des Anwenders", e)
      );
      return false;
    }
  };

export const thunkDeleteUser =
  (userToDelete: UserDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteUser(userToDelete);
      dispatch({
        type: DELETE_USER,
        payload: userToDelete,
      });
      dispatch(thunkCreateSuccessNotification("Der Anwender wurde gelöscht"));
      const loyaltyToken = ensureToken();
      if (loyaltyToken?.customer_number === userToDelete.userNumber) {
        logout();
      }
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen des Anwenders", e)
      );
      return false;
    }
  };

export const thunkChangePassword =
  (changePassword: ChangePasswordDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.changePassword(changePassword);
      dispatch(thunkCreateSuccessNotification("Ihr Passwort wurde geändert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Ändern des Passworts", e)
      );
      return false;
    }
  };
