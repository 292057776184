import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { CustomerCustomFieldDto } from "../models/customer/CustomerCustomFieldDto";
import { CustomerDto } from "../models/customer/CustomerDto";
import { CustomerSearchDto } from "../models/customer/CustomerSearchDto";
import { CustomerStatusDto } from "../models/customer/CustomerStatusDto";
import { ConfigTypes } from "../models/fieldConfig/FieldConfigDto";
import { GET_CUSTOMER_HISTORY, reloadCustomerHistory } from "./HistoryActions";
import {
  GET_CUSTOMER_CUSTOM_FIELDS,
  GET_CUSTOMER_IDENTIFICATIONS,
} from "./IdentificationActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";
import { GET_CUSTOMER_PREFERENCES } from "./PreferenceActions";
import { ConfigGroupKey } from "../models/config/ConfigGroupKey";

export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CUSTOMER_DISPLAY_CONFIG = "CUSTOMER_DISPLAY_CONFIG";
export const CUSTOMER_FIELD_CONFIG = "CUSTOMER_FIELD_CONFIG";
export const RECEIPT_DETAILS = "RECEIPT_DETAILS";
export const GET_CUSTOMER_BONUS_POINTS = "GET_CUSTOMER_BONUS_POINTS";

export const thunkCustomerSearch =
  (customerSearch: CustomerSearchDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const customers = await Api.customerSearch(customerSearch);
      dispatch({
        type: CUSTOMER_SEARCH,
        payload: customers,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler bei der Kundensuche", e));
      return false;
    }
  };

export const thunkClearCustomer = (): BooleanThunk => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUSTOMER,
    });
    dispatch({
      type: GET_CUSTOMER_PREFERENCES,
    });
    dispatch({
      type: GET_CUSTOMER_HISTORY,
    });
    dispatch({
      type: GET_CUSTOMER_BONUS_POINTS,
    });
    dispatch({
      type: GET_CUSTOMER_IDENTIFICATIONS,
    });
    dispatch({
      type: GET_CUSTOMER_CUSTOM_FIELDS,
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const thunkClearCustomerSearch =
  (): BooleanThunk => async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMER_SEARCH,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

export const thunkGetCustomer =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const customer = await Api.findCustomer(customerNumber);
      dispatch({
        type: GET_CUSTOMER,
        payload: customer,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Kunde nicht gefunden", e));
      return false;
    }
  };

export const thunkUpdateCustomer =
  (editedCustomer: CustomerDto): BooleanThunk =>
  async (dispatch) => {
    // remove preselected country
    if (
      editedCustomer.address &&
      editedCustomer.address.country === "DE" &&
      !editedCustomer.address.streetName &&
      !editedCustomer.address.houseNumber &&
      !editedCustomer.address.zipCode &&
      !editedCustomer.address.cityName
    ) {
      delete editedCustomer.address;
    }

    try {
      const customer: CustomerDto = await Api.updateCustomer(editedCustomer);
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: customer,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Kundendaten wurden aktualisiert")
      );
      reloadCustomerHistory(dispatch, customer.customerNumber);
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Die Kundendaten konnten nicht aktualisiert werden",
          e
        )
      );
      return false;
    }
  };

export const thunkUpdateCustomerStatus =
  (customerNumber: string, customerStatus: CustomerStatusDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const customer = await Api.updateCustomerStatus(
        customerNumber,
        customerStatus
      );
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: customer,
      });
      dispatch(
        thunkCreateSuccessNotification("Der Kundenstatus wurden aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Der Kundenstatus konnten nicht aktualisiert werden",
          e
        )
      );
      return false;
    }
  };

  export const thunkGetCustomerDisplayConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const customerDisplayConfig = await Api.getCustomerConfig(
        [ConfigGroupKey.CUSTOMER_CARE_FIELD_DISPLAY_CONFIG]
      );

      dispatch({
        type: CUSTOMER_DISPLAY_CONFIG,
        payload: customerDisplayConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Can not get customer display config",
          e
        )
      );
      return false;
    }
  };

export const thunkGetCustomerFieldConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const customerFieldConfig = await Api.getCustomerFieldConfig(
        ConfigTypes.ADMIN
      );

      dispatch({
        type: CUSTOMER_FIELD_CONFIG,
        payload: customerFieldConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler bei der Ermittlung der Feldliste",
          e
        )
      );
      return false;
    }
  };

export const thunkFindReceiptDetailsForInteraction =
  (interactionNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const receiptDetails = await Api.findReceiptDetailsForInteraction(
        interactionNumber
      );

      dispatch({
        type: RECEIPT_DETAILS,
        payload: receiptDetails,
      });
      return true;
    } catch (e) {
      if (!(e as string).startsWith("No Receipt Details found")) {
        dispatch(
          thunkCreateErrorNotification("Fehler beim Laden des Einkaufsbons", e)
        );
        return false;
      }
      return true;
    }
  };

export const thunkClearReceiptDetails =
  (): BooleanThunk => async (dispatch) => {
    dispatch({
      type: RECEIPT_DETAILS,
      payload: undefined,
    });
    return true;
  };

export const thunkGetCustomerBonusPoints =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const customerBonusPoints = await Api.getBonusPointsInfo(customerNumber);

      dispatch({
        type: GET_CUSTOMER_BONUS_POINTS,
        payload: customerBonusPoints,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Bonuspunkte", e)
      );
      return false;
    }
  };

export const thunkGetCustomerCustomFields =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const customerCustomFields = await Api.getCustomerCustomFields(
        customerNumber
      );
      dispatch({
        type: GET_CUSTOMER_CUSTOM_FIELDS,
        payload: customerCustomFields,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Custom Fields", e)
      );
      return false;
    }
  };

export const thunkCreateCustomerCustomField =
  (
    customerNumber: string,
    customerCustomField: CustomerCustomFieldDto
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createCustomerCustomField(customerNumber, customerCustomField);
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Erstellen der Zusatzfelder",
          e
        )
      );
      return false;
    }
  };

export const thunkUpdateCustomerCustomField =
  (
    customerNumber: string,
    customerCustomField: CustomerCustomFieldDto
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateCustomerCustomField(customerNumber, customerCustomField);
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der Zusatzfelder",
          e
        )
      );
      return false;
    }
  };

export const thunkDeleteCustomerCustomField =
  (
    customerNumber: string,
    customerCustomField: CustomerCustomFieldDto
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteCustomerCustomField(customerNumber, customerCustomField);
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen der Zusatzfelder", e)
      );
      return false;
    }
  };
