import { Gender } from "../customer/Gender";
import { AddressDto } from "../customer/AddressDto";
import { nameof, nameof2 } from "../../../components/atoms/Utils";

export interface RegistrationDto {
  login: LoginDto;
  mailAddress?: string;
  gender?: Gender;
  title?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  masterUnitNumber?: string;
  address?: AddressDto;
  externalCustomerId?: string;
  acceptPrivacyPolicy: boolean;
  acceptTermsOfUse: boolean;
  emailCorrespondenceAllowed?: boolean;
  letterCorrespondenceAllowed?: boolean;
}

export interface LoginDto {
  username?: string;
}
export const REGISTRATION_FIELDS = {
  LOGIN_USERNAME: nameof2<RegistrationDto, LoginDto>("login", "username"),
  MAILADDRESS: nameof<RegistrationDto>("mailAddress"),
  GENDER: nameof<RegistrationDto>("gender"),
  TITLE: nameof<RegistrationDto>("title"),
  FIRSTNAME: nameof<RegistrationDto>("firstName"),
  LASTNAME: nameof<RegistrationDto>("lastName"),
  DATEOFBIRTH: nameof<RegistrationDto>("dateOfBirth"),
  PHONENUMBER: nameof<RegistrationDto>("phoneNumber"),
  MOBILENUMBER: nameof<RegistrationDto>("mobileNumber"),
  MASTERUNITNUMBER: nameof<RegistrationDto>("masterUnitNumber"),
  ADDRESS_STREETNAME: nameof2<RegistrationDto, AddressDto>(
    "address",
    "streetName"
  ),
  ADDRESS_HOUSENUMBER: nameof2<RegistrationDto, AddressDto>(
    "address",
    "houseNumber"
  ),
  ADDRESS_ADDRESSADDITION: nameof2<RegistrationDto, AddressDto>(
    "address",
    "addressAddition"
  ),
  ADDRESS_ZIPCODE: nameof2<RegistrationDto, AddressDto>("address", "zipCode"),
  ADDRESS_CITYNAME: nameof2<RegistrationDto, AddressDto>("address", "cityName"),
  ADDRESS_COUNTRY: nameof2<RegistrationDto, AddressDto>("address", "country"),
  EXTERNALCUSTOMERID: nameof<RegistrationDto>("externalCustomerId"),
  ACCEPTPRIVACYPOLICY: nameof<RegistrationDto>("acceptPrivacyPolicy"),
  ACCEPTTERMSOFUSE: nameof<RegistrationDto>("acceptTermsOfUse"),
  EMAILCORRESPONDENCEALLOWED: nameof<RegistrationDto>("emailCorrespondenceAllowed"),
  LETTERCORRESPONDENCEALLOWED: nameof<RegistrationDto>("letterCorrespondenceAllowed"),
};
