import { AnyAction } from "redux";
import {
  REGISTRATION_DISPLAY_CONFIG,
  GET_BRANCH_STORE_LIST,
  REGISTRATION_FIELD_CONFIG,
  REGISTRATION_SUCCESS,
} from "../actions/RegistrationActions";
import { FieldConfigDto } from "../models/fieldConfig/FieldConfigDto";
import { ConfigDto } from "../models/config/ConfigDto";
import { BranchStoreDto } from "../models/registration/BranchStoreDto";
import { RegistrationCustomerDto } from "../models/registration/RegistrationCustomerDto";
import { RegistrationDto } from "../models/registration/RegistrationDto";

export interface IRegistrationReducerState {
  registration: RegistrationDto;
  customer?: RegistrationCustomerDto;
  registrationDisplayConfig: ConfigDto[];
  registrationFieldConfig: FieldConfigDto[];
  branchStores: BranchStoreDto[];
}

export default class RegistrationReducer {
  public static reducer(
    state: IRegistrationReducerState,
    action: AnyAction
  ): IRegistrationReducerState {
    switch (action.type) {
      case REGISTRATION_SUCCESS:
        return {
          ...state,
          customer: action.payload,
        };
      case GET_BRANCH_STORE_LIST:
        return {
          ...state,
          branchStores: action.payload,
        };
        case REGISTRATION_DISPLAY_CONFIG:
        return {
          ...state,
          registrationDisplayConfig: action.payload,
        };
      case REGISTRATION_FIELD_CONFIG:
        return {
          ...state,
          registrationFieldConfig: action.payload,
        };
      default:
        return (
          state || {
            registration: { login: {} },
            registrationDisplayConfig: [],
            registrationFieldConfig: [],
            branchStores: [],
          }
        );
    }
  }
}
