import {
  Air,
  Dashboard,
  LocalOffer,
  PermContactCalendar,
  QuestionAnswer,
  RedeemOutlined,
  SettingsApplications,
  Sort,
  Stars,
  SupervisorAccount,
  TableChart,
  TocOutlined,
} from "@mui/icons-material";
import {
  Box,
  CSSObject,
  IconButton,
  List,
  styled,
  Theme,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import ListItemLink from "./ListItemLink";
import harborNewYearBackground from "../atoms/images/harbor-new-year-background.png";
import logo from "../atoms/images/KNISTR_Logo_WM_blue_sRGB_C.png";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { thunkToggleMenu } from "../../store/actions/MenuActions";
import { getSetupUrl } from "../setup/Setup";
import {
  UrlPathBonus,
  UrlPathBonusPremium,
  UrlPathBonusXpress,
  UrlPathCampaigns,
  UrlPathCustomerSearch,
  UrlPathFlow,
  UrlPathInteractionSearch,
  UrlPathMain,
  UrlPathOperationalUnit,
  UrlPathPreferences,
  UrlPathReports,
  UrlPathUser,
} from "../../api/url";
import {
  canMaintainCircles,
  canMaintainConfig,
  canMaintainFlows,
  canMaintainGdpr,
  canMaintainIdentificationTypes,
  canMaintainOperationalUnits,
  canReadBonus,
  canReadBonusPremiums,
  canReadCampaigns,
  canReadCustomerInteractions,
  canReadCustomers,
  canReadDashboard,
  canReadPreferences,
  canReadReports,
  canReadUsers,
} from "../../store/actions/LoginActions";
import {
  COLOR_WHITE,
  KNISTR_BLUE,
  MENU_HEADER_HEIGHT,
  MENU_WIDTH,
} from "../atoms/ImsMaterialTheme";
import { IStore } from "../../store/IStore";
import TextDivider from "./TextDivider";

const DrawerMenu = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <>
      <Drawer
        variant="permanent"
        open={props.isMenuOpen}
        PaperProps={{ elevation: 0, sx: classes.drawerPaper }}
      >
        <DrawerHeader sx={{ backgroundColor: COLOR_WHITE }}>
          <Box component="div" sx={classes.headerLogoContainer}>
            <img
              alt="Logo"
              width="auto"
              height={`${MENU_HEADER_HEIGHT}px`}
              src={logo}
            />
          </Box>
          <IconButton
            onClick={props.thunkToggleMenu}
            sx={classes.drawerHeaderCollapsedButton}
          >
            <MenuIcon />
          </IconButton>
        </DrawerHeader>
        <Box sx={classes.drawerBackground} /> {/* This is the menu background */}
        <List sx={classes.listItemLink}>
          <ListItemLink
            visible={canReadDashboard()}
            to={UrlPathMain}
            primaryText="Dashboard"
            icon={<Dashboard fontSize="small" />}
          />
          <ListItemLink
            visible={canReadReports()}
            to={UrlPathReports}
            primaryText="Reports"
            icon={<TableChart fontSize="small" />}
          />

          <TextDivider visible={canReadCampaigns()}>Coupons</TextDivider>
          <ListItemLink
            visible={canReadCampaigns()}
            to={UrlPathCampaigns}
            primaryText="Kampagnen"
            icon={<LocalOffer fontSize="small" />}
          />
          <TextDivider visible={canReadBonus() || canReadBonusPremiums()}>
            Bonusprogramm
          </TextDivider>

          <ListItemLink
            visible={canReadBonus()}
            to={UrlPathBonus}
            primaryText="Bonuskampagnen"
            icon={<Stars fontSize="small" />}
          />

          <ListItemLink
            visible={canReadBonus()}
            to={UrlPathBonusXpress}
            primaryText="Bonus XPress"
            icon={<RedeemOutlined fontSize="small" />}
          />

          <ListItemLink
            visible={canReadBonusPremiums()}
            to={UrlPathBonusPremium}
            primaryText="Bonusprämien"
            icon={<RedeemOutlined fontSize="small" />}
          />

          <TextDivider
            visible={
              canReadCustomers() ||
              canReadPreferences() ||
              canReadCustomerInteractions()
            }
          >
            Mitglieder
          </TextDivider>

          <ListItemLink
            visible={canReadCustomers()}
            to={UrlPathCustomerSearch}
            primaryText="Mitgliederdaten"
            icon={<PermContactCalendar fontSize="small" />}
          />

          <ListItemLink
            visible={canReadPreferences()}
            to={UrlPathPreferences}
            primaryText="Präferenzfragen"
            icon={<QuestionAnswer fontSize="small" />}
          />

          <ListItemLink
            visible={canReadCustomerInteractions()}
            to={UrlPathInteractionSearch}
            primaryText="Interaktionen"
            icon={<TocOutlined fontSize="small" />}
          />

          <TextDivider visible={canMaintainOperationalUnits()}>
            Unternehmen
          </TextDivider>
          <ListItemLink
            visible={canMaintainOperationalUnits()}
            to={UrlPathOperationalUnit}
            primaryText="Filialhierarchie"
            icon={<Sort fontSize="small" />}
          />

          <TextDivider
            visible={
              canMaintainCircles() ||
              canMaintainIdentificationTypes() ||
              canMaintainFlows() ||
              canReadUsers()
            }
          >
            Administration
          </TextDivider>

        <ListItemLink
          visible={
            canMaintainCircles() ||
            canMaintainIdentificationTypes() ||
            canMaintainGdpr() ||
            canMaintainConfig()
          }
          to={getSetupUrl(
            canMaintainCircles(),
            canMaintainIdentificationTypes(),
            canMaintainGdpr(),
            canMaintainConfig()
          )}
          primaryText="Setup"
          icon={<SettingsApplications fontSize="medium" />}
        />

        <ListItemLink
          visible={canMaintainFlows()}
          to={UrlPathFlow}
          primaryText="Flows"
          icon={<Air fontSize="medium" />}
        />

          <ListItemLink
            visible={canReadUsers()}
            to={UrlPathUser}
            primaryText="Anwender"
            icon={<SupervisorAccount fontSize="small" />}
          />
        </List>
      </Drawer>
    </>
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: MENU_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open: isMenuOpen }) => ({
  width: MENU_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(isMenuOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!isMenuOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const styles = (theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(32),
      flexShrink: 0,
    },
  },
  drawerHeaderCollapsedButton: {
    marginRight: "3px",
  },
  drawerPaper: {
    width: theme.spacing(32),
    position: "relative",
    color: COLOR_WHITE,
    boxShadow: theme.spacing(0, 0, 2, 0) + " rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    borderRight: 0,
    backgroundColor: KNISTR_BLUE,
    "&AnchorDockedLeft": {
      border: "none",
    },
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    borderRadius: theme.spacing(1),
    // backgroundColor: COLOR_WHITE,
    flexGrow: 1,
    padding: theme.spacing(2.5),
  },
  navigationList: {
    color: COLOR_WHITE,
  },
  navListButton: {
    backgroundColor: theme.palette.primary.main,
  },
  headline: {
    color: KNISTR_BLUE,
    fontSize: 32,
  },
  navListWrapper: {
    zIndex: 20,
    paddingBottom: theme.spacing(30),
  },
  listItemLink: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  headerLogoContainer: {
    backgroundColor: COLOR_WHITE,
    width: theme.spacing(32),
    height: theme.spacing(11),
    alignContent: "center",
    paddingLeft: theme.spacing(3),
  },
  drawerBackground: {
    position: "absolute",
    top: `${MENU_HEADER_HEIGHT}px`,
    left: 0,
    pointerEvents: "none",
    backgroundImage: `url(${harborNewYearBackground})`,
    width: "100%",
    height: "100%",
    opacity: 0.1,
    backgroundPositionX: "50%",
  },
});

const mapStateToProps = (state: IStore) => ({
  isMenuOpen: state.menu?.open,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkToggleMenu,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(DrawerMenu);
