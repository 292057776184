import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathOperationalUnit } from "../../api/url";
import { canMaintainOperationalUnits } from "../../store/actions/LoginActions";
import {
  thunkDeleteOperationalUnit,
  thunkUpdateOperationalUnitStatus,
} from "../../store/actions/OperationalUnitActions";
import { IStore } from "../../store/IStore";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { OperationalUnitStatus } from "../../store/models/operationalUnit/OperationalUnitStatus";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";

interface OperationalUnitCardMenuProps {
  operationalUnit: OperationalUnitDto;
}

function OperationalUnitCardMenu({
  operationalUnit,
  ...props
}: OperationalUnitCardMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  if (!canMaintainOperationalUnits()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <>
      {confirmVisible && (
        <ImsConfirmDialog
          article="die"
          word="Filiale"
          verb="löschen"
          onConfirm={() => props.thunkDeleteOperationalUnit(operationalUnit)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleClose(e)}>
          <Link
            component={RouterLink}
            to={`${UrlPathOperationalUnit}/${operationalUnit.unitNumber}/edit`}
          >
            Bearbeiten
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            props.thunkUpdateOperationalUnitStatus(
              operationalUnit,
              operationalUnit.unitStatus === OperationalUnitStatus.DISABLED
                ? OperationalUnitStatus.ENABLED
                : OperationalUnitStatus.DISABLED
            );
            handleClose(e);
          }}
        >
          {operationalUnit.unitStatus === OperationalUnitStatus.DISABLED
            ? "Aktivieren"
            : "Deaktivieren"}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          Löschen
        </MenuItem>
      </Menu>
    </>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkUpdateOperationalUnitStatus, thunkDeleteOperationalUnit },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(OperationalUnitCardMenu);
