import { CreditCard } from "@mui/icons-material";
import { Button, Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { Fragment, PropsWithChildren, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomer } from "../../api/url";
import {
  thunkGetCustomerCustomFields,
  thunkGetCustomerFieldConfig,
} from "../../store/actions/CustomerCareActions";
import { canMaintainCustomers } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { ConfigGroupKey } from "../../store/models/config/ConfigGroupKey";
import { AddressDto } from "../../store/models/customer/AddressDto";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import { getGenderText } from "../../store/models/customer/Gender";
import { isFieldNeeded } from "../atoms/FieldValidation";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { formatGermanDate, nameof, nameof2 } from "../atoms/Utils";
import ReplacementCardDialog from "./ReplacementCardDialog";
import {  CustomerStatus} from "../../store/models/customer/CustomerStatusDto";

interface PersonalInformationFieldProps {
  fields: {
    name: string;
    value?: string | number | boolean;
  }[];
}

const PersonalInformation = ({
  customer,
  customerFieldConfig,
  operationalUnits,
  ...props
}: ThunkProps) => {
  const theme = useTheme();
  const [replacementCardDialogVisible, setReplacementCardDialogVisible] =
    useState(false);

  if (!customer) return null;
  if (!customerFieldConfig) {
    props.thunkGetCustomerFieldConfig();
    return null;
  }

  if (!props.customerCustomFields) {
    props.thunkGetCustomerCustomFields(customer.customerNumber);
    return null;
  }

  const customFields = props.configGroups.find(
    (configGroup) => configGroup.key === ConfigGroupKey.CUSTOM_FIELD
  );

  const PersonalInformationField = ({
    fields,
    children,
  }: PropsWithChildren<PersonalInformationFieldProps>) => {
    if (
      !fields.some(
        ({ name, value }) => isFieldNeeded(name, customerFieldConfig) && value
      )
    )
      return null;

    return <>{children}</>;
  };

  return (
    <Grid item xs={4}>
      {replacementCardDialogVisible && (
        <ReplacementCardDialog
          setReplacementCardDialogVisible={setReplacementCardDialogVisible}
        />
      )}
      <Paper
        style={{
          padding: theme.spacing(3),
          wordBreak: "break-word",
          height: "100%",
        }}
      >
        <ImsPaperHead text="Persönliche Informationen" />
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(1),
            }}
          >
            <b>Mitglied</b>
          </Grid>

          <PersonalInformationField
            fields={[
              { name: nameof<CustomerDto>("title"), value: customer.title },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Titel</Typography>
            </Grid>
            <Grid item xs={8}>
              {customer.title}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("firstName"),
                value: customer.firstName,
              },
              {
                name: nameof<CustomerDto>("lastName"),
                value: customer.lastName,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <b>
                {customer.firstName} {customer.lastName}
              </b>
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("gender"),
                value: customer.gender,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Geschlecht</Typography>
            </Grid>
            <Grid item xs={8}>
              {getGenderText(customer.gender)}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("dateOfBirth"),
                value: customer.dateOfBirth,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Geburtstag</Typography>
            </Grid>
            <Grid item xs={8}>
              {formatGermanDate(customer.dateOfBirth)}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("externalCustomerId"),
                value: customer.externalCustomerId,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Kundennummer</Typography>
            </Grid>
            <Grid item xs={8}>
              <div style={{ float: "left" }}>{customer.externalCustomerId}</div>
              {customer.status === CustomerStatus.ACTIVE && (<CreditCard
                style={{
                  marginLeft: theme.spacing(2),
                  fontSize: 24,
                  color: theme.palette.primary.main,
                  cursor: "hand",
                  borderRadius: "50%",
                  padding: 1,
                  float: "left",
                }}
                onClick={() => {
                  setReplacementCardDialogVisible(true);
                }}
              /> )}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("masterUnitNumber"),
                value: customer.masterUnitNumber,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Stammfiliale</Typography>
            </Grid>
            <Grid item xs={8}>
              {
                operationalUnits.find(
                  (operationalUnit) =>
                    operationalUnit.unitNumber === customer.masterUnitNumber
                )?.unitName
              }
            </Grid>
          </PersonalInformationField>

          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(1),
            }}
          >
            <b>Kontaktinformation</b>
          </Grid>

          <PersonalInformationField
            fields={[
              {
                name: nameof2<CustomerDto, AddressDto>("address", "streetName"),
                value: customer.address?.streetName,
              },
              {
                name: nameof2<CustomerDto, AddressDto>(
                  "address",
                  "houseNumber"
                ),
                value: customer.address?.houseNumber,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Adresse</Typography>
            </Grid>
            <Grid item xs={8}>
              {customer.address?.streetName} {customer.address?.houseNumber}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof2<CustomerDto, AddressDto>(
                  "address",
                  "addressAddition"
                ),
                value: customer.address?.addressAddition,
              },
            ]}
          >
            <Grid item xs={4} />
            <Grid item xs={8}>
              {customer.address?.addressAddition}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof2<CustomerDto, AddressDto>("address", "zipCode"),
                value: customer.address?.zipCode,
              },
              {
                name: nameof2<CustomerDto, AddressDto>("address", "cityName"),
                value: customer.address?.cityName,
              },
            ]}
          >
            <Grid item xs={4} />
            <Grid item xs={8}>
              {customer.address?.zipCode} {customer.address?.cityName}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof2<CustomerDto, AddressDto>("address", "country"),
                value: customer.address?.country,
              },
            ]}
          >
            <Grid item xs={4} />
            <Grid item xs={8}>
              {customer.address?.country &&
                countries[customer.address?.country as keyof typeof countries]
                  .name}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("mailAddress"),
                value: customer.mailAddress,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">E-Mail</Typography>
            </Grid>
            <Grid item xs={8}>
              {customer.mailAddress}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("phoneNumber"),
                value: customer.phoneNumber,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Telefon</Typography>
            </Grid>
            <Grid item xs={8}>
              {customer.phoneNumber}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("mobileNumber"),
                value: customer.mobileNumber,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Mobil</Typography>
            </Grid>
            <Grid item xs={8}>
              {customer.mobileNumber}
            </Grid>
          </PersonalInformationField>

          <PersonalInformationField
            fields={[
              {
                name: nameof<CustomerDto>("emailCorrespondenceAllowed"),
                value: customer.emailCorrespondenceAllowed,
              },
              {
                name: nameof<CustomerDto>("letterCorrespondenceAllowed"),
                value: customer.letterCorrespondenceAllowed,
              },
            ]}
          >
            <Grid item xs={4}>
              <Typography variant="subtitle1">Einwilligung</Typography>
            </Grid>
            <Grid item xs={8}>
              {[
                customer.emailCorrespondenceAllowed && "E-Mail",
                customer.letterCorrespondenceAllowed && "Brief",
              ]
                .filter((value) => value)
                .join(", ")}
            </Grid>
          </PersonalInformationField>

          {customer.executionDate && (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Gekündigt zum</Typography>
              </Grid>
              <Grid item xs={8}>
                {formatGermanDate(customer.executionDate)}
              </Grid>
            </>
          )}

          {props.customerCustomFields?.map((customerCustomField) => (
            <Fragment key={customerCustomField.fieldName}>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {customFields?.values.find(
                    (value) => value.key === customerCustomField.fieldName
                  )?.value || customerCustomField.fieldName}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {customerCustomField.fieldValue}
              </Grid>
            </Fragment>
          ))}

          {canMaintainCustomers() && (
            <Grid container justifyContent="flex-end">
              <Link
                component={RouterLink}
                to={`${UrlPathCustomer}/${customer.customerNumber}/edit`}
              >
                <Button color="primary" type="button" variant="contained">
                  Bearbeiten
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  operationalUnits: state.operationalUnits.operationalUnits,
  customerFieldConfig: state.customerCare.customerFieldConfig,
  customerCustomFields: state.customerCare.customerCustomFields,
  configGroups: state.configGroups.configGroups,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkGetCustomerFieldConfig, thunkGetCustomerCustomFields },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PersonalInformation);
