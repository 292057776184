import { Grid, Paper, useTheme } from "@mui/material";
import { genderOptions } from "../../store/models/customer/Gender";
import { RegistrationDto } from "../../store/models/registration/RegistrationDto";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof } from "../atoms/Utils";
import { getLabelText, RegistrationFormThunkProps } from "./RegistrationForm";

const PersonalData = (props: RegistrationFormThunkProps) => {
  const theme = useTheme();
  const { branchStores, registrationFieldConfig } = props;
  return (
    <Paper
      style={{
        padding: theme.spacing(3),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      }}
    >
      <ImsPaperHead text="Persönliche Informationen" />
      <Grid container spacing={4}>
        <Grid item md={4}>
          <FormInput
            type="text"
            name={nameof<RegistrationDto>("title")}
            label={getLabelText(
              "Titel",
              nameof<RegistrationDto>("title"),
              registrationFieldConfig
            )}
            fullWidth={true}
            variant="outlined"
            fieldConfig={registrationFieldConfig}
          />
        </Grid>

        <Grid
          container
          spacing={4}
          style={{
            margin: theme.spacing(0),
          }}
        >
          <Grid item md={4}>
            <FormInput
              type="text"
              fullWidth={true}
              name={nameof<RegistrationDto>("firstName")}
              label={getLabelText(
                "Vorname",
                nameof<RegistrationDto>("firstName"),
                registrationFieldConfig
              )}
              variant="outlined"
              fieldConfig={registrationFieldConfig}
            />
          </Grid>
          <Grid item md={4}>
            <FormInput
              type="text"
              fullWidth={true}
              name={nameof<RegistrationDto>("lastName")}
              label={getLabelText(
                "Nachname",
                nameof<RegistrationDto>("lastName"),
                registrationFieldConfig
              )}
              variant="outlined"
              fieldConfig={registrationFieldConfig}
            />
          </Grid>
        </Grid>

        <Grid item md={4}>
          <FormSelect
            name={nameof<RegistrationDto>("gender")}
            label={getLabelText(
              "Geschlecht",
              nameof<RegistrationDto>("gender"),
              registrationFieldConfig
            )}
            options={genderOptions}
            fullWidth={true}
            fieldConfig={registrationFieldConfig}
          />
        </Grid>
        <Grid item md={4}>
          <FormDate
            name={nameof<RegistrationDto>("dateOfBirth")}
            label={getLabelText(
              "Geburtstag",
              nameof<RegistrationDto>("dateOfBirth"),
              registrationFieldConfig
            )}
            fullWidth={true}
            fieldConfig={registrationFieldConfig}
          />
        </Grid>

        <Grid
          container
          spacing={4}
          style={{
            margin: theme.spacing(0),
          }}
        >
          <Grid item md={4}>
            <FormSelect
              name={nameof<RegistrationDto>("masterUnitNumber")}
              label={getLabelText(
                "Stammfiliale",
                nameof<RegistrationDto>("masterUnitNumber"),
                registrationFieldConfig
              )}
              options={[{ label: "bitte wählen...", value: "" }].concat(
                branchStores.map((branchStore) => ({
                  label: branchStore.unitName,
                  value: branchStore.unitNumber,
                }))
              )}
              fullWidth={true}
              fieldConfig={registrationFieldConfig}
            />
          </Grid>
          <Grid item md={4}>
            <FormInput
              type="text"
              fullWidth={true}
              name={nameof<RegistrationDto>("externalCustomerId")}
              label={getLabelText(
                "Kartennummer",
                nameof<RegistrationDto>("externalCustomerId"),
                registrationFieldConfig
              )}
              variant="outlined"
              fieldConfig={registrationFieldConfig}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonalData;
