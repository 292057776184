import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { ConfigTypes } from "../models/fieldConfig/FieldConfigDto";
import { RegistrationDto } from "../models/registration/RegistrationDto";
import { ConfigGroupKey } from "../models/config/ConfigGroupKey";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const GET_BRANCH_STORE_LIST = "GET_BRANCH_STORE_LIST";
export const REGISTRATION_FIELD_CONFIG = "REGISTRATION_FIELD_CONFIG";
export const REGISTRATION_DISPLAY_CONFIG = "REGISTRATION_DISPLAY_CONFIG";

export const thunkGetRegistrationDisplayConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const registrationDisplayConfig = await Api.getRegistrationConfig(
        [ConfigGroupKey.REGISTRATION_FIELD_DISPLAY_CONFIG]
      );

      dispatch({
        type: REGISTRATION_DISPLAY_CONFIG,
        payload: registrationDisplayConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Can not get registration display config",
          e
        )
      );
      return false;
    }
  };

export const thunkGetRegistrationFieldConfig =
  (): BooleanThunk => async (dispatch) => {
    try {
      const registrationFieldConfig = await Api.getRegistrationFieldConfig(
        ConfigTypes.ADMIN
      );

      dispatch({
        type: REGISTRATION_FIELD_CONFIG,
        payload: registrationFieldConfig,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler bei der Ermittlung der Feldliste",
          e
        )
      );
      return false;
    }
  };

export const thunkRegistration =
  (registration: RegistrationDto): BooleanThunk =>
  async (dispatch) => {
    try {
      registration.mailAddress = registration.login.username;

      // remove preselected country
      if (
        registration.address &&
        registration.address.country === "DE" &&
        !registration.address.streetName &&
        !registration.address.houseNumber &&
        !registration.address.zipCode &&
        !registration.address.cityName
      ) {
        delete registration.address;
      }

      const registrationResult = await Api.register(registration);
      dispatch({
        type: REGISTRATION_SUCCESS,
        payload: registrationResult,
      });
      dispatch(
        thunkCreateSuccessNotification(
          `Benutzer registriert: ${registrationResult.customerNumber}`
        )
      );
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler bei der Registrierung", e));
      return false;
    }
  };

export const thunkGetBranchStoreList = (): BooleanThunk => async (dispatch) => {
  try {
    const branchStores = await Api.getBranchStoreList();
    dispatch({
      type: GET_BRANCH_STORE_LIST,
      payload: branchStores,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification("Fehler beim Laden der Kundenfilialen", e)
    );
    return false;
  }
};
