import { AnyAction } from "redux";
import {
  DISABLE_CAMPAIGN,
  ENABLE_CAMPAIGN,
  FIND_ALL_CAMPAIGNS,
  GET_CUSTOMER_COUNT_FOR_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from "../actions/CampaignActions";
import { CampaignDto } from "../models/campaign/CampaignDto";
import { CampaignStatus } from "../models/campaign/CampaignStatus";

export interface ICampaignReducerState {
  campaigns: CampaignDto[];
  customerCountForCampaign?: number;
}

export default class CampaignReducer {
  public static reducer(
    state: ICampaignReducerState,
    action: AnyAction
  ): ICampaignReducerState {
    switch (action.type) {
      case FIND_ALL_CAMPAIGNS:
        return { ...state, campaigns: action.payload };
      case UPDATE_CAMPAIGN:
        const updatedCampaign = action.payload as CampaignDto;
        return {
          ...state,
          campaigns: state.campaigns.map((c) =>
            c.campaignNumber === updatedCampaign.campaignNumber
              ? updatedCampaign
              : c
          ),
        };
      case GET_CUSTOMER_COUNT_FOR_CAMPAIGN:
        return {
          ...state,
          customerCountForCampaign: action.payload.customerCount,
        };
      case ENABLE_CAMPAIGN:
        return {
          ...state,
          campaigns: [
            ...state.campaigns.map((campaign) => {
              if (campaign.campaignNumber !== action.payload.campaignNumber) {
                return campaign;
              } else {
                return { ...campaign, status: CampaignStatus.ENABLED };
              }
            }),
          ],
        };
      case DISABLE_CAMPAIGN:
        return {
          ...state,
          campaigns: [
            ...state.campaigns.map((campaign) => {
              if (campaign.campaignNumber !== action.payload.campaignNumber) {
                return campaign;
              } else {
                return { ...campaign, status: CampaignStatus.DISABLED };
              }
            }),
          ],
        };
      default:
        return state || { campaigns: [] };
    }
  }
}
