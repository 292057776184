import { AnyAction } from "redux";
import {
  CREATE_NOTIFICATION,
  DISMISS_NOTIFICATION,
  ENTER_DIALOG,
  LEAVE_DIALOG,
} from "../actions/NotificationActions";
import { INotification } from "../models/INotification";

export interface INotificationsReducerState {
  notifications?: INotification[];
  isDialog?: boolean;
}

export default class NotificationsReducer {
  public static reducer(
    state: INotificationsReducerState,
    action: AnyAction
  ): INotificationsReducerState {
    switch (action.type) {
      case CREATE_NOTIFICATION:
        return {
          ...state,
          notifications: [...(state.notifications || []), action.payload],
        };
      case DISMISS_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications?.filter(
            (err) => err.id !== action.payload.id
          ),
        };
      case ENTER_DIALOG:
        return {
          ...state,
          isDialog: true,
        };
      case LEAVE_DIALOG:
        return {
          ...state,
          isDialog: false,
        };
      default:
        return state || {};
    }
  }
}
