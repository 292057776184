import { Brightness1 } from "@mui/icons-material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import { KNISTR_GREEN } from "../../atoms/ImsMaterialTheme";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import IdentificationTypeTableMenu from "./IdentificationTypeTableMenu";

const IdentificationTypeTable = (props: ThunkProps) => {
  const { identificationTypes } = props;
  const headCells: ImsHeadCell<IdentificationTypeDto>[] = [
    { val: (val) => val.identificationTypeNumber, node: "ID" },
    { val: (val) => val.identificationTypeName, node: "Bezeichnung" },
    {
      val: (val) => val.defaultIdentification,
      node: "Standard für Touchpoint-Schnittstellen",
    },
  ];

  const rows: ImsTableRow<IdentificationTypeDto>[] = identificationTypes?.map(
    (row: IdentificationTypeDto) => ({
      value: row,
      nodes: [
        row.externalIdentificationTypeNumber,
        row.identificationTypeName,
        row.defaultIdentification && (
          <Brightness1
            style={{
              fill: KNISTR_GREEN,
              paddingTop: 4,
            }}
          />
        ),

        <IdentificationTypeTableMenu identificationType={row} />,
      ],
    })
  );
  return rows.length ? (
    <ImsTable
      hasMenu={true}
      headCells={headCells}
      rows={rows}
      sortColumnIndex={1}
      sortDirection="desc"
    />
  ) : (
    <>Es sind keine Daten vorhanden</>
  );
};

const mapStateToProps = (state: IStore) => ({
  identificationTypes: state.identifications?.identificationTypes,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeTable);
