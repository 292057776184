import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import {
  BonusPremiumDto,
  BonusPremiumStatus,
} from "../models/bonusPremium/BonusPremiumDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_BONUS_PREMIUMS = "GET_BONUS_PREMIUMS";
export const DELETE_BONUS_PREMIUM = "DELETE_BONUS_PREMIUM";
export const UPDATE_BONUS_PREMIUM = "UPDATE_BONUS_PREMIUM";
export const ACTIVATE_BONUS_PREMIUM = "ACTIVATE_BONUS_PREMIUM";
export const DEACTIVATE_BONUS_PREMIUM = "DEACTIVATE_BONUS_PREMIUM";

export const thunkGetBonusPremiums = (): BooleanThunk => async (dispatch) => {
  try {
    const BonusPremiums = await Api.findAllBonusPremiums();
    dispatch({
      type: GET_BONUS_PREMIUMS,
      payload: BonusPremiums,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification("Fehler beim Laden der Bonusprämie", e)
    );
    return false;
  }
};

export const thunkDeleteBonusPremium =
  (bonusPremium: BonusPremiumDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteBonusPremium(bonusPremium);
      dispatch({
        type: DELETE_BONUS_PREMIUM,
        payload: bonusPremium,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Bonusprämie wurde gelöscht")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen der Bonusprämie", e)
      );
      return false;
    }
  };

export const thunkCreateBonusPremium =
  (newBonusPremium: BonusPremiumDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createBonusPremium(newBonusPremium);
      dispatch(thunkGetBonusPremiums() as BooleanThunkAction);
      dispatch(
        thunkCreateSuccessNotification("Die Bonusprämie wurde angelegt")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen der Bonusprämie", e)
      );
      return false;
    }
  };

export const thunkUpdateBonusPremium =
  (bonusPremium: BonusPremiumDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedBonusPremium = await Api.updateBonusPremium(bonusPremium);
      dispatch({
        type: UPDATE_BONUS_PREMIUM,
        payload: updatedBonusPremium,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Bonusprämie wurde aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der Bonusprämie",
          e
        )
      );
      return false;
    }
  };

export const thunkActivateBonusPremium =
  (bonusPremium: BonusPremiumDto): BooleanThunk =>
  async (dispatch) => {
    if (!bonusPremium.premiumNumber) return false;
    try {
      await Api.updateBonusPremiumStatus(
        bonusPremium.premiumNumber,
        BonusPremiumStatus.ENABLED
      );
      dispatch({
        type: ACTIVATE_BONUS_PREMIUM,
        payload: bonusPremium,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Bonusprämie wurde aktiviert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktivieren der Bonusprämie",
          e
        )
      );
      return false;
    }
  };

export const thunkDeactivateBonusPremium =
  (bonusPremium: BonusPremiumDto): BooleanThunk =>
  async (dispatch) => {
    if (!bonusPremium.premiumNumber) return false;
    try {
      await Api.updateBonusPremiumStatus(
        bonusPremium.premiumNumber,
        BonusPremiumStatus.DISABLED
      );
      dispatch({
        type: DEACTIVATE_BONUS_PREMIUM,
        payload: bonusPremium,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Bonusprämie wurde deaktiviert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Deaktivieren der Bonusprämie",
          e
        )
      );
      return false;
    }
  };
