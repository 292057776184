import { AnyAction } from "redux";
import {
  CREATE_OPERATIONAL_UNIT,
  DELETE_OPERATIONAL_UNIT,
  GET_DEFAULT_OPERATIONAL_UNIT_TYPES,
  GET_OPERATIONAL_UNITS,
  UPDATE_OPERATIONAL_UNIT,
} from "../actions/OperationalUnitActions";
import { OperationalUnitDto } from "../models/operationalUnit/OperationalUnitDto";
import { OperationalUnitType } from "../models/operationalUnit/OperationalUnitType";

export interface IOperationalUnitReducerState {
  operationalUnits: OperationalUnitDto[];
  operationalUnitTypes: OperationalUnitType[];
}

export default class OperationalUnitReducer {
  public static reducer(
    state: IOperationalUnitReducerState,
    action: AnyAction
  ): IOperationalUnitReducerState {
    switch (action.type) {
      case GET_OPERATIONAL_UNITS:
        return {
          ...state,
          operationalUnits: action.payload,
        };
      case GET_DEFAULT_OPERATIONAL_UNIT_TYPES:
        return {
          ...state,
          operationalUnitTypes: action.payload,
        };
      case CREATE_OPERATIONAL_UNIT:
        return {
          ...state,
          operationalUnits: [...state.operationalUnits, action.payload],
        };
      case UPDATE_OPERATIONAL_UNIT:
        const updatedOperationalUnit = action.payload as OperationalUnitDto;
        return {
          ...state,
          operationalUnits: state.operationalUnits.map((operationalUnit) =>
            operationalUnit.unitNumber === updatedOperationalUnit.unitNumber
              ? updatedOperationalUnit
              : operationalUnit
          ),
        };
      case DELETE_OPERATIONAL_UNIT:
        const deletedOperationalUnit = action.payload as OperationalUnitDto;
        return {
          ...state,
          operationalUnits: state.operationalUnits.filter(
            (operationalUnit) =>
              operationalUnit.unitNumber !== deletedOperationalUnit.unitNumber
          ),
        };
      default:
        return state || { operationalUnitTypes: [], operationalUnits: [] };
    }
  }
}
