import { AnyAction } from "redux";
import {
  DELETE_USER,
  FIND_ALL_USERS,
  UPDATE_USER,
} from "../actions/UserActions";
import { UserDto } from "../models/user/UserDto";

export interface IUserReducerState {
  users: UserDto[];
}

export default class UserReducer {
  public static reducer(
    state: IUserReducerState,
    action: AnyAction
  ): IUserReducerState {
    switch (action.type) {
      case FIND_ALL_USERS:
        return {
          ...state,
          users: action.payload,
        };
      case UPDATE_USER:
        const updatedUser = action.payload as UserDto;
        return {
          ...state,
          users: state.users.map((user) =>
            user.userNumber === updatedUser.userNumber ? updatedUser : user
          ),
        };
      case DELETE_USER:
        const deletedUser = action.payload as UserDto;
        return {
          ...state,
          users: state.users.filter(
            (user) => user.userNumber !== deletedUser.userNumber
          ),
        };
      default:
        return state || { users: [] };
    }
  }
}
