import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Field } from "react-final-form";
import { FormOption } from "./FormOption";
import { COLOR_BLACK, KNISTR_RED } from "./ImsMaterialTheme";

interface FormRadioButtonProps {
  value?: any;
  name: string;
  label?: string;
  options: FormOption[];
  readOnly?: boolean;
  row?: boolean;
}

export const FormRadioButton = (props: FormRadioButtonProps) => (
  <Field name={props.name} type="radio">
    {({ input, meta }) => {
      const hasErrors = meta.error && meta.touched;
      return (
        <FormControl component="fieldset" error={hasErrors}>
          {props.label && (
            <FormLabel
              component="legend"
              style={{
                fontWeight: "bold",
                paddingBottom: 16,
                color: hasErrors ? KNISTR_RED : COLOR_BLACK,
              }}
            >
              {props.label}
            </FormLabel>
          )}
          <RadioGroup
            aria-label={props.label}
            {...input}
            value={props.value}
            row={props.row}
          >
            {props.options.map(({ label, value, disabled }) => (
              <FormControlLabel
                disabled={props.readOnly || disabled}
                key={value}
                value={value}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
          {hasErrors && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      );
    }}
  </Field>
);
