import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Button, Grid, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathOperationalUnitCreate } from "../../api/url";
import { canMaintainOperationalUnits } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import MenuLayout from "../app/MenuLayout";
import { OperationalUnitCard } from "./OperationalUnitCard";

const OperationalUnitOverview = (props: ThunkProps) => {
  const theme = useTheme();
  const { operationalUnits } = props;
  const rootUnit =
    operationalUnits && operationalUnits.find((unit) => !unit.parentUnitNumber);
  return (
    <MenuLayout headline="Filialhierarchie">
      <Grid container>
        {canMaintainOperationalUnits() && (
          <Grid container direction="column" alignItems="flex-end">
            <Button
              component={Link}
              to={UrlPathOperationalUnitCreate}
              startIcon={<Add />}
              variant="contained"
              color="primary"
              style={{ marginBottom: theme.spacing(4) }}
            >
              Neue Filiale/-Gruppe
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <Grid
            container
            style={{
              marginBottom: theme.spacing(2),
              paddingLeft: theme.spacing(6),
            }}
          >
            <Grid item xs={3}>
              <b>Filiale/-gruppe</b>
            </Grid>
            <Grid item xs={2}>
              <b>ID</b>
            </Grid>
            <Grid item xs={2}>
              <b>Adresse</b>
            </Grid>
            <Grid item xs={5}>
              <b>Kontakt</b>
            </Grid>
          </Grid>
          {rootUnit && (
            <TreeView
              defaultExpanded={[rootUnit.externalUnitNumber]}
              defaultCollapseIcon={<KeyboardArrowDown />}
              defaultExpandIcon={<KeyboardArrowRight />}
              disableSelection={true}
            >
              <OperationalUnitCard
                operationalUnit={rootUnit}
                operationalUnits={operationalUnits}
              />
            </TreeView>
          )}
        </Grid>
      </Grid>
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(OperationalUnitOverview);
