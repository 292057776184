import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { FamilyMembershipRoleDto } from "../models/family/FamilyMembershipDto";
import { InvitationDto } from "../models/InvitationDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_FAMILY_BY_CUSTOMER_NUMBER = "GET_FAMILY_BY_CUSTOMER_NUMBER";
export const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER";
export const UPDATE_FAMILY_MEMBERSHIP = "UPDATE_FAMILY_MEMBERSHIP";
export const JOIN_FAMILY = "JOIN_FAMILY";

export const thunkGetFamilyByCustomerNumber =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const family = await Api.getFamilyByCustomerNumber(customerNumber);
      dispatch({
        type: GET_FAMILY_BY_CUSTOMER_NUMBER,
        payload: family,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Familie", e)
      );
      return false;
    }
  };

export const thunkDeleteFamilyMembership =
  (familyNumber: string, customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteFamilyMembership(familyNumber, customerNumber);
      dispatch({
        type: DELETE_FAMILY_MEMBER,
        payload: customerNumber,
      });
      dispatch(
        thunkCreateSuccessNotification(
          "Die Familienmitgliedschaft wurde gelöscht"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Löschen der Familienmitgliedschaft",
          e
        )
      );
      return false;
    }
  };

export const thunkUpdateFamilyMembership =
  (
    familyNumber: string,
    customerNumber: string,
    familyMembershipRole: FamilyMembershipRoleDto
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateFamilyMembership(
        familyNumber,
        customerNumber,
        familyMembershipRole
      );
      dispatch({
        type: UPDATE_FAMILY_MEMBERSHIP,
        payload: familyMembershipRole,
      });
      dispatch(
        thunkCreateSuccessNotification(
          "Die Familienberechtigungen wurden geändert"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Ändern der Familienberechtigung",
          e
        )
      );
      return false;
    }
  };

export const thunkTransferFamilyMember =
  (familyNumber: string, customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.transferFamilyMember(familyNumber, customerNumber);
      dispatch({
        type: JOIN_FAMILY,
        payload: customerNumber,
      });
      dispatch(
        thunkCreateSuccessNotification(
          "Das Mitglied wurde erfolgreich der Familie hinzugefügt"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Ändern der Familienberechtigung",
          e
        )
      );
      return false;
    }
  };

export const thunkInviteFamilyMember =
  (invitation: InvitationDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.inviteFamilyMember(invitation);
      dispatch(
        thunkCreateSuccessNotification(
          `Familienmitglied eingeladen: ${invitation.mailAddress}`
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Einladen eines Familienmitglieds",
          e
        )
      );
      return false;
    }
  };
