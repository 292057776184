import { Help } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ValidationErrors } from "final-form";
import { debounce, isEqual } from "lodash";
import memoizeOne from "memoize-one";
import { useState } from "react";
import { AnyObject, Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCampaigns } from "../../api/url";
import {
  thunkCreateCampaign,
  thunkGetCustomerCountForCampaign,
  thunkUpdateCampaign,
} from "../../store/actions/CampaignActions";
import { IStore } from "../../store/IStore";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { CampaignDto } from "../../store/models/campaign/CampaignDto";
import { CampaignFormModel } from "../../store/models/campaign/CampaignFormModel";
import { CampaignType } from "../../store/models/campaign/CampaignType";
import { Gender } from "../../store/models/customer/Gender";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import {
  getCurrentDateAsString,
  getToday,
  getTomorrow,
  isBlank,
  isPositiveInteger,
} from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";

interface CampaignFormProps {
  campaign: CampaignDto;
}

const campaignTypeOptions = [
  {
    label: "An einem bestimmten Tag",
    value: CampaignType.FIXED_DAY,
  },
  {
    label: "Geburtstagskampagne",
    value: CampaignType.DATE_OF_BIRTH_TIME_PERIOD,
  },
];

const genderOptions = [
  {
    label: "Egal",
    value: "ANY",
  },
  {
    label: "Männlich",
    value: Gender.MALE,
  },
  {
    label: "Weiblich",
    value: Gender.FEMALE,
  },
  {
    label: "Divers",
    value: Gender.DIVERSE,
  },
];

const advantageTypeOptions = [
  { value: CouponAdvantageType.EURO, label: "Geldbetrag" },
  { value: CouponAdvantageType.PERCENT, label: "Prozent" },
  { value: CouponAdvantageType.OFFER, label: "Präsent" },
];

const couponValidityTypeOptions = [
  {
    label: "Ablauf an einem bestimmten Datum",
    value: "invalidAt",
  },
  {
    label: "Ablauf nach einem bestimmten Zeitraum",
    value: "invalidPeriod",
  },
];

const CampaignForm = (props: CampaignFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);

  const saveCampaign = async (campaignForm: CampaignFormModel) => {
    const campaign = mapFormModelToCampaign(campaignForm);
    const success = campaign.campaignNumber
      ? await props.thunkUpdateCampaign(campaign)
      : await props.thunkCreateCampaign(campaign);
    if (success) {
      setRedirect(true);
    }
  };

  const searchDebounced = debounce(
    memoizeOne(props.thunkGetCustomerCountForCampaign, (newArgs, lastArgs) =>
      isEqual(newArgs[0], lastArgs[0])
    ),
    300
  );

  const isInvalidAfterPeriod = (values: CampaignFormModel) => {
    if (values.couponValidityType === "invalidPeriod") {
      delete values.couponDetails.expiryDate;
      return true;
    } else {
      delete values.couponDetails.expiryMonths;
      return false;
    }
  };

  if (redirect) {
    return <Navigate to={UrlPathCampaigns} />;
  }
  const { campaign, operationalUnits, customerCountForCampaign } = props;

  if (!operationalUnits.length) return null;

  const operationalUnitOptions = getOperationalUnitOptions(
    undefined,
    operationalUnits,
    theme,
    true,
    false
  );

  return (
    <>
      <Form
        onSubmit={saveCampaign}
        initialValues={mapCampaignToFormModel(campaign)}
        keepDirtyOnReinitialize={true}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          const isBirthday =
            values.campaignType === CampaignType.DATE_OF_BIRTH_TIME_PERIOD;
          const showDateRange =
            isBirthday || values.campaignType === CampaignType.TIME_PERIOD;
          searchDebounced(values.selectionCriteria);

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="text"
                      name="campaignName"
                      label="Name der Kampagne"
                      variant="standard"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="textarea"
                      fullWidth={true}
                      name="campaignDescription"
                      label="Beschreibung (optional)"
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text="Wann sollen die Coupons ausgespielt werden?" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        name="campaignType"
                        label="Art der Kampagne"
                        value={values.campaignType}
                        options={campaignTypeOptions}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12}>
                      <b>
                        {showDateRange ? "Zeitraum wählen" : "Datum wählen"}
                      </b>
                    </Grid>
                    <Grid item md={4}>
                      <FormDate
                        name="startDate"
                        label="Datum"
                        prefix={showDateRange ? "Von" : "Am"}
                        minDate={getToday()}
                        testId="dt-sd"
                      />
                    </Grid>
                    {showDateRange && (
                      <Grid item md={4}>
                        <FormDate
                          name="endDate"
                          label="Datum"
                          prefix="Bis"
                          testId="dt-ed"
                          helperText="Optional"
                          minDate={getToday()}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text="Wer soll die Coupons erhalten?" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={4}>
                      <b>Kunden der folgenden Filialen</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label="Filiale/-gruppe"
                        name="selectionCriteria.unitNumber"
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid item md={8}>
                      <b>Kunden mit folgenden demografischen Merkmalen</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label="Geschlecht"
                        name="selectionCriteria.gender"
                        options={genderOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={2}>
                      <FormInput
                        type="number"
                        label="Alter von"
                        name="selectionCriteria.minimumAge"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <FormInput
                        type="number"
                        label="Alter bis"
                        name="selectionCriteria.maximumAge"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12}>
                      <b>
                        Kunden mit den folgenden Aktivitäten in den letzten 12
                        Monaten
                      </b>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumActivityCount"
                        label="Anzahl Einkäufe"
                        variant="outlined"
                        prefix="Mind."
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={
                          "nur Kunden, die mindestens x mal eingekauft haben"
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumBuyingCount"
                        label="Umsatz"
                        variant="outlined"
                        prefix="Mind."
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={
                          "nur Kunden, die Waren im Wert von mindestens x EUR eingekauft haben"
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumBonusPointsEarnedInTotal"
                        label="Bonuspunkte"
                        variant="outlined"
                        prefix="Mind."
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={
                          "nur Kunden, die mindestens x Bonuspunkte haben"
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      style={{
                        backgroundColor: COLOR_GRAY_1,
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="bottom"
                        style={{ marginBottom: theme.spacing(1) }}
                      >
                        <span style={{ paddingRight: theme.spacing(2) }}>
                          Die aktuellen Einstellungen entsprechen heute einer
                          Zielgruppe von:
                        </span>
                        <Tooltip
                          title={
                            "Hallo ich bin auch ein Tooltip, leider ohne definierten Text"
                          }
                          placement="right-start"
                        >
                          <Help color="primary" />
                        </Tooltip>
                      </Box>
                      <Typography variant="h2">
                        {customerCountForCampaign}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text="Was sind die Coupons-Details?" />

                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>Name der Prämie</b>
                  </Grid>
                  <Grid item md={5} style={{ marginBottom: theme.spacing(2) }}>
                    <FormInput
                      type="text"
                      name="couponDetails.advantageInfo"
                      label="Anzeigename"
                      fullWidth={true}
                      variant="outlined"
                      tooltip="Diesen Coupontext sieht der Kunde"
                    />
                  </Grid>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        value={values.couponDetails.advantageType}
                        name="couponDetails.advantageType"
                        label="Art der Prämie"
                        options={advantageTypeOptions}
                      />
                    </Grid>
                  </Grid>

                  {values.couponDetails.advantageType ===
                    CouponAdvantageType.EURO && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>Geldbetrag in Euro</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name="couponDetails.advantageValue"
                          label="Euro"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {values.couponDetails.advantageType ===
                    CouponAdvantageType.PERCENT && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>Rabatt in Prozent</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name="couponDetails.advantageValue"
                          label="% Rabatt"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item>
                    <Grid item md={6}>
                      <FormRadioButton
                        name="couponValidityType"
                        value={values.couponValidityType}
                        label="Gültigkeit der Prämie"
                        options={couponValidityTypeOptions}
                      />
                    </Grid>
                  </Grid>
                  {isInvalidAfterPeriod(values) ? (
                    <Grid container item spacing={2}>
                      <Grid item md={12}>
                        <b>Ablaufzeitraum wählen</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name="couponDetails.expiryMonths"
                          label="Monate"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item>
                      <Grid item md={12}>
                        <b>Gültigkeitsdatum wählen</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name="couponDetails.expiryDate"
                          label="Datum"
                          prefix="Bis"
                          minDate={getTomorrow()}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>
                        Filialen, in denen die Coupons eingelöst werden können
                      </b>
                    </Grid>
                    <Grid item md={4}>
                      <FormSelect
                        label="Filiale/-gruppe"
                        name="couponDetails.unitNumber"
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>Coupon ID</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="text"
                        name="couponDetails.generatorPrefix"
                        label="Präfix"
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container item justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  data-testid="button-save"
                  variant="contained"
                  disabled={submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {values.campaignNumber
                    ? "Kampagne speichern"
                    : "Kampagne anlegen"}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

const validateForm = (values: CampaignFormModel) => {
  const errors: ValidationErrors = {
    couponDetails: {},
    selectionCriteria: {},
  };
  if (isBlank(values.campaignName)) {
    errors.campaignName = "Bitte geben Sie einen Namen für die Kampagne an.";
  }
  if (!values.selectionCriteria.unitNumber) {
    errors.selectionCriteria.unitNumber = "Pflichtfeld.";
  }
  if (!values.campaignType) {
    errors.campaignType = "Pflichtfeld.";
  }
  if (!values.startDate) {
    errors.startDate = "Pflichtfeld.";
  } else if (values.startDate < getCurrentDateAsString()) {
    errors.startDate = "Darf nicht in der Vergangenheit liegen.";
  }
  if (values.startDate && values.endDate && values.endDate < values.startDate) {
    errors.endDate = "Enddatum darf nicht vor dem Startdatum liegen.";
  }
  return validateCouponDetails(values, errors);
};

function validateCouponDetails(values: CampaignFormModel, errors: AnyObject) {
  if (!values.couponDetails.unitNumber) {
    errors.couponDetails.unitNumber = "Pflichtfeld.";
  }
  if (isBlank(values.couponDetails.advantageInfo)) {
    errors.couponDetails.advantageInfo =
      "Bitte geben Sie den Anzeigenamen ein.";
  }

  if (
    values.couponDetails.expiryDate &&
    values.startDate &&
    values.couponDetails.expiryDate < values.startDate &&
    !values.endDate
  ) {
    errors.couponDetails.expiryDate =
      "Ablaufdatum muss nach dem Startdatum liegen.";
  }

  if (
    values.couponDetails.expiryDate &&
    values.startDate &&
    values.endDate &&
    values.couponDetails.expiryDate < values.endDate
  ) {
    errors.couponDetails.expiryDate =
      "Ablaufdatum darf nicht vor Kampagnenende liegen.";
  }

  if (
    values.couponDetails.advantageType !== CouponAdvantageType.OFFER &&
    !values.couponDetails.advantageValue
  ) {
    errors.couponDetails.advantageValue =
      "Bitte geben Sie einen Rabattbetrag ein.";
  }
  if (
    values.couponValidityType === "invalidAt" &&
    !values.couponDetails.expiryDate
  ) {
    errors.couponDetails.expiryDate = "Pflichtfeld";
  }
  if (
    values.couponValidityType === "invalidPeriod" &&
    !values.couponDetails.expiryMonths
  ) {
    errors.couponDetails.expiryMonths = "Pflichtfeld";
  }

  if (isBlank(values.couponDetails.generatorPrefix)) {
    errors.couponDetails.generatorPrefix = "Pflichtfeld";
  } else if (values.couponDetails.generatorPrefix.length > 6) {
    errors.couponDetails.generatorPrefix = "max. 6 Zeichen";
  }
  return errors;
}

const mapCampaignToFormModel = (campaign: CampaignDto): CampaignFormModel => {
  return {
    ...campaign,
    couponValidityType: isPositiveInteger(campaign.couponDetails.expiryMonths)
      ? "invalidPeriod"
      : "invalidAt",
  };
};

const mapFormModelToCampaign = (
  campaignForm: CampaignFormModel
): CampaignDto => {
  const campaign = {
    ...campaignForm,
  };
  if (campaignForm.selectionCriteria.gender === "ANY") {
    delete campaignForm.selectionCriteria.gender;
  }

  return campaign;
};

const mapStateToProps = (state: IStore) => ({
  customerCountForCampaign: state.campaigns.customerCountForCampaign,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetCustomerCountForCampaign,
      thunkCreateCampaign,
      thunkUpdateCampaign,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CampaignForm);
