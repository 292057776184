import { Button, Grid, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkGetGdprTemplates,
  thunkUpdateGdprTemplates
} from "../../../store/actions/GdprActions";
import { IStore } from "../../../store/IStore";
import { GdprTemplates } from "../../../store/models/gdpr/GdprTemplate";
import { FormInput } from "../../atoms/FormInput";

export const gdprTemplateItems = [
  {
    title: "2. Verarbeitungszwecke",
    key: "PROCESSING_PURPOSE",
    sequenceNumber: 2,
  },
  { title: "3. Datenkategorien", key: "DATA_CATEGORIES", sequenceNumber: 3 },
  { title: "4. Datenempfänger", key: "DATA_RECEIVER", sequenceNumber: 4 },
  { title: "5. Speicherdauer", key: "STORAGE_PERIOD", sequenceNumber: 5 },
  {
    title:
      "6. Betroffenenrechte (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Widerspruch)",
    key: "INFORMATIONAL_RIGHTS",
    sequenceNumber: 6,
  },
  {
    title: "7. Beschwerderecht gegenüber der Aufsichtsbehörde",
    key: "COMPLAINT_RIGHTS",
    sequenceNumber: 7,
  },
  { title: "8. Herkunft der Daten", key: "DATA_SOURCE", sequenceNumber: 8 },
  {
    title: "9. Automatisierte Entscheidungsfindung",
    key: "AUTOMATED_DECISION",
    sequenceNumber: 9,
  },
  {
    title: "10. Übermittlung in Drittstaaten",
    key: "TRANSFER_TO_THIRD_COUNTRIES",
    sequenceNumber: 10,
  },
  { title: "Grußformel", key: "SALUTATION", sequenceNumber: 11 },
];

const Gdpr = (props: ThunkProps) => {
  const theme = useTheme();
  const { gdprTemplates } = props;
  if (!gdprTemplates) {
    props.thunkGetGdprTemplates();
    return null;
  }

  const submit = (values: Record<string, string>) => {
    const gdprTemplates: GdprTemplates = {
      gdprTemplateItems: gdprTemplateItems.map((item) => {
        const value = Object.entries(values).find(
          (value) => value[0] === item.key
        );
        return {
          key: item.key,
          value: value ? value[1] : undefined,
          sequenceNumber: item.sequenceNumber,
        };
      }),
    };
    props.thunkUpdateGdprTemplates(gdprTemplates);
  };

  const initialValues: Record<string, string | undefined> = {};
  gdprTemplates?.gdprTemplateItems.forEach(
    (item) => (initialValues[item.key] = item.value)
  );

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {gdprTemplateItems.map((block) => (
            <div key={block.key} style={{ paddingBottom: theme.spacing(4) }}>
              <b>
                {block.title}
                <br />
              </b>
              <FormInput
                type="textarea"
                name={block.key}
                label=""
                variant="outlined"
                fullWidth={true}
                multiline={true}
              />
            </div>
          ))}
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  gdprTemplates: state.gdpr.gdprTemplates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkGetGdprTemplates, thunkUpdateGdprTemplates },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Gdpr);
