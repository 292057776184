import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathBonusCreate } from "../../api/url";
import { canMaintainBonus } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import BonusTable from "./BonusRuleTable";

export const BonusRulePage = () => {
  return (
    <MenuLayout headline="Bonuskampagnen">
      <Grid container spacing={2}>
        {canMaintainBonus() && (
          <Grid container item justifyContent="flex-end">
            <Button
              component={Link}
              to={UrlPathBonusCreate}
              startIcon="+"
              variant="contained"
              color="primary"
            >
              Neue Bonuskampagne
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <BonusTable />
        </Grid>
      </Grid>
    </MenuLayout>
  );
};
