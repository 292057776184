import { Grid, Paper, useTheme } from "@mui/material";
import { CircleDto } from "../../store/models/circle/CircleDto";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof } from "../atoms/Utils";
import { getLabelText, RegistrationFormThunkProps } from "./RegistrationForm";

const Registration = (props: RegistrationFormThunkProps) => {
  const theme = useTheme();
  const { circles, registrationFieldConfig } = props;
  return (
    <Paper
      id="personal"
      style={{
        padding: theme.spacing(3),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      }}
    >
      <ImsPaperHead text="Programm" />
      <Grid container spacing={2} style={{ paddingBottom: theme.spacing(4) }}>
        <Grid item md={2}>
          {
            <FormSelect
              name={nameof<CircleDto>("circleNumber")}
              label={getLabelText(
                "Circle",
                nameof<CircleDto>("circleName"),
                registrationFieldConfig
              )}
              options={[{ label: "bitte auswählen...", value: "" }].concat(
                circles.map((c) => ({
                  label: c.circleName,
                  value: c.circleNumber,
                }))
              )}
              fullWidth={true}
            />
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Registration;
