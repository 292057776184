import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkDeleteQuestion } from "../../store/actions/PreferenceActions";
import { IStore } from "../../store/IStore";
import { AnswerOptionDto } from "../../store/models/preferences/AnswerOptionDto";
import { QuestionDto } from "../../store/models/preferences/QuestionDto";
import { questionTypeOptions } from "../../store/models/preferences/QuestionType";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import PreferencesTableMenu from "./PreferencesTableMenu";

interface PreferencesTableProps {
  questions: QuestionDto[];
}

const PreferencesTable = (props: PreferencesTableProps & ThunkProps) => {
  props.questions.sort(sortQuestionsByPosition);
  for (const question of props.questions) {
    question.options.sort(sortAnswerOptionByPosition);
  }

  const headCells: ImsHeadCell<QuestionDto>[] = [
    { val: (val) => val.label, node: "Name" },
    { val: (val) => val.type, node: "Fragenart" },
  ];

  const rows: ImsTableRow<QuestionDto>[] = props.questions.map((row) => ({
    value: row,
    nodes: [
      <b>{row.label}</b>,
      questionTypeOptions.find((option) => option.value === row.type)?.label,
      <PreferencesTableMenu question={row} />,
    ],
  }));

  return <ImsTable hasMenu={true} headCells={headCells} rows={rows} />;
};

const sortQuestionsByPosition = (a: QuestionDto, b: QuestionDto) =>
  (a.position || 0) - (b.position || 0);

const sortAnswerOptionByPosition = (a: AnswerOptionDto, b: AnswerOptionDto) =>
  (a.position || 0) - (b.position || 0);

const mapStateToProps = (state: IStore) => ({
  questions: state.preferences.questions,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDeleteQuestion }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PreferencesTable);
