import { CouponDto } from "../coupon/CouponDto";
import { CustomerInteractionDto } from "../customer/CustomerInteractionDto";
import { CustomerStatus } from "../customer/CustomerStatusDto";

export interface CustomerHistoryDto {
  customerNumber: string;
  entityType: EntityType;
  eventTimestamp: string;
  eventType: EventType;
  eventDetails: EventDetails;
  eventUser: string;
  historyNumber: string;
  interaction?: CustomerInteractionDto;
  coupon?: CouponDto;
  customerStatus?: CustomerStatus;
  registrationTimestamp?: string;
  executionDate?: string;
  note?: string;
  username?: string;
}

export enum EntityType {
  CUSTOMER = "CUSTOMER",
  COUPON = "COUPON",
  CUSTOMER_STATUS = "CUSTOMER_STATUS",
  ADDRESS = "ADDRESS",
  PREFERENCE = "PREFERENCE",
  INTERACTION = "INTERACTION",
  CIRCLE = "CIRCLE",
  CUSTOMER_IDENTIFICATION = "CUSTOMER_IDENTIFICATION",
  DSGVO = "DSGVO",
}

export enum EventType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  REPLACEMENT = "REPLACEMENT",
  PRODUCE = "PRODUCE",
  TRANSFER_OWNERSHIP = "TRANSFER_OWNERSHIP",
}

export enum TransferType {
  REMOVED = "REMOVED",
  RECEIVED = "RECEIVED",
}

export interface EventDetails {
  creationDetails: CreationDetails;
  updateDetails: UpdateDetails;
}

export interface CreationDetails {
  details: string;
}

export interface UpdateDetails {
  from: string;
  to: string;
}
