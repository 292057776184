import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkNewReplacementCard } from "../../store/actions/ReplacementCardActions";
import { IStore } from "../../store/IStore";
import { ReplacementCardDto } from "../../store/models/identification/ReplacementCardDto";
import { FormInput } from "../atoms/FormInput";
import { nameof } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";

interface ReplacementCardDialogProps {
  setReplacementCardDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ReplacementCardDialog = ({
  setReplacementCardDialogVisible,
  ...props
}: ReplacementCardDialogProps & ThunkProps) => {
  const theme = useTheme();
  const submit = (replacementCard: ReplacementCardDto) => {
    props.thunkNewReplacementCard({ ...props.customer, ...replacementCard });
    setReplacementCardDialogVisible(false);
  };

  if (!props.customer) return null;
  return (
    <Dialog onClose={() => setReplacementCardDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        <b>Ersatzkarte wirklich anfordern?</b>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={2}>
                  <Grid item>
                    <FormInput
                      type="textarea"
                      name={nameof<ReplacementCardDto>("note")}
                      label="Notiz"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={() => setReplacementCardDialogVisible(false)}
                    >
                      Zurück
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                    >
                      Ersatzkarte anfordern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkNewReplacementCard }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ReplacementCardDialog);
