import { AnyAction } from "redux";
import { INTERACTION_SEARCH } from "../actions/InteractionActions";
import { InteractionSearchResponseDto } from "../models/interactionSearch/InteractionSearchResponseDto";

export interface IInteractionSearchReducerState {
  interactions: InteractionSearchResponseDto[];
}

export default class InteractionSearchReducer {
  public static reducer(
    state: IInteractionSearchReducerState,
    action: AnyAction
  ): IInteractionSearchReducerState {
    switch (action.type) {
      case INTERACTION_SEARCH:
        return {
          ...state,
          interactions: action.payload,
        };
      default:
        return state || {};
    }
  }
}
