import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";
import { formatApiDate } from "./Utils";

interface FormDateProps {
  name: string;
  label?: string;
  readOnly?: boolean;
  fieldProps?: any;
  prefix?: string;
  helperText?: string;
  disableFuture?: boolean;
  tooltip?: string;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
  fullWidth?: boolean;
  fieldConfig?: FieldConfigDto[];
}

export const FormDate = (props: FormDateProps) => {
  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig))
    return null;
  return (
    <Field name={props.name} parse={formatApiDate}>
      {({ input, meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        const helperText = hasErrors ? meta.error : props.helperText;
        const containerSpacing = props.prefix || props.tooltip ? 2 : 0;
        const itemSpacing = props.prefix || props.tooltip ? 9 : 12;
        return (
          <FormControl fullWidth={props.fullWidth}>
            <Grid container spacing={containerSpacing}>
              {props.prefix && (
                <Grid item style={{ margin: "auto" }}>
                  <Typography>{props.prefix}</Typography>
                </Grid>
              )}
              <Grid item xs={itemSpacing}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={de}
                >
                  <DatePicker
                    {...input}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    disableFuture={props.disableFuture}
                    disabled={props.readOnly}
                    InputProps={{ fullWidth: props.fullWidth }}
                    renderInput={(renderProps) => (
                      <TextField
                        {...renderProps}
                        data-testid={props.testId || "datePicker-" + props.name}
                        helperText={helperText}
                        error={hasErrors}
                      />
                    )}
                    label={props.label}
                    value={input.value || null}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </FormControl>
        );
      }}
    </Field>
  );
};
