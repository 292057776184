import { AnyAction } from "redux";
import { DELETE_CIRCLE, GET_ALL_CIRCLES } from "../actions/CircleActions";
import { CircleDto } from "../models/circle/CircleDto";

export interface ICircleReducerState {
  circles: CircleDto[];
}

export default class CircleReducer {
  public static reducer(
    state: ICircleReducerState,
    action: AnyAction
  ): ICircleReducerState {
    switch (action.type) {
      case GET_ALL_CIRCLES:
        return {
          ...state,
          circles: action.payload,
        };
      case DELETE_CIRCLE:
        const deletedCircle = action.payload as CircleDto;
        return {
          ...state,
          circles: state.circles.filter(
            (circle) => circle.circleNumber !== deletedCircle.circleNumber
          ),
        };
      default:
        return state || { circles: [] };
    }
  }
}
