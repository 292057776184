import { Brightness1, Launch, PersonAdd, Search } from "@mui/icons-material";
import { Button, Grid, Link, MenuItem, Paper, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails } from "../../api/url";
import {
  thunkClearCustomerSearch,
  thunkCustomerSearch,
} from "../../store/actions/CustomerCareActions";
import {
  thunkGetFamilyByCustomerNumber,
  thunkTransferFamilyMember,
  thunkUpdateFamilyMembership,
} from "../../store/actions/FamilyActions";
import { IStore } from "../../store/IStore";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import { CustomerSearchDto } from "../../store/models/customer/CustomerSearchDto";
import { getCustomerStatusColor } from "../../store/models/customer/CustomerStatusDto";
import {
  FamilyRoleDto,
  memberRoleOptions,
} from "../../store/models/family/FamilyMembershipDto";
import MenuLayout from "../app/MenuLayout";
import { DateTag } from "../atoms/DateTag";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";
import { COLOR_BLACK } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { nameof, useMountEffect } from "../atoms/Utils";
import { getFamilyMemberName } from "./Family";
import FamilyInvitation from "./FamilyInvitation";

const FamilyPage = (props: ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [invitationVisible, setInvitationVisible] = useState(false);
  const [transferFamilyMemberVisible, setTransferFamilyMemberVisible] =
    useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const { id } = useParams<"id">();
  const { family, customers } = props;

  useMountEffect(() => {
    props.thunkClearCustomerSearch();
  });

  const customerSearch = (customerSearch: CustomerSearchDto) => {
    props.thunkCustomerSearch(customerSearch);
  };

  const handleTransferFamilyMember = (customerNumber: string) => {
    setTransferFamilyMemberVisible(true);
    setCustomerNumber(customerNumber);
  };

  const headCells: ImsHeadCell<CustomerDto>[] = [
    { val: (val) => val.status, node: "" },
    { val: (val) => val.externalCustomerId, node: "Kartennummer" },
    { val: (val) => val.firstName, node: "Vorname" },
    { val: (val) => val.lastName, node: "Nachname" },
    { val: (val) => val.mailAddress, node: "E-Mail" },
    { val: (val) => val.dateOfBirth, node: "Geburtstag" },
    { val: (val) => val.address?.zipCode, node: "PLZ" },
    { val: (val) => val.address?.cityName, node: "Ort" },
  ];

  const rows: ImsTableRow<CustomerDto>[] | undefined = customers?.map(
    (row) => ({
      value: row,
      nodes: [
        <Brightness1
          style={{
            fill: getCustomerStatusColor(row.status),
            paddingTop: 4,
          }}
        />,

        <MenuItem>
          <Link
            component={RouterLink}
            to={UrlPathCustomerDetails.replace(":id", row.customerNumber)}
          >
            {row.externalCustomerId}
          </Link>
        </MenuItem>,
        <b>{row.firstName}</b>,
        <b>{row.lastName}</b>,
        <a
          style={{ color: COLOR_BLACK, textDecoration: "none" }}
          href={`mailto:${row.mailAddress}`}
        >
          {row.mailAddress}
        </a>,
        <DateTag date={row.dateOfBirth} />,
        row.address ? row.address.zipCode : "",
        row.address ? row.address.cityName : "",
        <PersonAdd
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleTransferFamilyMember(row.customerNumber);
          }}
        />,
      ],
    })
  );

  if (!id) return null;
  if (redirect) {
    return <Navigate to={UrlPathCustomerDetails.replace(":id", id)} />;
  }

  if (
    !family ||
    !family.familyMembers.some((member) => member.customerNumber === id)
  ) {
    props.thunkGetFamilyByCustomerNumber(id);
    return null;
  }

  const initialValues: FamilyRoleDto = {};

  family.familyMembers.forEach((member) => {
    initialValues[member.customerNumber] = member.roleName || "";
  });

  return (
    <MenuLayout headline="Familiengruppe">
      <Grid container justifyContent="flex-end">
        <Button
          startIcon="+"
          variant="contained"
          color="primary"
          onClick={() => setInvitationVisible(true)}
        >
          Mitglied einladen
        </Button>
      </Grid>
      {transferFamilyMemberVisible && (
        <ImsConfirmDialog
          article="das"
          verb="zur Familie hinzufügen"
          word="Mitglied"
          hint="Dieser Vorgang kann nicht rückgängig gemacht werden. Das Mitglied bleibt bis zur Kündigung Teil der neuen Familie."
          onConfirm={() =>
            props.thunkTransferFamilyMember(family.familyNumber, customerNumber)
          }
          setConfirmVisible={(confirmVisible) =>
            setTransferFamilyMemberVisible(confirmVisible)
          }
        />
      )}
      {invitationVisible && (
        <FamilyInvitation closeInvitation={() => setInvitationVisible(false)} />
      )}
      <Form
        onSubmit={async (familyRoles: FamilyRoleDto) => {
          for (const [customerNumber, roleName] of Object.entries(
            familyRoles
          )) {
            await props.thunkUpdateFamilyMembership(
              family.familyNumber,
              customerNumber,
              {
                roleName,
              }
            );
          }
          const anyFamilyMemberCustomerNumber =
            family.familyMembers[0].customerNumber;
          props.thunkGetFamilyByCustomerNumber(anyFamilyMemberCustomerNumber);
          setRedirect(true);
        }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 3),
                }}
              >
                <ImsPaperHead
                  text="Mitglieder"
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Grid container spacing={4}>
                  {family.familyMembers.map((member) => (
                    <React.Fragment key={member.customerNumber}>
                      <Grid item md={4} style={{ marginTop: theme.spacing(2) }}>
                        <span
                          style={{
                            paddingRight: theme.spacing(1),
                            fontWeight:
                              member.customerNumber === id ? "bold" : undefined,
                          }}
                        >
                          {getFamilyMemberName(member)}
                        </span>
                        {member.customerDto && (
                          <Link
                            component={RouterLink}
                            to={UrlPathCustomerDetails.replace(
                              ":id",
                              member.customerNumber
                            )}
                          >
                            <Launch
                              style={{ marginBottom: theme.spacing(-1) }}
                            />
                          </Link>
                        )}
                      </Grid>
                      <Grid item md={7}>
                        <FormSelect
                          name={member.customerNumber}
                          fullWidth={true}
                          label=""
                          options={memberRoleOptions}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Speichern
                </Button>
              </Grid>
            </form>
          );
        }}
      />
      <Form
        onSubmit={customerSearch}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 3),
                }}
              >
                <ImsPaperHead
                  text="Familienmitglieder hinzufügen"
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>Kartennummer</b>
                      </Grid>
                      <Grid item md={8}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("externalCustomerId")}
                          label="Kartennummer eingeben"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>Name</b>
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("firstName")}
                          label="Vorname"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("lastName")}
                          label="Nachname"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name={nameof<CustomerSearchDto>("dateOfBirth")}
                          label="Geburtsdatum"
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid
                container
                style={{
                  marginBottom: theme.spacing(3),
                  marginTop: theme.spacing(2),
                }}
                justifyContent="flex-end"
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                >
                  <Search />
                  Suchen
                </Button>
              </Grid>
            </form>
          );
        }}
      />
      {rows &&
        (rows.length ? (
          <ImsTable
            hasMenu={true}
            headCells={headCells}
            rows={rows}
            sortColumnIndex={1}
          />
        ) : (
          <p>keine Ergebnisse</p>
        ))}
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  family: state.customerCare.family,
  customers: state.customerCare.customers,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetFamilyByCustomerNumber,
      thunkUpdateFamilyMembership,
      thunkTransferFamilyMember,
      thunkCustomerSearch,
      thunkClearCustomerSearch,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FamilyPage);
