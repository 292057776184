import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathPreferencesCreate } from "../../api/url";
import { canMaintainPreferences } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import PreferencesTable from "./PreferencesTable";

export const PreferencesPage = () => (
  <MenuLayout headline="Präferenzfragen">
    <Grid container spacing={2}>
      {canMaintainPreferences() && (
        <Grid container item justifyContent="flex-end">
          <Button
            component={Link}
            to={UrlPathPreferencesCreate}
            startIcon="+"
            variant="contained"
            color="primary"
          >
            Neue Präferenzfrage
          </Button>
        </Grid>
      )}
      <Grid item md={12}>
        <PreferencesTable />
      </Grid>
    </Grid>
  </MenuLayout>
);
