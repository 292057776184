import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { UrlPathMain } from "../../api/url";
import { thunkUpdateBonusXpress } from "../../store/actions/BonusXpressActions";
import { canMaintainBonus } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { BonusXpressDto } from "../../store/models/bonusXpress/BonusXpressDto";
import { BonusXpressRoundingType } from "../../store/models/bonusXpress/BonusXpressRoundingType";
import { BonusXpressStatus } from "../../store/models/bonusXpress/BonusXpressStatus";
import MenuLayout from "../app/MenuLayout";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import ImsPaperHead from "../atoms/ImsPaperHead";

const statusOptions = [
  { value: BonusXpressStatus.ENABLED, label: "Ja" },
  { value: BonusXpressStatus.DISABLED, label: "Nein" },
];

const roundingTypeOptions = [
  { value: BonusXpressRoundingType.UP, label: "Aufrunden" },
  { value: BonusXpressRoundingType.DOWN, label: "Abrunden" },
  { value: BonusXpressRoundingType.MERCANTILE, label: "kaufmännisches Runden" },
];

const BonusXpressPage = (props: ThunkProps) => {
  const [redirect, setRedirect] = useState(false);
  const theme = useTheme();
  if (redirect) {
    return <Navigate to={UrlPathMain} />;
  }
  if (!props.bonusXpress) return null;
  const readOnly = !canMaintainBonus();
  return (
    <MenuLayout headline="Bonus XPress-Konfiguration">
      <Form
        onSubmit={(bonusXpress: BonusXpressDto) => {
          props.thunkUpdateBonusXpress(bonusXpress);
        }}
        initialValues={props.bonusXpress}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text="Bonus XPress aktivieren?" />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        label="Soll Bonus XPress aktiviert werden?"
                        name="bonusXpressStatus"
                        value={values.bonusXpressStatus}
                        options={statusOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 0),
                }}
              >
                <ImsPaperHead text="Bonus XPress Details" />
                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>Gegenwert pro Punkt</b>
                  </Grid>
                  <Grid item md={4}>
                    <FormInput
                      type="number"
                      name="bonusXpressAmount"
                      label="Cent"
                      fullWidth={true}
                      variant="outlined"
                      tooltip="Cents pro eingelöstem Bonuspunkt"
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <b>Auszahlung ab wie vielen Punkten?</b>
                  </Grid>
                  <Grid item md={4}>
                    <FormInput
                      type="number"
                      name="bonusXpressThreshold"
                      label="Schwellwert Punkte"
                      fullWidth={true}
                      variant="outlined"
                      tooltip="Mindestens einzulösende Bonuspunkte"
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormRadioButton
                      label="Rundung der Punkte bei Auszahlung"
                      name="bonusXpressRoundingType"
                      value={values.bonusXpressRoundingType}
                      options={roundingTypeOptions}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Abbrechen
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  Speichern
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ thunkUpdateBonusXpress }, dispatch);

const mapStateToProps = (state: IStore) => ({
  bonusXpress: state.bonusXpress.bonusXpress,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusXpressPage);
