import { Brightness1, Edit } from "@mui/icons-material";
import { Button, Grid, Paper, useTheme } from "@mui/material";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails } from "../../api/url";
import {
  thunkClearCustomer,
  thunkCreateCustomerCustomField,
  thunkDeleteCustomerCustomField,
  thunkGetCustomer,
  thunkGetCustomerCustomFields,
  thunkGetCustomerDisplayConfig,
  thunkGetCustomerFieldConfig,
  thunkUpdateCustomer,
  thunkUpdateCustomerCustomField,
} from "../../store/actions/CustomerCareActions";
import { canMaintainCustomers } from "../../store/actions/LoginActions";
import {
  thunkCancelCustomerMembership,
  thunkRevokeCancelCustomerMembership,
} from "../../store/actions/MembershipCancellationActions";
import { IStore } from "../../store/IStore";
import { CircleDto } from "../../store/models/circle/CircleDto";
import { ConfigGroupKey } from "../../store/models/config/ConfigGroupKey";
import { AddressDto } from "../../store/models/customer/AddressDto";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import {
  CustomerStatus,
  getCustomerStatusColor,
  getCustomerStatusText,
} from "../../store/models/customer/CustomerStatusDto";
import { genderOptions } from "../../store/models/customer/Gender";
import MenuLayout from "../app/MenuLayout";
import {
  validateCustomerFields,
  validateDateOfBirth,
  validateName,
} from "../atoms/FieldValidation";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import {
  COLOR_GRAY_1,
  COLOR_WHITE,
  KNISTR_RED,
} from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import {
  nameof,
  nameof2,
  useMountEffect,
  ValidationErrors,
} from "../atoms/Utils";
import { getHeadlineText } from "./CustomerCareDetailsPage";
import UpdateCustomerStatus from "./CustomerStatusDialog";
import MembershipCancellationDialog from "./MembershipCancellationDialog";

const countryList = Object.entries(countries)
  .map(([k, v]) => ({
    value: k,
    label: v.name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const CustomerCareEditPage = (props: ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [updateCustomerStatusVisible, setUpdateCustomerStatusVisible] =
    useState(false);
  const [
    membershipCancellationDialogVisible,
    setMembershipCancellationDialogVisible,
  ] = useState(false);
  const { id } = useParams<"id">();

  const {
    customerDisplayConfig,
    customerFieldConfig,
    operationalUnits,
    circles,
    customer,
    customerCustomFields,
  } = props;

  useMountEffect(() => {
    if (!customerDisplayConfig?.length) props.thunkGetCustomerDisplayConfig();
  });

  const customFields = props.configGroups.find(
    configGroup => configGroup.key === ConfigGroupKey.CUSTOM_FIELD
  );

  if (!id) return null;

  if (!customer || customer.customerNumber !== id) {
    props.thunkClearCustomer();
    props.thunkGetCustomer(id);
    return null;
  }

  if (!customerFieldConfig) {
    props.thunkGetCustomerFieldConfig();
    return null;
  }

  if (!props.customerCustomFields) {
    props.thunkGetCustomerCustomFields(customer.customerNumber);
    return null;
  }

  const validateForm = (updatedCustomer: CustomerDto) => {
    const errors: ValidationErrors<CustomerDto> = {};
    validateCustomerFields(
      errors,
      customer,
      updatedCustomer,
      customerFieldConfig
    );
    validateDateOfBirth(errors, updatedCustomer.dateOfBirth);
    validateName(errors, updatedCustomer.firstName, updatedCustomer.lastName);
    return errors;
  };

  const getCircleNameFromNumber = (circleNumber?: string) =>
    circles.find(
      (circleObj: CircleDto) => circleObj.circleNumber === circleNumber
    )?.circleName;

  const handleCustomerMembership = async () => {
    if (customer.executionDate) {
      await props.thunkRevokeCancelCustomerMembership(customer.customerNumber);
    } else {
      setMembershipCancellationDialogVisible(true);
    }
  };

  const onSubmit = async (customer: CustomerDto) => {
    if (customer.dateOfBirth === "") {
      customer.dateOfBirth = undefined;
    }
    if (!(await props.thunkUpdateCustomer(customer))) {
      return;
    }
    let reloadCustomFields = false;
    for (let customField of customFields?.values || []) {
      const fieldName = customField.key;
      const oldValue = customerCustomFields?.find(
        customerCustomField => customerCustomField.fieldName === fieldName
      );
      const fieldValue = (customer as any)[fieldName];

      if (oldValue && !fieldValue) {
        await props.thunkDeleteCustomerCustomField(customer.customerNumber, {
          fieldName,
          fieldValue,
        });
        reloadCustomFields = true;
      } else if (!oldValue && fieldValue) {
        await props.thunkCreateCustomerCustomField(customer.customerNumber, {
          fieldName,
          fieldValue,
        });
        reloadCustomFields = true;
      } else if (oldValue !== fieldValue) {
        await props.thunkUpdateCustomerCustomField(customer.customerNumber, {
          fieldName,
          fieldValue,
        });
        reloadCustomFields = true;
      }
    }
    if (reloadCustomFields) {
      await props.thunkGetCustomerCustomFields(customer.customerNumber);
    }
    setRedirect(true);
  };

  const getButtonText = () => {
    if (customer.executionDate) {
      return "Kündigung abbrechen";
    } else {
      return "Mitglied kündigen";
    }
  };

  const getButtonStyling = () => {
    if (customer.executionDate) {
      return {
        backgroundColor: theme.palette.primary.main,
        color: COLOR_WHITE,
      };
    }
    return {
      backgroundColor: KNISTR_RED,
      color: COLOR_WHITE,
    };
  };

  if (redirect) {
    return <Navigate to={UrlPathCustomerDetails.replace(":id", id)} />;
  }

  const initialValues = {
    ...customer,
    ...customFields?.values?.reduce(
      (prev, customField) => ({
        ...prev,
        [customField.key]: customerCustomFields?.find(
          customerCustomField =>
            customerCustomField.fieldName === customField.key
        )?.fieldValue,
      }),
      {}
    ),
  };

  return (
    <MenuLayout
      headline={getHeadlineText(customer)}
      subHeadline={getCircleNameFromNumber(customer.circleNumber)}
    >
      {updateCustomerStatusVisible && (
        <UpdateCustomerStatus
          setCustomerStatusDialogVisible={setUpdateCustomerStatusVisible}
        />
      )}
      {membershipCancellationDialogVisible && (
        <MembershipCancellationDialog
          setMembershipCancellationDialogVisible={
            setMembershipCancellationDialogVisible
          }
        />
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateForm}
        render={({ handleSubmit, submitting, initialValues }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                style={{
                  marginBottom: theme.spacing(7.5),
                }}
              >
                <Grid item md={6} container alignItems="center">
                  <Brightness1
                    style={{
                      fill: getCustomerStatusColor(customer.status),
                      marginRight: theme.spacing(1),
                      paddingBottom: 2,
                    }}
                  />
                  {getCustomerStatusText(customer.status)}
                  {canMaintainCustomers() &&
                    customer.status !== CustomerStatus.CANCELLED && (
                      <Edit
                        style={{
                          marginLeft: theme.spacing(2),
                          fontSize: 24,
                          backgroundColor: theme.palette.primary.main,
                          color: COLOR_GRAY_1,
                          cursor: "hand",
                          borderRadius: "50%",
                          padding: 1,
                        }}
                        onClick={() => {
                          setUpdateCustomerStatusVisible(true);
                          return false;
                        }}
                      />
                    )}
                </Grid>
                {canMaintainCustomers() && (
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      style={getButtonStyling()}
                      onClick={() => {
                        handleCustomerMembership();
                      }}
                    >
                      {getButtonText()}
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  margin: theme.spacing(6, 0, 3, 0),
                }}
              >
                <ImsPaperHead text="Persönliche Informationen" />
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormSelect
                      name={nameof<CustomerDto>("gender")}
                      label="Geschlecht"
                      options={genderOptions}
                      fullWidth={true}
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("title")}
                      label="Titel"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("firstName")}
                      label="Vorname"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("lastName")}
                      label="Nachname"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      name={nameof<CustomerDto>("externalCustomerId")}
                      fullWidth={true}
                      label="Kundennummer"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormDate
                      name={nameof<CustomerDto>("dateOfBirth")}
                      label="Geburtsdatum"
                      fullWidth={true}
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormSelect
                      name={nameof<CustomerDto>("masterUnitNumber")}
                      label="Stammfiliale"
                      fullWidth
                      options={operationalUnits.map(operationalUnit => ({
                        label: operationalUnit.unitName,
                        value: operationalUnit.unitNumber,
                      }))}
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  margin: theme.spacing(6, 0, 3, 0),
                }}
              >
                <ImsPaperHead text="Kontaktinformationen" />
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "streetName"
                      )}
                      label="Straße"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "houseNumber"
                      )}
                      label="Nr."
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "addressAddition"
                      )}
                      label="Adresszusatz"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={1}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "zipCode"
                      )}
                      label="PLZ"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                      pattern=".{4,}"
                    />
                  </Grid>
                  <Grid item md={5}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "cityName"
                      )}
                      label="Ort"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("mailAddress")}
                      label="E-Mail"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormSelect
                      name={nameof2<CustomerDto, AddressDto>(
                        "address",
                        "country"
                      )}
                      label="Land"
                      options={countryList}
                      fullWidth={true}
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: theme.spacing(0),
                  }}
                >
                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("phoneNumber")}
                      label="Telefon"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>

                  <Grid item md={3}>
                    <FormInput
                      type="text"
                      fullWidth={true}
                      name={nameof<CustomerDto>("mobileNumber")}
                      label="Mobil"
                      variant="outlined"
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
              </Paper>

              {Boolean(customFields?.values) && (
                <Paper
                  style={{
                    padding: theme.spacing(3),
                    margin: theme.spacing(6, 0, 3, 0),
                  }}
                >
                  <ImsPaperHead text="Zusatzfelder" />
                  <Grid
                    container
                    spacing={2}
                    style={{ paddingBottom: theme.spacing(4) }}
                  >
                    {customFields?.values.map(customField => (
                      <Grid item md={2} key={customField.key}>
                        <FormInput
                          type="text"
                          name={customField.key}
                          fullWidth={true}
                          label={customField.value}
                          variant="outlined"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              )}

              <Paper
                style={{
                  padding: theme.spacing(3),
                  margin: theme.spacing(6, 0, 3, 0),
                }}
              >
                <ImsPaperHead text="Programm" />
                <Grid
                  container
                  spacing={2}
                  style={{ paddingBottom: theme.spacing(4) }}
                >
                  <Grid item md={2}>
                    <FormSelect
                      name={nameof<CustomerDto>("circleNumber")}
                      label={"Circle"}
                      options={circles.map(circle => ({
                        label: circle.circleName,
                        value: circle.circleNumber,
                      }))}
                      fullWidth={true}
                      fieldConfig={customerFieldConfig}
                    />
                  </Grid>
                </Grid>
              </Paper>

              <Grid container justifyContent="flex-end">
                <FormCheckbox
                  name={nameof<CustomerDto>("emailCorrespondenceAllowed")}
                  label={
                    !initialValues.emailCorrespondenceAllowed
                      ? "E-Mail zur Newsletteranmeldung versenden"
                      : "Einwilligung zum Newsletter"
                  }
                  displayConfig={customerDisplayConfig}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <FormCheckbox
                  name={nameof<CustomerDto>("letterCorrespondenceAllowed")}
                  label="Einwilligung zur Briefkommunikation"
                  displayConfig={customerDisplayConfig}
                />
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={{
                  margin: theme.spacing(1, 0, 2, 0),
                }}
              >
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                >
                  Abbrechen
                </Button>

                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  style={{ marginLeft: theme.spacing(2) }}
                >
                  Speichern
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  operationalUnits: state.operationalUnits.operationalUnits,
  customerDisplayConfig: state.customerCare.customerDisplayConfig,
  customerFieldConfig: state.customerCare.customerFieldConfig,
  circles: state.circles.circles,
  configGroups: state.configGroups.configGroups,
  customerCustomFields: state.customerCare.customerCustomFields,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkClearCustomer,
      thunkGetCustomer,
      thunkUpdateCustomer,
      thunkGetCustomerDisplayConfig,
      thunkGetCustomerFieldConfig,
      thunkCancelCustomerMembership,
      thunkRevokeCancelCustomerMembership,
      thunkGetCustomerCustomFields,
      thunkCreateCustomerCustomField,
      thunkUpdateCustomerCustomField,
      thunkDeleteCustomerCustomField,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerCareEditPage);
