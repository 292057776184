import { Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import ImsPaperHead from "../atoms/ImsPaperHead";
import HistoryFeedDialog from "./HistoryFeedDialog";
import HistoryFeedTable from "./HistoryFeedTable";

const HistoryFeed = () => {
  const theme = useTheme();
  const [historyFeedDialogVisible, setHistoryFeedDialogVisible] =
    useState(false);

  return (
    <Grid item md={8}>
      {historyFeedDialogVisible && (
        <HistoryFeedDialog
          setHistoryFeedDialogVisible={setHistoryFeedDialogVisible}
        />
      )}
      <Paper
        style={{
          padding: theme.spacing(3, 0),
          margin: theme.spacing(0, 0, 0, 3),
          height: "100%",
        }}
      >
        <ImsPaperHead
          text="Letzte Aktivitäten"
          style={{ marginLeft: theme.spacing(3) }}
        />
        <HistoryFeedTable maxTableSize={5} />
        <Grid container justifyContent="flex-end">
          <Link
            style={{ cursor: "pointer", marginRight: theme.spacing(3) }}
            onClick={() => {
              setHistoryFeedDialogVisible(true);
            }}
          >
            <Typography variant="button">mehr anzeigen</Typography>
          </Link>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default HistoryFeed;
