import { AnyAction } from "redux";
import {
  ACTIVATE_BONUS_PREMIUM,
  DEACTIVATE_BONUS_PREMIUM,
  DELETE_BONUS_PREMIUM,
  GET_BONUS_PREMIUMS,
  UPDATE_BONUS_PREMIUM,
} from "../actions/BonusPremiumActions";
import {
  BonusPremiumDto,
  BonusPremiumStatus,
} from "../models/bonusPremium/BonusPremiumDto";

export interface IBonusPremiumReducerState {
  bonusPremiums: BonusPremiumDto[];
}

export default class BonusPremiumReducer {
  public static reducer(
    state: IBonusPremiumReducerState,
    action: AnyAction
  ): IBonusPremiumReducerState {
    switch (action.type) {
      case GET_BONUS_PREMIUMS:
        return { ...state, bonusPremiums: action.payload };
      case UPDATE_BONUS_PREMIUM:
        const updatedBonusPremium = action.payload as BonusPremiumDto;
        return {
          ...state,
          bonusPremiums: state.bonusPremiums.map((bonusPremium) =>
            bonusPremium.premiumNumber === updatedBonusPremium.premiumNumber
              ? updatedBonusPremium
              : bonusPremium
          ),
        };
      case DELETE_BONUS_PREMIUM:
        return {
          ...state,
          bonusPremiums: [
            ...state.bonusPremiums.filter(
              (bonusPremium) =>
                bonusPremium.premiumNumber !== action.payload.premiumNumber
            ),
          ],
        };
      case ACTIVATE_BONUS_PREMIUM:
        return {
          ...state,
          bonusPremiums: [
            ...state.bonusPremiums.map((premium) => {
              if (premium.premiumNumber !== action.payload.premiumNumber) {
                return premium;
              } else {
                return {
                  ...premium,
                  premiumStatus: BonusPremiumStatus.ENABLED,
                };
              }
            }),
          ],
        };
      case DEACTIVATE_BONUS_PREMIUM:
        return {
          ...state,
          bonusPremiums: [
            ...state.bonusPremiums.map((premium) => {
              if (premium.premiumNumber !== action.payload.premiumNumber) {
                return premium;
              } else {
                return {
                  ...premium,
                  premiumStatus: BonusPremiumStatus.DISABLED,
                };
              }
            }),
          ],
        };
      default:
        return state || { bonusPremiums: [] };
    }
  }
}
