import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setMilliseconds, setSeconds } from "date-fns";
import { de } from "date-fns/locale";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";

interface FormDateTimeProps {
  name: string;
  label?: string;
  readOnly?: boolean;
  prefix?: string;
  helperText?: string;
  tooltip?: string;
  testId?: string;
  fullWidth?: boolean;
  fieldConfig?: FieldConfigDto[];
}

export const FormDateTime = (props: FormDateTimeProps) => {
  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig))
    return null;
  return (
    <Field
      name={props.name}
      parse={(value) => {
        value = setSeconds(value, 0);
        value = setMilliseconds(value, 0);
        return value;
      }}
    >
      {({ input, meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        const helperText = hasErrors ? meta.error : props.helperText;
        const containerSpacing = props.prefix || props.tooltip ? 2 : 0;
        const itemSpacing = props.prefix || props.tooltip ? 9 : 12;
        return (
          <FormControl fullWidth={props.fullWidth}>
            <Grid container spacing={containerSpacing}>
              {props.prefix && (
                <Grid item style={{ margin: "auto" }}>
                  <Typography>{props.prefix}</Typography>
                </Grid>
              )}
              <Grid item xs={itemSpacing}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={de}
                >
                  <DateTimePicker
                    ampm={false}
                    {...input}
                    disabled={props.readOnly}
                    InputProps={{ fullWidth: props.fullWidth }}
                    renderInput={(renderProps) => (
                      <TextField
                        {...renderProps}
                        data-testid={
                          props.testId || "dateTimePicker-" + props.name
                        }
                        helperText={helperText}
                        error={hasErrors}
                      />
                    )}
                    label={props.label}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </FormControl>
        );
      }}
    </Field>
  );
};
