import { useState, useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";
import {
  TabCircle,
  TabCustomField,
  TabDSGVO,
  TabFilter,
  TabWallet,
} from "../../../api/url";

const setupLinks = [
  TabCircle,
  TabWallet,
  TabDSGVO,
  TabFilter,
  TabCustomField,
];

/**
 * Additional handle for Setup menu item
 *
 * The setup menu contains many kinds of page. If the current page is belongs to setup menu,
 * then the Setup menu must be highlighted.
 *
 * @param to The target link of the menu
 */
export default function useMenuMatch(to: string) {
  const [isSetupMatch, setIsSetupMatch] = useState(false);
  const currentPath = useLocation().pathname;

  //Match normal page and sub pages
  const match = useMatch({ path: `${to}/*`, end: true });

  useEffect(() => {
    if (to && to.length === 0) {
      //Invalid input. Abort
      return;
    }
    const removedSlashTo = to.substring(1);
    if (!setupLinks.includes(removedSlashTo)) {
      //This it not setup menu. Abort
      return;
    }

    //path always start with "/" so "split" always returns 2 elements
    //the element at 0 is always empty
    //Ex: /cirle => ["", "circle"]
    const firstPath = currentPath.split("/")[1];

    if (setupLinks.includes(firstPath) && setupLinks.includes(removedSlashTo)) {
      setIsSetupMatch(true);
    }
  }, [currentPath, to]);

  return isSetupMatch || match;
}
