import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { IStore } from "../../store/IStore";
import { QuestionType } from "../../store/models/preferences/QuestionType";
import MenuLayout from "../app/MenuLayout";
import PreferenceForm from "./PreferenceForm";

const PreferencesFormPage = (props: ThunkProps) => {
  const { id } = useParams<"id">();
  const { questions } = props;

  if (!questions) return null;

  const createDefaultQuestion = () => {
    return {
      label: "",
      position: Math.max(...questions.map((p) => p.position || 0), 0) + 1,
      questionNumber: `NEW-QUESTION-${uuid()}`,
      type: QuestionType.SINGLE,
      options: [],
      isNew: true,
      tags: [],
    };
  };

  const question = questions.find((q) => q.questionNumber === id);

  return (
    <MenuLayout
      headline={id ? "Präferenzfrage bearbeiten" : "Neue Präferenzfrage"}
    >
      <PreferenceForm question={question || createDefaultQuestion()} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  questions: state.preferences.questions,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PreferencesFormPage);
