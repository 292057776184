import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import {
  CampaignDto,
  SelectionCriteriaDto,
} from "../models/campaign/CampaignDto";
import { CampaignStatus } from "../models/campaign/CampaignStatus";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const FIND_ALL_CAMPAIGNS = "FIND_ALL_CAMPAIGNS";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const GET_CUSTOMER_COUNT_FOR_CAMPAIGN =
  "GET_CUSTOMER_COUNT_FOR_CAMPAIGN";
export const ENABLE_CAMPAIGN = "ENABLE_CAMPAIGN";
export const DISABLE_CAMPAIGN = "DISABLE_CAMPAIGN";

export const thunkFindAllCampaigns = (): BooleanThunk => async (dispatch) => {
  try {
    const campaigns = await Api.findAllCampaigns();
    dispatch({
      type: FIND_ALL_CAMPAIGNS,
      payload: campaigns,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification("Fehler beim Laden der Kampagnen", e)
    );
    return false;
  }
};

export const thunkCreateCampaign =
  (newCampaign: CampaignDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createCampaign(newCampaign);
      dispatch(thunkFindAllCampaigns() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Die Kampagne wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen der Kampagne", e)
      );
      return false;
    }
  };

export const thunkUpdateCampaign =
  (campaign: CampaignDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedCampaign = await Api.updateCampaign(campaign);
      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: updatedCampaign,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Kampagne wurde aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der Kampagne",
          e
        )
      );
      return false;
    }
  };

export const thunkGetCustomerCountForCampaign =
  (selectionCriteria: SelectionCriteriaDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const criteria = { ...selectionCriteria };
      if (criteria.gender === "ANY") {
        delete criteria.gender;
      }
      const customerCountForCampaign = await Api.getCustomerCountForCampaign(
        criteria
      );
      dispatch({
        type: GET_CUSTOMER_COUNT_FOR_CAMPAIGN,
        payload: customerCountForCampaign,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler bei der Zielgruppen-Suche", e)
      );
      return false;
    }
  };

export const thunkEnableCampaign =
  (campaign: CampaignDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateCampaignStatus(
        campaign.campaignNumber,
        CampaignStatus.ENABLED
      );
      dispatch({
        type: ENABLE_CAMPAIGN,
        payload: campaign,
      });
      dispatch(thunkCreateSuccessNotification("Die Kampagne wurde aktiviert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktivieren der Kampagne", e)
      );
      return false;
    }
  };

export const thunkDisableCampaign =
  (campaign: CampaignDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateCampaignStatus(
        campaign.campaignNumber,
        CampaignStatus.DISABLED
      );
      dispatch({
        type: DISABLE_CAMPAIGN,
        payload: campaign,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Kampagne wurde deaktiviert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Deaktivieren der Kampagne", e)
      );
      return false;
    }
  };
