import { CheckCircleOutline } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, useTheme } from "@mui/material";
import React from "react";
import { ensureToken } from "../../store/actions/LoginActions";
import { KNISTR_GREEN } from "../atoms/ImsMaterialTheme";
import { ImsHeadCell, ImsTable } from "../atoms/ImsTable";
import NotificationList from "../notification/NotificationList";

interface ShowRolesProps {
  setShowRolesVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RoleView {
  readonly: boolean;
  maintain: boolean;
}

function screamingSnakeCaseToSentenceCase(str: string) {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

const ShowRoles = (props: ShowRolesProps) => {
  const theme = useTheme();
  const { setShowRolesVisible } = props;

  const loyaltyToken = ensureToken();
  if (!loyaltyToken) return null;
  const roles = new Map<string, RoleView>();
  loyaltyToken.groups?.forEach((row) => {
    const key = row.substring(0, row.lastIndexOf("_"));
    const privilege = row.substring(row.lastIndexOf("_") + 1, row.length);
    const value = roles.get(key) || { readonly: false, maintain: false };
    if (privilege === "MAINTAIN") value.maintain = true;
    if (privilege === "READONLY") value.readonly = true;
    roles.set(key, value);
  });

  const rows = Array.from(roles, ([key, value]) => ({ key, ...value })).map(
    (row) => ({
      value: row,
      nodes: [
        screamingSnakeCaseToSentenceCase(row.key),
        row.readonly ? (
          <CheckCircleOutline
            style={{
              fill: KNISTR_GREEN,
            }}
          />
        ) : null,
        row.maintain ? (
          <CheckCircleOutline
            style={{
              fill: KNISTR_GREEN,
            }}
          />
        ) : null,
      ],
    })
  );

  const headCells: ImsHeadCell<{
    key: string;
    readonly: boolean;
    maintain: boolean;
  }>[] = [
    { node: "Rolle", val: (val) => val.key },
    { node: "Lesen", val: (val) => val.readonly },
    { node: "Schreiben", val: (val) => val.maintain },
  ];

  return (
    <Dialog onClose={() => setShowRolesVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>Rollen anzeigen</DialogTitle>
      <DialogContent style={{ paddingBottom: theme.spacing(4) }}>
        {rows.length ? (
          <ImsTable hasMenu={false} headCells={headCells} rows={rows} />
        ) : (
          "Ihrem Konto sind keine Rollen zugeordnet. Es handelt sich hierbei um einen Systemfehler."
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShowRoles;
