import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import {
  CustomerPreferenceDto,
  CustomerPreferenceMap,
} from "../models/preferences/CustomerPreferenceDto";
import { QuestionDto } from "../models/preferences/QuestionDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_CUSTOMER_PREFERENCES = "GET_CUSTOMER_PREFERENCES";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";

export const thunkGetQuestions = (): BooleanThunk => async (dispatch) => {
  try {
    const preference = await Api.getQuestions();
    dispatch({
      type: GET_QUESTIONS,
      payload: preference.questions,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification("Fehler beim Laden der Präferenzen", e)
    );
    return false;
  }
};

export const thunkGetCustomerPreferences =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const customerPreferences = await Api.getCustomerPreferences(
        customerNumber
      );
      dispatch({
        type: GET_CUSTOMER_PREFERENCES,
        payload: customerPreferences,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          `Fehler beim Laden der Präferenzen für Kundennummer ${customerNumber}`,
          e
        )
      );
      return false;
    }
  };

export const thunkUpdateCustomerPreferences =
  (
    customerNumber: string,
    preferenceMap: CustomerPreferenceMap
  ): BooleanThunk =>
  async (dispatch) => {
    const preferences: CustomerPreferenceDto[] = [];
    for (const key in preferenceMap) {
      const value = preferenceMap[key];
      if (value instanceof Array) {
        value.forEach((val) => {
          preferences.push({
            selectedQuestionNumber: key,
            selectedOptionNumber: val,
          });
        });
      } else {
        preferences.push({
          selectedQuestionNumber: key,
          selectedOptionNumber: value,
        });
      }
    }

    try {
      await Api.updateCustomerPreferences(preferences, customerNumber);
      dispatch(
        thunkCreateSuccessNotification(
          "Die Kundenpräferenzen wurden gespeichert"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Speichern der Kundenpräferenzen",
          e
        )
      );
      return false;
    }
  };

export const thunkUpdateQuestion =
  (question: QuestionDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedQuestion = await Api.updateQuestion(question);
      dispatch({
        type: UPDATE_QUESTION_SUCCESS,
        payload: updatedQuestion,
      });
      dispatch(
        thunkCreateSuccessNotification("Die Präferenz wurde aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der Präferenz",
          e
        )
      );
      return false;
    }
  };

export const thunkCreateQuestion =
  (question: QuestionDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const createdQuestion = await Api.createQuestion(question);
      dispatch({
        type: CREATE_QUESTION_SUCCESS,
        payload: createdQuestion,
      });
      dispatch(thunkCreateSuccessNotification("Die Präferenz wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen der Präferenz", e)
      );
      return false;
    }
  };

export const thunkDeleteQuestion =
  (question: QuestionDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteQuestion(question);
      dispatch({
        type: DELETE_QUESTION_SUCCESS,
        payload: question,
      });
      dispatch(thunkCreateSuccessNotification("Die Präferenz wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen der Präferenz", e)
      );
      return false;
    }
  };
