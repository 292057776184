import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { ReplacementCardDto } from "../models/identification/ReplacementCardDto";
import { reloadCustomerHistory } from "./HistoryActions";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const thunkNewReplacementCard =
  (replacementCard: ReplacementCardDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.newReplacementCard(replacementCard);
      reloadCustomerHistory(dispatch, replacementCard.customerNumber);
      dispatch(
        thunkCreateSuccessNotification(
          "Ersatzkartenanfrage erfolgreich gesendet"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Senden der Ersatzkartenanfrage",
          e
        )
      );
      return false;
    }
  };
