import { ImsSortDirection } from "../components/report/ImsTable";
import { tokenRequestOptions } from "../store/actions/LoginActions";
import {
  BranchTranslation,
  ReportTranslation,
} from "../store/models/reports/ReportsDto";
import { objectToQueryString } from "./Api";

export const DEFAULT_LIMIT = 10;

const REACT_APP_SERVER_URL =
    window.env.REACT_APP_SERVER_URL || process.env.REACT_APP_SERVER_URL;

const dashboardUrl =
    window.env.REACT_APP_DASHBOARD_URL ||
    process.env.REACT_APP_DASHBOARD_URL ||
    `${REACT_APP_SERVER_URL}/dashboard/v1`;

export async function getView<T>(
    view: string,
    offset?: number,
    orderBy?: string,
    sorting?: ImsSortDirection
): Promise<SnowflakeTable<T>> {
  const requestOptions = await tokenRequestOptions("POST");
  return fetch(
      `${dashboardUrl}/views/${view}${objectToQueryString({
        offset: offset && offset * DEFAULT_LIMIT,
        limit: DEFAULT_LIMIT,
        orderBy,
        sorting,
      })}`,
      {
        body: JSON.stringify({}),
        ...requestOptions,
      }
  )
      .then(handleResponse)
      .then((response) => mapResponseToDto<T>(response, offset));
}

export interface SnowflakeTable<T> {
  data: T[];
  fields: string[];
  offset?: number;
}

export interface ReportFilterDto {
  additionalParameters?: {
    field: string;
    value: string;
    type:
        | "string"
        | "number"
        | "bigint"
        | "boolean"
        | "symbol"
        | "undefined"
        | "object"
        | "function";
  }[];
}

export async function getReport(
    report: string,
    metric: string[],
    branch: string[] | undefined,
    country: string | undefined,
    from: string | undefined,
    to: string | undefined,
    limit: number,
    filters: ReportFilterDto,
    branchTranslation: BranchTranslation
): Promise<SnowflakeTable<Record<string, string>>> {
  const requestOptions = await tokenRequestOptions("POST");

  return fetch(
      `${dashboardUrl}/views/${report}/report${objectToQueryString({
        limit,
        metric,
        branch,
        country,
        from,
        to,
      })}`,
      {
        body: JSON.stringify(filters),
        ...requestOptions,
      }
  )
      .then(handleResponse)
      .then((response) =>
          mapResponseToDtoNew<Record<string, string>>(
              response,
              0,
              branchTranslation
          )
      );
}

interface SnowflakeResponse {
  message: string;
  sqlState: string;
  resultSetMetaData: {
    rowType: [{ name: string }];
  };
  data: string[][];
  error?: string;
}

async function handleResponse(response: Response) {
  const text = await response.text();
  const data = JSON.parse(text) as SnowflakeResponse;
  if (data.error) {
    throw data.error;
  }
  if (data.sqlState !== "00000") {
    throw data.message;
  }
  return data;
}

function mapResponseToDto<T>(
    response: SnowflakeResponse,
    offset?: number
): SnowflakeTable<T> {
  const data = response.data.map((row) => {
    const elem = {} as T;
    response.resultSetMetaData.rowType.forEach((prop, index) => {
      elem[prop.name as keyof T] = row[index] as unknown as T[keyof T];
    });
    return elem;
  });
  const fields = response.resultSetMetaData.rowType.map((prop) => prop.name);
  return { data, fields, offset };
}

function mapResponseToDtoNew<T>(
    response: SnowflakeResponse,
    offset?: number,
    branchTranslation?: BranchTranslation
): SnowflakeTable<T> {
  const processedData = response.data.map((row) => {
    const elem: { [key: string]: string } = {};
    response.resultSetMetaData.rowType.forEach((prop, index) => {
      elem[prop.name] = row[index];
    });
    return elem;
  });

  let data: T[] = [];
  let tmpElem = {} as T;
  let myMap = new Map<string, T>();
  let mapKey: string;
  processedData.forEach((row, index) => {
    // create the unique key for the map, based on group by fields
    mapKey = [row["ISO_WEEK_NUMBER"], row["OPU_NUMBER"], row["OPU_COUNTRY"]]
        .filter(Boolean)
        .join("~");
    tmpElem = {} as T;
    if (!myMap.get(mapKey)) {
      // in case no entry in map exists, start to populate an object
      tmpElem["Woche" as keyof T] = row[
          "ISO_WEEK_NUMBER"
          ] as unknown as T[keyof T];
      if (row["OPU_NUMBER"]) {
        tmpElem["Filiale" as keyof T] = branchTranslation
            ? (branchTranslation[row["OPU_NUMBER"] as keyof BranchTranslation]
                ?.label as unknown as T[keyof T])
            : (undefined as unknown as T[keyof T]);
      }
      if (row["OPU_COUNTRY"]) {
        tmpElem["Land" as keyof T] = row[
            "OPU_COUNTRY"
            ] as unknown as T[keyof T];
      }
      myMap.set(mapKey, tmpElem);
    }

    // add the additional properties to existing object
    tmpElem = myMap.get(mapKey)!;
    tmpElem[
        ReportTranslation[
            row["KPI_NAME"] as keyof typeof ReportTranslation
            ] as keyof T
        ] = row["KPI_VALUE"] as unknown as T[keyof T];
  });

  Array.from(myMap.values()).forEach((value) => data.push(value));
  const fields = Object.values(ReportTranslation).map((value) => value);
  return { data, fields, offset };
}
