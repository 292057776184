import { Add } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  UrlPathCircle,
  UrlPathCircleCreate,
  UrlPathConfigValueCreate,
  UrlPathCustomField,
  UrlPathGdpr,
  UrlPathIdentificationType,
  UrlPathIdentificationTypeCreate,
  UrlPathReceiptDetail,
} from "../../api/url";
import {
  canMaintainCircles,
  canMaintainConfig,
  canMaintainGdpr,
  canMaintainIdentificationTypes,
  canReadCircles,
  canReadConfig,
  canReadGdpr,
  canReadIdentificationTypes,
} from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import CircleTable from "./circle/CircleTable";
import {
  customFieldProps,
  receiptDetailProps,
} from "./configValue/ConfigValueFormPage";
import ConfigValueTable from "./configValue/ConfigValueTable";
import Gdpr from "./gdpr/Gdpr";
import IdentificationTypeTable from "./identificationType/IdentificationTypeTable";

// Tab names
const CIRCLE = "Circle";
const IDENTIFICATION_TYPE = "Wallet";
const GDPR = "DSGVO";

const Setup = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTab = getSelectedTab(location.pathname);

  return (
    <MenuLayout headline={"Setup | " + selectedTab}>
      {canMaintainTab(selectedTab) && (
        <Grid
          container
          justifyContent="flex-end"
          style={{
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          <Link to={getCreateUrlByTab(selectedTab)} component={RouterLink}>
            <Button variant="contained" color="primary">
              <Add />
              {`${selectedTab} erstellen`}
            </Button>
          </Link>
        </Grid>
      )}
      <Paper>
        <AppBar position="static">
          <Tabs
            value={selectedTab}
            onChange={(_event, newValue) => navigate(getUrlByTab(newValue))}
          >
            {canReadCircles() && <Tab label={CIRCLE} value={CIRCLE} />}
            {canReadIdentificationTypes() && (
              <Tab label={IDENTIFICATION_TYPE} value={IDENTIFICATION_TYPE} />
            )}
            {canReadGdpr() && <Tab label={GDPR} value={GDPR} />}
            {canReadConfig() && (
              <Tab
                label={receiptDetailProps.entityName}
                value={receiptDetailProps.entityName}
              />
            )}
            {canReadConfig() && (
              <Tab
                label={customFieldProps.entityName}
                value={customFieldProps.entityName}
              />
            )}
          </Tabs>
        </AppBar>
        <Grid
          style={{
            padding: theme.spacing(4),
          }}
        >
          {getSelectedTable(selectedTab)}
        </Grid>
      </Paper>
    </MenuLayout>
  );
};

function getSelectedTab(pathname: string) {
  switch (pathname) {
    case UrlPathCircle:
      return CIRCLE;
    case UrlPathIdentificationType:
      return IDENTIFICATION_TYPE;
    case UrlPathGdpr:
      return GDPR;
    case UrlPathReceiptDetail:
      return receiptDetailProps.entityName;
    case UrlPathCustomField:
      return customFieldProps.entityName;
  }
  return "";
}

function getSelectedTable(selectedTab: string) {
  switch (selectedTab) {
    case CIRCLE:
      return <CircleTable />;
    case IDENTIFICATION_TYPE:
      return <IdentificationTypeTable />;
    case GDPR:
      return <Gdpr />;
    case receiptDetailProps.entityName:
      return <ConfigValueTable {...receiptDetailProps} />;
    case customFieldProps.entityName:
      return <ConfigValueTable {...customFieldProps} />;
  }
}

function canMaintainTab(selectedTab: string) {
  switch (selectedTab) {
    case CIRCLE:
      return canMaintainCircles();
    case IDENTIFICATION_TYPE:
      return canMaintainIdentificationTypes();
    case GDPR:
      return canMaintainGdpr();
    case receiptDetailProps.entityName:
    case customFieldProps.entityName:
      return canMaintainConfig();
  }
  return false;
}

export function getSetupUrl(
  canReadCircles: boolean,
  canReadIdentificationTypes: boolean,
  canReadGdpr: boolean,
  canReadConfig: boolean
) {
  const path = window.location.pathname;
  // Setup is highlighted on every tab
  if (path === UrlPathCircle) return UrlPathCircle;
  if (path === UrlPathIdentificationType) return UrlPathIdentificationType;
  if (path === UrlPathGdpr) return UrlPathGdpr;
  if (path === UrlPathReceiptDetail) return UrlPathReceiptDetail;
  if (path === UrlPathCustomField) return UrlPathCustomField;
  // preselect Setup tab by user role
  if (canReadCircles) return UrlPathCircle;
  if (canReadIdentificationTypes) return UrlPathIdentificationType;
  if (canReadGdpr) return UrlPathGdpr;
  if (canReadConfig) return UrlPathReceiptDetail;
  return "";
}

function getCreateUrlByTab(selectedTab: string) {
  switch (selectedTab) {
    case CIRCLE:
      return UrlPathCircleCreate;
    case IDENTIFICATION_TYPE:
      return UrlPathIdentificationTypeCreate;
    case receiptDetailProps.entityName:
      return UrlPathConfigValueCreate(UrlPathReceiptDetail);
    case customFieldProps.entityName:
      return UrlPathConfigValueCreate(UrlPathCustomField);
  }
  return "";
}

export function getUrlByTab(selectedTab: string) {
  switch (selectedTab) {
    case CIRCLE:
      return UrlPathCircle;
    case IDENTIFICATION_TYPE:
      return UrlPathIdentificationType;
    case GDPR:
      return UrlPathGdpr;
    case receiptDetailProps.entityName:
      return UrlPathReceiptDetail;
    case customFieldProps.entityName:
      return UrlPathCustomField;
  }
  return "";
}

export default Setup;
