import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { FlowDetailsDto, FlowGroupDto } from "../models/flow/FlowDto";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_ALL_FLOWS = "GET_ALL_FLOWS";
export const DELETE_FLOW = "DELETE_FLOW";

export const thunkGetAllFlows = (): BooleanThunk => async (dispatch) => {
  try {
    const flows = await Api.getAllFlows();
    dispatch({
      type: GET_ALL_FLOWS,
      payload: flows,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler beim Laden der Flows", e));
    return false;
  }
};

export const thunkCreateFlow =
  (flowToCreate: FlowGroupDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createFlow(flowToCreate);
      dispatch(thunkGetAllFlows() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Flow wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Anlegen des Flows", e)
      );
      return false;
    }
  };

export const thunkUpdateFlow =
  (flowToUpdate: FlowGroupDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateFlow(flowToUpdate);
      dispatch(thunkGetAllFlows() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Flow wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren des Flow", e)
      );
      return false;
    }
  };

export const thunkDeleteFlow =
  (flowToDelete: FlowGroupDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteFlow(flowToDelete);
      dispatch({
        type: DELETE_FLOW,
        payload: flowToDelete,
      });
      dispatch(thunkCreateSuccessNotification("Der Flow wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen des Flows", e)
      );
    }
    return false;
  };

export const thunkCreateFlowDetails =
  (flowToUpdate: FlowDetailsDto, flowGroupNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createFlowDetails(flowToUpdate, flowGroupNumber);
      dispatch(thunkGetAllFlows() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Flow wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren des Flow", e)
      );
      return false;
    }
  };

export const thunkUpdateFlowDetails =
  (flowToUpdate: FlowDetailsDto, flowGroupNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateFlowDetails(flowToUpdate, flowGroupNumber);
      dispatch(thunkGetAllFlows() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Flow wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Aktualisieren des Flow", e)
      );
      return false;
    }
  };

export const thunkDeleteFlowDetail =
  (flowToDelete: FlowDetailsDto, flowGroupNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteFlowDetails(flowToDelete, flowGroupNumber);
      dispatch({
        type: DELETE_FLOW,
        payload: flowToDelete,
      });
      dispatch(thunkCreateSuccessNotification("Der Flow wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Löschen des Flows", e)
      );
    }
    return false;
  };
