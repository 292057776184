import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { GdprRequestDto } from "../models/gdpr/GdprRequestDto";
import { GdprTemplates } from "../models/gdpr/GdprTemplate";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const GET_GDPR_TEMPLATES = "GET_GDPR_TEMPLATES";
export const UPDATE_GDPR_TEMPLATES = "UPDATE_GDPR_TEMPLATES";

export const thunkGetGdprTemplates = (): BooleanThunk => async (dispatch) => {
  try {
    const gdprTemplates = await Api.getGdprTemplates();
    dispatch({
      type: GET_GDPR_TEMPLATES,
      payload: gdprTemplates,
    });
    return true;
  } catch (e) {
    dispatch(
      thunkCreateErrorNotification(
        "Fehler beim Laden der DSGVO-Textbausteine",
        e
      )
    );
    return false;
  }
};

export const thunkUpdateGdprTemplates =
  (gdprTemplates: GdprTemplates): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateGdprTemplates(gdprTemplates);
      dispatch({
        type: UPDATE_GDPR_TEMPLATES,
        payload: gdprTemplates,
      });
      dispatch(
        thunkCreateSuccessNotification(
          "Die DSGVO-Textbausteine wurden aktualisiert"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Aktualisieren der DSGVO-Textbausteine",
          e
        )
      );
      return false;
    }
  };

export const thunkCreateGdprDocument =
  (customerNumber: string, gdprRequestDto: GdprRequestDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createGdprDocument(customerNumber, gdprRequestDto);
      dispatch(
        thunkCreateSuccessNotification("Das DSGVO-Dokument wurde angefordert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Anfordern des DSGVO-Dokuments",
          e
        )
      );
      return false;
    }
  };
