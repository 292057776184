import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { thunkGetBonusPremiums } from "../../store/actions/BonusPremiumActions";
import { thunkGetOperationalUnits } from "../../store/actions/OperationalUnitActions";
import { IStore } from "../../store/IStore";
import { BonusPremiumDto } from "../../store/models/bonusPremium/BonusPremiumDto";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { CampaignDto } from "../../store/models/campaign/CampaignDto";
import {
  CouponDto,
  CouponStatus,
  formatCouponStatus,
  getCouponStatus,
} from "../../store/models/coupon/CouponDto";
import { FamilyDto } from "../../store/models/family/FamilyDto";
import { DateTag } from "../atoms/DateTag";
import ImsEmbeddedTable, {
  ImsHeadCell,
  ImsTableRow,
} from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_1, KNISTR_GREEN } from "../atoms/ImsMaterialTheme";
import { formatGermanDate, formatNumber } from "../atoms/Utils";

function getCouponStatusText(coupon: CouponDto, family?: FamilyDto) {
  const couponStatus = getCouponStatus(coupon);
  switch (couponStatus) {
    case CouponStatus.ACTIVATED:
      return (
        <b color={KNISTR_GREEN}>
          {formatCouponStatus(CouponStatus.ACTIVATED)} bis{" "}
          <DateTag date={coupon.expired} />
        </b>
      );
    case CouponStatus.EXPIRED:
      return (
        <b color={COLOR_GRAY_1}>
          {formatCouponStatus(CouponStatus.EXPIRED)} am{" "}
          <DateTag date={coupon.expired} />
        </b>
      );
    case CouponStatus.REDEEMED:
      const redeemedByFirstName = family?.familyMembers.find(
        (familyMember) => familyMember.customerNumber === coupon.activatedBy
      )?.customerDto?.firstName;
      return (
        <b>
          {formatCouponStatus(CouponStatus.REDEEMED)} von {redeemedByFirstName}{" "}
          am <DateTag date={coupon.redeemed} />
        </b>
      );
  }
}

function getCampaignActivation(
  coupon: CouponDto,
  family?: FamilyDto
): React.ReactNode {
  const activatedByFirstName = family?.familyMembers.find(
    (familyMember) => familyMember.customerNumber === coupon.activatedBy
  )?.customerDto?.firstName;

  return `von ${activatedByFirstName} am ${formatGermanDate(coupon.activated)}`;
}

function getCouponActivationCost(
  coupon: CouponDto,
  bonusPremiums: BonusPremiumDto[]
): string {
  const result = bonusPremiums.find(
    (bonusPremium) =>
      bonusPremium.couponDetails.advantageInfo ===
        coupon.advantage.advantageText &&
      bonusPremium.couponDetails.advantageType ===
        coupon.advantage.advantageType &&
      bonusPremium.couponDetails.advantageValue ===
        coupon.advantage.advantageValue &&
      bonusPremium.unitNumber === coupon.unitNumber
  );
  return result?.couponDetails.requiredBonusPoints
    ? "- " + formatNumber(result.couponDetails.requiredBonusPoints)
    : "";
}

function getCouponAdvantageText(coupon: CouponDto) {
  switch (coupon.advantage.advantageType) {
    case CouponAdvantageType.EURO:
      return "€";
    case CouponAdvantageType.PERCENT:
      return "%";
    case CouponAdvantageType.OFFER:
      return coupon.advantage.advantageText;
  }
}

function getCampaignDescription(coupon: CouponDto, campaigns: CampaignDto[]) {
  const campaignResult = campaigns.find(
    (campaign) =>
      campaign.couponDetails.advantageInfo === coupon.advantage.advantageText &&
      campaign.couponDetails.advantageType === coupon.advantage.advantageType &&
      campaign.couponDetails.advantageValue ===
        coupon.advantage.advantageValue &&
      campaign.selectionCriteria.unitNumber === coupon.unitNumber
  );

  return campaignResult?.campaignDescription
    ? campaignResult.campaignDescription
    : coupon.advantage.advantageText;
}

interface CouponTableProps extends ThunkProps {
  maxCouponTableSize?: number;
}

const CouponTable = (props: CouponTableProps) => {
  if (!props.bonusPremiums) {
    props.thunkGetBonusPremiums();
    return null;
  }
  if (!props.operationalUnits) {
    props.thunkGetOperationalUnits();
    return null;
  }

  if (!props.coupons) return null;

  const headCells: ImsHeadCell[] = [
    {
      title: "Coupon / Prämie",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
    {
      title: "Kampagne",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
    {
      title: "Aktivierung",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
    {
      title: "Bonuspunkte",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
    {
      title: "Filialen",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
    {
      title: "Status",
      align: "center",
      cellStyle: { verticalAlign: "center" },
    },
  ];
  const rows: ImsTableRow[] = props.coupons
    .slice(0, props.maxCouponTableSize)
    .map((coupon, index) => ({
      key: index,
      value: coupon,
      nodes: [
        <b>
          {coupon.advantage.advantageValue}
          {getCouponAdvantageText(coupon)}
        </b>,
        getCampaignDescription(coupon, props.campaigns),

        getCampaignActivation(coupon, props.family),

        <span>{getCouponActivationCost(coupon, props.bonusPremiums)}</span>,
        <span>
          {
            props.operationalUnits.find(
              (operationalUnit) =>
                operationalUnit.unitNumber === coupon.unitNumber
            )?.unitName
          }
        </span>,
        getCouponStatusText(coupon, props.family),
      ],
    }));

  return <ImsEmbeddedTable headCells={headCells} rows={rows} />;
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  family: state.customerCare.family,
  coupons: state.coupons?.coupons,
  bonusPremiums: state.bonusPremiums.bonusPremiums,
  operationalUnits: state.operationalUnits.operationalUnits,
  campaigns: state.campaigns.campaigns,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { thunkGetOperationalUnits, thunkGetBonusPremiums },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CouponTable);
