import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkCreateConfigValue,
  thunkUpdateConfigValue,
} from "../../../store/actions/ConfigGroupActions";
import { IStore } from "../../../store/IStore";
import { ConfigValueDto } from "../../../store/models/config/ConfigDto";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../../atoms/Utils";
import { getUrlByTab } from "../Setup";
import { ConfigValueFormDto, ConfigValueProps } from "./ConfigValueFormPage";

interface ConfigValueFormProps {
  configValue: ConfigValueDto;
}

const ConfigValueForm = (
  props: ConfigValueFormProps & ConfigValueProps & ThunkProps
) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);

  const saveConfigValue = async (configValueToSave: ConfigValueFormDto) => {
    if (configValueToSave.isNew) {
      delete configValueToSave.isNew;
      await props.thunkCreateConfigValue(
        configValueToSave,
        props.configGroupKey
      );
    } else {
      await props.thunkUpdateConfigValue(
        configValueToSave,
        props.configGroupKey
      );
    }
    setRedirect(true);
  };

  const { configValue } = props;
  if (redirect) {
    return <Navigate to={getUrlByTab(props.entityName)} />;
  }

  const validateForm = (values: ConfigValueDto) => {
    const errors: ValidationErrors<ConfigValueDto> = {};
    if (isBlank(values.key)) {
      errors.key = "Bitte geben Sie einen Namen ein";
    }
    if (isBlank(values.description)) {
      errors.description = "Bitte geben Sie eine Beschreibung ein";
    }
    if (isBlank(values.value)) {
      errors.value = `Bitte geben Sie einen Wert für ${props.valueName} ein`;
    }
    return errors;
  };
  return (
    <Form
      onSubmit={saveConfigValue}
      initialValues={configValue}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead
              text={`Wie lauten die Details der ${props.entityName}?`}
            />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>Name</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<ConfigValueDto>("key")}
                    label="Name"
                    readOnly={!values.isNew}
                  />
                </Grid>

                <Grid item md={12}>
                  <b>Beschreibung</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<ConfigValueDto>("description")}
                    label="Beschreibung"
                  />
                </Grid>
                <Grid item md={12}>
                  <b>{props.valueName}</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<ConfigValueDto>("value")}
                    label={props.valueName}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};
const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateConfigValue,
      thunkCreateConfigValue,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(ConfigValueForm);
